<template>
<transition name="fade">
  <div @click.self="close" v-if="show" class="popup-modal d-flex justify-content-center">
  <login ref="login"/>
  </div>
</transition>
</template>

<script>
import Login from "./Login";

export default {
  name: "AuthModal",
  components: {Login},
  data: function () {
    return {
      show: false,
      step: '',
      userPhone: '',
      validateCode: '',
      userName: '',
      errorPhoneModal: '',
      errorCodeModal: '',
      resolvePromise: undefined,
      rejectPromise: undefined,
      pending: false,
    }
  },
  watch: {
    validateCode() {
      if (`${this.validateCode}`.length>3) this.sendValidateCode();
    },
  },
  methods: {
    showAuthModal() {
      this.show = true
      const vm = this
      return new Promise((resolve, reject) => {
        vm.rejectPromise = reject
        vm.$nextTick(()=>{
          this.$refs.login.auth()
              .then(()=>{
                this.show=false
                resolve()
              })
        })
      })
    },
    close: function () {
      this.show = false
      this.rejectPromise()
    },
  },
}
</script>

<style scoped>

.popup-modal {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.5rem;
  align-items: center;
  z-index: 1100;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

</style>