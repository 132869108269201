<template>
  <my-button @click="$emit('prev-photo')" :icon="require('@/assets/media/prev.svg')" style="background: transparent;border: none;position: absolute;top:50%;left:-16px"></my-button>
  <my-button @click="$emit('next-photo')" :icon="require('@/assets/media/next.svg')" style="background: transparent;border: none;position: absolute;top:50%;right:-16px"></my-button>
    <div class="fw-bold">{{eventInfo.game.theme.title}}</div>
    <div class="text-secondary">{{eventInfo.place.title}}</div>
    <img :src="photo.file_url" alt="" style="max-width: 80%;display: block;margin: 0 auto;max-height: 100vh;">
</template>

<script>
import MyButton from "@/components/UI/MyButton";
export default {
  name: "PhotoReportItem",
  components: { MyButton },
  props: ['photo', 'eventInfo']
}
</script>

<style scoped>
.photoReportWrap {
  /*height: 240px;*/
  width: 100%;
  /*margin: 0 auto;*/
}

@media (min-width: 576px) {
.photoReportWrap {
  /*height: 320px;*/
}
}
@media (min-width: 768px) {
.photoReportWrap {
  width: 49%;
}
}

@media (min-width: 992px) {
.photoReportWrap {

}
}

@media (min-width: 1400px) {
.photoReportWrap {
  width: 453px;
  background-size: cover;
}
}
</style>
