<template>
  <div class="mb-3 text-center">
    <h4 class="formTitle" data-v-5b9eac9b=""> Добавить игрока в команду </h4>
    <div class="col-12 blockWrapper p-0">
      <MyInput label="Имя"
             name-input
             v-model="newPlayerName"
             :class="{'is-invalid': nameIsInvalid}"
      ></MyInput>
      <div v-if="nameIsInvalid" id="nameInputFeedback" class="invalid-feedback">
        Имя должно содержать не менее 2 букв!
      </div>
      <MyInput
         label="Телефон"
         phone-input
         v-model="newPlayerPhone"
         :class="{'is-invalid': phoneIsInvalid}"
         data-tel-input
         ref="phoneInput"
      ></MyInput>
      <div v-if="phoneIsInvalid" id="phoneInputFeedback" class="invalid-feedback">
        Некорректный номер телефона!
      </div>
    </div>

    <my-button @click="addNewPlayer" class="btn btn-secondary btn-block my-3 w-100"><span>Добавить</span></my-button>
    <div v-if="inviteLink" class="d-flex justify-content-sm-between">
      <div class="w-50"><hr></div>
      <div class="mx-3 d-flex text-center align-items-center">или</div>
      <div class="w-50"><hr></div>
    </div>
    <my-button v-if="inviteLink && glb.isMobileBrowser()" @click="shareInviteLink" cls="hollow" class="w-100 my-3">Отправить ссылку для регистрации</my-button>
    <my-button v-if="inviteLink && !glb.isMobileBrowser()" @click="copyInviteLink" cls="hollow" class="w-100 my-3">Скопировать ссылку для регистрации</my-button>
    <my-button @click="$emit('close')" cls="blue" class="w-100">Назад!</my-button>
    <input type="text" value="value" class="d-none" ref="inputcopy">
  </div>
</template>

<script>
import MyInput from "../UI/MyInput";
import {handleError} from "@/utils/error";
export default {
  name: "AddNewPlayer",
  components: {MyInput},
  props: {
    team: {type: Object, required: true},
    inviteLink:{type: String},
    showAddPlayer: {type: Boolean, required: false}
  },
  data() {
    return {
      newPlayerName: '',
      newPlayerPhone: '',
      nameIsInvalid: false,
      phoneIsInvalid: false,
    }
  },
  mounted () {
    this.$nextTick(()=> {
        this.$refs.phoneInput.focus();
        this.glb.phoneInputFormatter();
      });
  },
  methods: {
    addNewPlayer() {
      let vm = this;
      vm.nameIsInvalid = false;
      vm.phoneIsInvalid = false;
      console.log(vm.glb.formatPhone(this.newPlayerPhone).length)
      console.log(this.newPlayerName.length)
      if ([11, 12].includes(vm.glb.formatPhone(this.newPlayerPhone).length) && this.newPlayerName.length > 1){
        vm.$api.addPlayerInTeam(
            vm.team.id,
            {
              'name': vm.newPlayerName,
              'phone': vm.glb.formatPhone(this.newPlayerPhone),
            })
            .then(data=>{
              if (data.result) {
                if (data.result === 'not exists'){
                  let phone = vm.newPlayerPhone;
                  vm.newPlayerPhone = `${phone} нет в Whats App :(`;
                  vm.phoneIsInvalid = true;
                }
              } else {
                vm.phoneIsInvalid = false;
                vm.newPlayerPhone = '';
                vm.newPlayerName = '';
                vm.$emit('add-new-player')
              }
            })
            .catch(error=>{
              console.log(error.response)
              handleError(error)
            });
      } else {
        if (vm.glb.formatPhone(this.newPlayerPhone).length !== 11) {
          vm.phoneIsInvalid = true;
        }
        if (vm.newPlayerName.length < 3) {
          vm.nameIsInvalid = true;
        }
      }
    },
    shareInviteLink() {
      window.open(`whatsapp://send?text=${this.inviteLink}`, 'sharer')
    },
    copyInviteLink(){
      let vm = this;
      if (navigator.clipboard) {
        navigator.clipboard.writeText(this.inviteLink)
            .then(() => {
              vm.$toast.success('Ссылка скопирована в буфер.');
            })
            .catch((error) => {
              vm.$toast.error(error)
            })
      } else vm.$toast.error('упс...')
    }
  },

}
</script>

<style scoped>

.popup-modal {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.5rem;
  align-items: center;
  z-index: 1100;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.window {
  position: relative;
  background: #33347E;
  border-radius: 1rem;
  box-shadow: 2px 4px 8px rgb(0 0 0 / 20%);
  max-width: max-content;
  /*margin-left: auto;*/
  /*margin-right: auto;*/
  padding: 1.75rem;
  margin: 0 auto;
}
.window-content{
  height: 100%;
}

</style>
