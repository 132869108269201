<template>
  <div class="wrapper position-relative p-md-4">
    <h2 class="py-3">
      Мне удобно играть
    </h2>
    <p class="pb-5">
      Выберите даты когда вам удобно играть и заполните поля, что бы мы могли прислать <br>Вам приглашение на игру!
    </p>
    <div class="d-xl-flex">
      <div>
        <div class="w-100">
          <div class="d-flex align-items-center">
            <div class="timeEl"></div>
            <div class="col"><p class="me-auto ms-1">пн</p></div>
            <div class="col"><p class="me-auto ms-1">вт</p></div>
            <div class="col"><p class="me-auto ms-1">ср</p></div>
            <div class="col"><p class="me-auto ms-1">чт</p></div>
            <div class="col"><p class="me-auto ms-1">пт</p></div>
            <div class="col"><p class="me-auto ms-1">сб</p></div>
            <div class="col"><p class="me-auto ms-1">вс</p></div>
          </div>
          <div >
          <div class="d-flex justify-content-center align-items-center" v-for="(time, time_index) in times" :key="time_index">
            <div class="timeEl">с {{ time }}</div>
            <div class="position-relative col d-flex justify-content-center align-items-center" v-for="(day, day_index) in ['mn','tu','we','th','fr','sa','su']" :key="day_index">
              <input v-if="setCheck(time_index+1, day)" checked type="checkbox" class="custom-checkbox" :id="`${time_index+1}-${day_index+1}`">
              <input v-else type="checkbox" class="custom-checkbox" :id="`${time_index+1}-${day_index+1}`">
              <label
                  class="custom-checkbox"
                  @click="clickTime(time_index+1, day_index+1, $event)"
                  :for="`${time_index+1}-${day_index+1}`"></label>
            </div>
          </div>
          </div>
        </div>
        <div v-if="!$store.getters.isAuthenticated && withInputs" class="w-100 pt-4">
          <MyInput
            label="Имя"
            v-model="username"
            @click="$refs.auth.showAuthModal()"
          >
          </MyInput>
          <MyInput
            label="Телефон"
            v-model="userphone"
            @click="$refs.auth.showAuthModal()"
          >
          </MyInput>
        <my-button @click="$refs.auth.showAuthModal()" class="w-100" mailIcon>Отправить</my-button>
        </div>
      </div>
    </div>
    <auth-modal ref="auth"></auth-modal>
  </div>
</template>

<script>
import MyButton from "../../components/UI/MyButton.vue";
import MyInput from "../UI/MyInput.vue";
import AuthModal from "../../components/AuthModal";
export default {
  name: "ComfTime",
  components: {MyButton, MyInput, AuthModal},
  props:{
    withInputs:{
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      username: '',
      userphone: '',
      times: ['18:00', '19:00', '20:00', '21:00']
    }
  },
  created() {
    // document.getElementById('1-1').checked = this.$store.state.user.comfortable_time.mn1
  },
  methods: {
    setCheck: function (time_index, day) {
      if (this.$store.state.user.comfortable_time){
        return this.$store.state.user.comfortable_time[day+time_index];
      } else {
        return
      }
    },
    clickTime: function (time_index, day_index, event) {
      let checked = event.target.previousElementSibling.checked
      if (!this.$store.getters.isAuthenticated) {
        event.target.previousElementSibling.checked = true
        this.$store.commit('SET_TARGET_CLICK_ELEMENT', event.target)
        this.$refs.auth.showAuthModal()
      } else {
      console.log(checked)
      this.axios.post(
          `/api/user/comfortable_time/`,
          {
            id:`${time_index}-${day_index}`,
            status: !checked
          },
          {
            headers: {
              'Authorization': `Token ${this.$store.state.user.authToken}`
            }
          })
          .then(function (response) {
            console.log(response.data);
          });
      }

    },
  }
}
</script>

<style scoped>
.col {
  width: 30px;
  height: 30px;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
}
.wrapper {
  max-width: 28rem;
}
input[type=checkbox] {
 opacity: 0;
}
.timeEl {
  width: 45px;
  font-size: 0.56rem;
  padding: 0;
  margin: 5px;
}
.custom-checkbox {
  opacity: 1;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: 1.5px solid white;
  border-radius: 0.625rem;
  position: absolute;
  top: 0;
  left: 0;
}
.custom-checkbox:checked + label {
  background-color: #E32289;
}
@media screen and (min-width: 1200px) {
  .timeEl {
    font-size: 1rem;
    width: 60px;
  }
}
</style>
