<template>
<div>
    <div class="container-fluid fixed-bottom">
      <div class="detailsContainer position-relative">
        <div class="eventDetails">
          <div v-if="eventTeam" class="gameTitle mb-2 d-flex justify-content-between">
            <div>Команда: </div>
            <div class="fw-bold" >{{ eventTeam.title }}</div>
          </div>
          <div class="gameTitle mb-2 d-flex justify-content-between">
            <div class="">Игра: </div>
            <div class="fw-bold" v-html="event.game.theme.title +' '+ event.postfix "></div>
          </div>
            <place-detail class="mb-2" with-address :place="event.place" style="font-size: 14px"/>
          <div class="d-flex align-items-center">
            <div class="iconImg calendarIcon"></div>
            <p class="gameDescription ms-2 me-3">{{ glb.getDate(event.date) }} </p>
            <div class="iconImg clockIcon"></div>
            <p class="gameDescription ms-2">{{ glb.getTime(event.date) }}</p>
          </div>
        </div>
        <div v-if="isRegisteredOnEventTeam">
          <my-button @click="goToRegEvTeam()" class="hollow mt-2 mx-auto w-100" with-margin :icon="require('../../assets/media/edit.svg')">Редактировать участие</my-button>
        </div>
        <div @click="fetchCarts().then(() => $refs.cart.open())" class="basketSM">
          <div class="basketDiv"><notification style="right: -8px; top: -13px" :count="totalItems()"></notification></div>
          <div class="arrowContainer">
            <div class="arrowRightSmall"></div>
          </div>
        </div>
      </div>
    </div>
    <my-modal class="cartModal" ref="cart">
      <cart
          :cart="cart"
          :carts="carts"
          @deleteCart="(cartId) => deleteCart(cartId)"
          @close="$refs.cart.close()"
        ></cart>
    </my-modal>
</div>
</template>

<script>
import Cart from './PreorderCart.vue';
import MyModal from '../UI/MyModal';
import Notification from '../UI/Notification';
import MyButton from '../UI/MyButton';
import PlaceDetail from "@/components/main/PlaceDetail.vue";
export default {
  name: "PreorderNav",
  components: {PlaceDetail, Cart, MyModal, Notification, MyButton},
  props: ['event', 'eventTeam', 'cart', 'isRegisteredOnEvent', 'isRegisteredOnEventTeam'],
  async mounted () {
      this.carts = await this.$api.listEventTeamCarts(this.event.id, this.eventTeam.id);
  },
  methods: {
    handleCartDisplay() {
      console.log(this.eventTeam)
    },
    async deleteCart(cartId) {
        await this.$api.deleteEventTeamPlayerCart(cartId);
        await this.fetchCarts()
        this.$refs.cart.close()
    },
    goToRegEvTeam() {
      this.$router.push({name:'RegTeamOnEvent', params: {eventId: this.event.id, teamId: this.eventTeam.team.id}})
    },
    totalItems: function() {
      if (!this.cart) return 0
      let total_items = 0;
      for (let item of this.cart.cart_items) {
        total_items += item.quantity
      }
      return total_items
    },
    async fetchCarts() {
      this.carts = await this.$api.listEventTeamCarts(this.event.id, this.eventTeam.id);
    },
  },
}
</script>

<style scoped>
.cartModal {
  padding: 0 !important;
}
.detailsContainer {
  background-color: #33347E;
  border-radius: 20px 20px 0px 0px;
  padding: 15px;
}
.eventDetails{
  margin-right: 80px;
}
.gameTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: white;
}
.gameDescription {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  opacity: .9;
}
.basketSM {
  position: absolute;
  width: 80px;
  height: 80px;
  background: #3B3C8D;
  border-radius: 0px 20px;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.basketDiv {
  position: relative;
  width: 32px;
  height: 32px;
  background-image: url(../../assets/media/bag.svg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.iconImg {
  width: 18px;
  height: 18px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.calendarIcon {
  background-image: url(../../assets/media/calendar.svg);
}
.clockIcon {
  background-image: url(../../assets/media/alarm.svg);
}
.arrowRightSmall {
  width: 24px;
  height: 24px;
  background-image: url(../../assets/media/arrow_down.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  transform: rotate(-90deg);
}
.basketContainerXL {
  display: none;
  align-items: center;
  justify-content: space-between;
  width: 19%;
  margin-left: 1%;
  padding: 20px;
  background: #33347E;
  border-radius: 20px 20px 20px 0px;
}
.arrowContainer {
  background: #3B3C8D;
  border-radius: 0px 20px 0px 0px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  display: none;
  align-items: center;
  padding: 0 10px;
}
@media (min-width: 576px) {
  .detailsContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 7rem;
  }
}
@media (min-width: 768px) {
    .arrowContainer {
      display: flex;
    }
  .detailsContainer {
    padding-right: 10px;
    width: 80%;
    }
  .basketSM {
      right: -9rem;
      height: 100%;
      background: #33347E;
      border-radius: 20px 20px 20px 0px;
      padding-right: 2.6rem;
      width: 8rem;
    }
    .basketDiv {
      width: 44px;
      height: 44px;
    }
  .container-fluid {
      max-width: 960px;
      display: flex;
      align-items:flex-end;
      align-content: space-between;
      padding: 0;
    }
}
@media screen and (min-width: 1200px) {

}
</style>
