<template>
  <div class="wrapInput">
    <div class="d-flex justify-content-between inputHeader position-relative" ref="divEl">
      <div><label class="position-absolute" v-show="label" ref="labelEl">{{ label }}</label></div>
    </div>
    <div class="position-relative">
      <div class="nameBorderTopRight" :style="{width: topRight}"></div>
      <div class="nameBorderTopLeft" :style="{width: '24px'}"></div>
      <input
          ref="input"
          :type="type"
          :maxlength="maxlength"
          :min="type==='number'&& min ? min : undefined"
          :max="type==='number'&& max ? max : undefined"
          :autocomplete="autocomplete"
          :placeholder=placeholder
          :pattern="pattern"
          @input="updateInput"
          :value="modelValue"
          :data-tel-input="phoneInput?true:undefined"
          :disabled="disabled"
          required="required"
      >
    </div>
    <img class="avatarIcon" v-if="withIcon" src="../../assets/media/Cookie.svg" alt=""/>
  </div>
</template>

<script>
export default {
  name: "MyInput",
  props: {
    label: {
      type: String,
      default: "",
      required: false
    },
    placeholder: {
      type: String,
      default: "",
      required: false
    },
    type: {
      type: String,
      default: "text"
    },
    max:{
      type: String,
      default: undefined
    },
    min:{
      type: String,
      default: undefined
    },
    maxlength: {
      type: String,
      default: undefined
    },
    autocomplete: {
      type: String,
      default: undefined
    },
    pattern:{
      type: String,
      default: undefined
    },
    required: {
      type: Boolean,
      default: false
    },
    modelValue: {
      required: true
    },
    withIcon: {
      type: Boolean,
      default: false
    },
    phoneInput: {
      type: Boolean,
      default: false
    },
    disabled:{
      type: Boolean,
      default: false
    }
  },

  data:() =>({
      input: '',
      topRight: '250px',
      topLeft: '150px'
  }),
  methods: {
    handleBorders: function() {
      const minMargin = 24
      let newWidth = (this.$refs.divEl.clientWidth - this.$refs.labelEl.clientWidth - minMargin) > minMargin ?
          (this.$refs.divEl.clientWidth - this.$refs.labelEl.clientWidth - minMargin):
          minMargin
      this.topRight = newWidth + 'px'
    },
    updateInput(event) {
      this.$emit('update:modelValue', event.target.value)
    },
    focus(){
      this.$refs.input.focus()
    }
  },
  mounted() {
    this.$nextTick(()=>{
        this.handleBorders()
    })
    window.addEventListener('resize', this.handleBorders)
    window.addEventListener('scroll', this.handleBorders)
    window.addEventListener('click', this.handleBorders)
  },
  unmounted() {
    window.removeEventListener('resize', this.handleBorders)
    window.removeEventListener('scroll', this.handleBorders)
    window.removeEventListener('click', this.handleBorders)
  }
}
</script>

<style scoped>
.inputHeader {
  align-items: center;
  position: relative;
}
input {
  position: relative;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  background: transparent;
  border-radius: 1.25rem;
  border: 1px solid #fff;
  border-top: none;
  align-items: center;
  width: 100%;
  color: #fff;
  padding: 1rem 1.875rem;
  z-index: 2;
}
input:autofill,
input:-internal-autofill-selected,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  color: #fff !important;
  background: transparent !important;
  border: 1px solid #fff !important;
}
.nameBorderTopRight {
  position: absolute;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  height: 100%;
  border-top-right-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem;
  right: 0;
  z-index: 0;
}
.nameBorderTopLeft {
  position: absolute;
  border-top: 1px solid #fff;
  border-left: 1px solid #fff;
  height: 100%;
  border-top-left-radius: 1.25rem;
  border-bottom-left-radius: 1.25rem;
  left: 0;
}
.wrapInput {
  margin: 1.75rem auto 1.75rem;
  max-width: 28rem;
}
input:active, :hover, :focus {
  outline: 0;
  outline-offset: 0;
}
input::placeholder,
input::-webkit-input-placeholder {
  color: #fff;
}
label {
  display: flex;
  line-height: 1.25rem;
  color: #fff;
  font-weight: 500;
  font-size: 0.625rem;
  letter-spacing: 0.2em;
  top: -1rem;
  left: 1.45rem;
  text-transform: uppercase;
  padding: 0.375rem 0.625rem;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
