<template>
<div>
  <div class="teamHead d-block d-lg-flex justify-content-between px-3 mb-2 blue w-100"
       style="width: 100%"
       @click ="isCollapsed=!isCollapsed">
    <div class="d-flex justify-content-between w-100">
      <div class="d-flex align-items-center" style="width: max-content;">
          <!-- <my-button class="disabled" :icon="require(`../../assets/media/${team.rank}.png`)">
          </my-button> -->
        <div v-if="!editTeamTitle" class="d-flex">
          <div class="foo">{{team.title}}</div>
          <img @click="showEditTeamTitle()"
               src="../../assets/media/pinkEdit.svg"
               style="margin-right: auto; margin-left: 8px" alt=""/>
        </div>
        <div v-else class="d-flex">
          <input ref="editTeamTitle"
                 class="editTeamTitle"
                 type="text"
                 v-model="teamTitle"
                 @focus="changeInputWidth"
                 @focusout="updateTeamTitle">
        </div>
      </div>
      <div class="d-flex w-lg-75 justify-content-end">
        <div class="row infoBoxWrap d-none d-lg-flex mx-3 w-100">
          <div class="col infoBox px-3 m-0">
            <div class="infoTitle mt-2">УЧАСТНИКОВ</div>
            <div class="info">{{ team.players.length }}</div>
          </div>
          <div class="col infoBox px-3 m-0">
            <div class="infoTitle mt-2">СЫГРАНО</div>
            <div class="info">{{ team.games_played }}</div>
          </div>
          <div class="col infoBox px-3 m-0">
            <div class="infoTitle mt-2">РАНГ</div>
            <div class="info">
              -
<!--              {{ team.rank_name}}-->
            </div>
          </div>
        </div>
        <div class="arrowBtn d-flex align-items-center">
          <img
             :class="{'flip': team.isVisible}" src="../../assets/media/arrow_down.svg"
             style="margin-left: auto; margin-right: 8px" alt=""/>
        </div>
      </div>
    </div>
    <div class="row m-0 mt-3 d-flex d-lg-none infoBoxWrap">
        <div class="col-3 p-0 infoBox">
          <div class="infoTitle">УЧАСТНИКОВ</div>
          <div class="info mt-2">{{ team.players.length }}</div>
        </div>
        <div class="col-3 p-0 infoBox">
          <div class="infoTitle">СЫГРАНО</div>
          <div class="info mt-2">{{ team.games_played }}</div>
        </div>
        <div class="col p-0 infoBox">
          <div class="infoTitle">РАНГ</div>
          <div class="info mt-2">
            -
<!--            {{ team.rank_name }}-->
          </div>
        </div>
      </div>
  </div>
  <collapse-transition>
    <div v-if="!isCollapsed" class="container">
       <div class="row" v-if="team.players.length > 0">
         <div class="col-12 p-0">
          <div class="teamHeader d-flex justify-content-between">
            <h5>УЧАСТНИК</h5>
            <div class="d-flex">
<!--              <h5>СЫГРАНО</h5>-->
              <h5>ДЕЙСТВИЕ</h5>
            </div>
          </div>
           <div style="height: max-content;
                padding-right: 20px;
                width: calc(100% + 20px);
                overflow-y: scroll;
                overflow-x: hidden;
                margin-bottom: 20px">
             <player-in-team
                 class="mb-2"
                 v-for="(player, index) in team.players"
                 :key="player.id"
                 :player="player"
                 :picture="player.avatar"
                 :team="team"
                 :index="index"
                 :isCaptain="isCaptain"
                 @delete-player="deletePlayer"
                 @name-changed="loadData">
             </player-in-team>
           </div>
           <div class="d-flex justify-content-end">
             <my-button data-bs-toggle="modal"
                        data-bs-target="#modalExample"
                        aria-expanded="false"
                        aria-controls="modalExample"
                        class="mb-2"
                        cls="hollow"
                        style="margin-right: 8px"
                        @click="$refs.addPlayerModal.open()"> Добавить игрока
             </my-button>
             <my-button
                 class="mb-2"
                 cls="blue"
                 @click="leftTeam()"> Покинуть команду
             </my-button>
           </div>
         </div>
       </div>
    </div>
  </collapse-transition>
  <my-modal ref="addPlayerModal">
   <add-new-player
       :invite-link="getInviteLink()"
       :team="team"
       @add-new-player="addNewPlayer"
       @close="$refs.addPlayerModal.close()">
   </add-new-player>
   </my-modal>
   <confirm-modal ref="confirmDialogue"></confirm-modal>
 <auth-modal ref="auth"></auth-modal>
</div>
</template>

<script>
import {mapActions} from "vuex";
import PlayerInTeam from "./PlayerInTeam";
import MyButton from "../UI/MyButton";
import ConfirmModal from "../UI/ConfirmModal";
import AddNewPlayer from "./AddNewPlayer";
import AuthModal from "../AuthModal";
import MyModal from "../UI/MyModal";
import CollapseTransition from '@ivanv/vue-collapse-transition/src/CollapseTransition.vue';


export default {
  name: "TeamCabinet",
  components: {MyModal, ConfirmModal, MyButton, PlayerInTeam, AddNewPlayer, AuthModal, CollapseTransition},
  props: ['teamSecretSlug', 'team'],
  data() {
    return {
      isCollapsed: true,
      isCaptain: false,
      showAddPlayer: true,
      editTeamTitle: false,
      teamTitle: ''
    }
  },
  mounted() {
      if (this.team.captain === this.$store.state.user.id) {
        this.isCaptain = true;
      }
      this.teamTitle = this.team.title
  },
  methods: {
    ...mapActions(['SHOW_AUTH_MODAL']),
    loadData: function () {
      this.$emit('load-data')
    },
    async authenticate() {
      let vm = this;
      const ok = await this.$refs.auth.showAuthModal();
      if (ok) {
        vm.loadData();
      } else {
        vm.$toast.error('Что-то пошло не так!')
      }
    },
    async leftTeam() {
      let vm = this;
      const ok = await this.$refs.confirmDialogue.show({
        title: 'Покинуть команду',
        message: 'Вы действительно хотите покинуть эту команду?',
        okButton: 'Покинуть команду',
        cancelButton: 'Назад'
      })
      if (ok) {
        this.axios.delete(
            `/api/team/${vm.team.id}/user/${vm.$store.state.user.id}/`,
            {headers:{
                'Authorization': `Token ${vm.$store.state.user.authToken}`
              }
            })
            .then(function (response) {
              console.log(response);
              vm.$emit('load-data')
            })
            .catch(function (error){
              console.log(error);
              vm.$toast.error(error);
            });
      } else {
        console.log('cancel');
      }
    },
    async deletePlayer(team_id, player_id) {
      let vm = this
      const ok = await this.$refs.confirmDialogue.show({
        title: 'Удалить игрока',
        message: 'Вы действительно хотите удалить этого игрока?',
        okButton: 'Удалить игрока',
        cancelButton: 'Назад'
      })
      if (ok) {
        this.axios.delete(
            `/api/team/${team_id}/user/${player_id}/`,
            {headers:{
                'Authorization': `Token ${vm.$store.state.user.authToken}`
              }
            })
            .then(function (response) {
              console.log(response)
              vm.loadData()
            })
            .catch(function (error){
              vm.$toast.error(error);
            });
      } else {
        console.log('cancel')
      }
    },
    addNewPlayer() {
      this.showAddPlayer = false;
      this.loadData();
      this.$refs.addPlayerModal.close()
    },
    getInviteLink(){
      return `${window.location.protocol}//${window.location.host}/reg_on_team/${this.team.secret_slug}`;
    },
    copyLink: async function () {
      let vm = this;
      let url = `${window.location.protocol}//${window.location.host}/reg_on_team/${this.team.secret_slug}`;
      if (navigator.clipboard) {
        navigator.clipboard.writeText(url)
            .then(() => {
              vm.$toast.success('Ссылка скопирована в буфер.');
            })
            .catch((error) => {
              vm.$toast.error(error)
            })
      } else vm.$toast.error('упс...')
    },
    createChatRequest(teamId) {
      let vm = this;
      this.axios.get(
          `/api/team/${teamId}/create_whatsapp_chat`,
      {headers:{'Authorization': `Token ${vm.$store.state.user.authToken}`}
      })
      .then(function (response) {
        console.log(response);
        vm.team = response.data;
        if (vm.team.captain === vm.$store.state.user.id) {
          vm.isCaptain = true;
        }
      })
      .catch(function (error){
        vm.$toast.error(error);
      });
    },
    updateTeamTitle(){
      let vm = this
      console.log(this.teamTitle)
      this.editTeamTitle=false
      this.axios.patch(
          `api/team/${vm.team.id}/`,
          {title: vm.teamTitle},
          {headers:{'Authorization': `Token ${vm.$store.state.user.authToken}`}}
      ).then(function (response){
        console.log(response);
        vm.loadData()
      }
      )
      .catch(function (error){
        if (error.response.data.title) {
          vm.$toast.info(error.response.data.title)
        } else {
          vm.$toast.error(error);
        }
      });
    },
    showEditTeamTitle(){
      this.editTeamTitle=!this.editTeamTitle
      this.$nextTick(()=>{
        this.$refs.editTeamTitle.focus()
      })
    },
    changeInputWidth(ev){
      ev.target.style.width='200px'
    }

  },

}
</script>

<style>
.teamHeader {
  margin-top: 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 18px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 30px;
}
.teamHeader h5{
  font-size: 12px;
  line-height: 100%;
  color: #FFFFFF;
  opacity: 0.6;
}
.teamHeader h5:last-child{
  margin-left: 4rem;
}
.infoBox{
  border-left: none;
  text-align: center;
}
.infoBoxWrap {
  border-right: none;
  text-align: center;
}
.infoTitle {
  font-size: 10px;
  line-height: 100%;
  text-align: center;
  opacity: 0.6;
}
.blockWrapperTitle {
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.375rem;
  margin: 0;
}
.blockWrapper {
  max-width: 24rem;
  margin: 0 auto;
  padding: 0;
}
.notification {
  background-color: #E32289;
  border-radius: 50%;
  font-size: 10px;
  line-height: 143%;
  top: -8px;
  right: -24px;
  width: 16px;
  padding: 2px 2px 0 2px;
  text-align: center;
}
.formWrap {
  background: #33347E;
  border-radius: 20px;
  padding: 30px 30px 40px 30px;
}
.formWrap__vote {
  padding: 0;
}
.formTitle {
  font-size: 10px;
  line-height: 24px;
  letter-spacing: 0.165em;
  text-transform: uppercase;
  opacity: 0.6;
  text-align: left;
}
.formTitle__vote {
  padding: 30px 30px 0 30px;
}
.formBtns {
  padding: 10px;
}
.formBtns__vote {
  padding: 20px 30px;
}

.formSubtitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
  margin-bottom: 20px;
}
.formSubtitle__vote {
  padding: 0 30px;
}
.formDescription {
  text-align: left;
  font-size: 14px;
  margin-bottom: 30px;
}
.flip {
  transform: rotate(180deg);
}
input:active, :hover, :focus {
  outline: 0;
  outline-offset: 0;
}
.foo {
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  max-width: calc(70vw - 10px);
  padding-left: 0;
}
.disabled {
  background: transparent!important;
  border: none!important;
  padding: 0!important;
  margin-right: 8px!important;
}
.disabled .btnIcon {
  margin-left: 0!important;
}
.edit-span {
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid #ffffff;
  background-position: center;
  margin-right: 4px;
  margin-left: 4px;
  padding: 0px 4px;
  height: 24px;
}
.blue {
  padding: 0.75rem;
}
.teamHead{
  background-color: #3B3C8D;
  border-radius: 1rem;
}
.editTeamTitle {
  background: transparent;
  display: block;
  border: none;
  color: white
}

@media (min-width: 577px) {
  .foo {
    max-width: calc(55vw - 10px);
  }
  .infoTitle {
    font-size: 12px;
  }
  .infoBoxWrap {
    border-right: 1px solid rgba(255, 255, 255, 0.1);
  }
  .infoBox{
    border-left: 1px solid rgba(255, 255, 255, 0.1);
  }
}
@media (min-width: 992px) {
  .w-lg-75{
    width: 75%;
  }
}
</style>

