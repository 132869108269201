<template>
  <div class="playerInTeam d-flex position-relative" @click="setCheck" :style="player.playAnAnotherTeam || (inUnitedTeam && !itIsMe)? 'filter:opacity(0.5)':'filter:opacity(1)'">
    <div class="col-1 align-self-center text-center d-none d-sm-block checkPlayer align-items-center d-flex">
      <div v-if="loading" class="spinner-border text-light mt-1" role="status">
            <span class="visually-hidden">Loading...</span>
      </div>
      <input class="form-check-input form-check-input-lg"
             v-if="windowWidth>=576 && !loading"
             type="checkbox"
             v-bind:id="player.id"
             :disabled="player.playAnAnotherTeam || (inUnitedTeam && !itIsMe)? true: undefined"
             :checked="player.inGame? true: undefined"
             ref="inGameCheck">
    </div>
    <div class="playerWrap col d-sm-flex justify-content-between align-items-center">
      <div class="d-flex w-100 justify-content-start">
        <div class="d-flex">
          <div class="navAvatar" :style="{ backgroundImage: 'url('+ player.avatar +')'}"></div>
          <div class="ps-2">
            <div class="foo"
                 v-if="!activeEdit"
                 v-bind:for="player.id"
            >{{player.first_name}}
              <span class="badge text-bg-warning" v-if="itIsMe">это вы</span>
              <span class="ms-4" v-if="player.playAnAnotherTeam" style="font-size: 10px">играет в другой команде</span>
            </div>
            <div v-else class="d-flex w-100">
              <input
                  ref="edit"
                  type="text"
                  v-model="playerFirstName"
                  :aria-describedby="player.id + '-addon'"
                  class="form-control edit-form"
                  onfocus="this.style.width = ((this.value.length + 1) * 10) + 'px';"
                  @focusout="saveChanges(player.id)">
            </div>
            <div class="d-flex align-items-center position-relative">
              <div class="text-secondary"
                   style="font-size: 10px">*{{ player.phone.slice(-4) }}
              </div>
            </div>
          </div>
        </div>
        <div class="d-none d-md-block">
          <span v-if="player.inGame" class="badge text-bg-success">Зарегистрирован</span>
          <span v-else class="badge text-bg-secondary">Не зарегистрирован</span>
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <div class="align-self-center text-center d-flex d-sm-none align-items-center ps-2">
            <div v-if="loading" class="spinner-border text-light" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <input class="form-check-input form-check-input-lg"
                     v-if="windowWidth<576 && !loading"
                     type="checkbox"
                     v-bind:id="player.id"
                     :disabled="player.playAnAnotherTeam || (inUnitedTeam && !itIsMe)? true: undefined"
                     :checked="player.inGame? true: undefined"
                     ref="inGameCheck"
              >
            <span v-if="player.inGame" class="badge text-bg-success">Зарегистрирован</span>
            <span v-else class="badge text-bg-secondary">Не зарегистрирован</span>
        </div>
        <div class="d-flex">
          <div class="d-flex justify-content-end">
            <MyButton
                v-show="isCaptain"
                @click.stop="showEdit"
                edit-small
                cls="hollow"
            ><img height="18" src="@/assets/media/edit.svg"></MyButton>
            <MyButton
                v-show="isCaptain && player.id !== $store.state.user.id"
                @click.stop="deletePlayer()"
                user-close
                cls="hollow"
            ><img height="18" src="@/assets/media/userClose.svg"></MyButton>
          </div>
          <div v-if="player.inGame" class="d-flex justify-content-around">
          <my-button
              class="hollow"
              @click.stop="deleteParticipant(player)"><i class="bi bi-dash-lg fs-18"></i></my-button>
          <div class="participantCount">
            {{ player.participantCount -1 }}
          </div>
          <my-button
              class="hollow"
              @click.stop="addParticipant(player)"><i class="bi bi-plus-lg fs-18"></i></my-button>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyButton from "../UI/MyButton";
export default {
  name: "player-in-team",
  components: {MyButton},
  props: {
    team: Object,
    player: Object,
    index: Number,
    isCaptain: Boolean,
    picture: String,
    inUnitedTeam:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      playerFirstName: this.player.first_name,
      activeEdit: false,
      windowWidth: window.innerWidth,
      loading: false,
    }
  },
  computed:{
    itIsMe(){
      return this.player.id === this.$store.state.user.id
    }
  },
  watch:{
    player(currentValue, pastValue){
      if (currentValue.inGame !== pastValue.inGame){
        this.loading = false
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', ()=>{this.onResize()});
    })
  },
  onUnmounted() {
    window.removeEventListener('resize', ()=>{this.onResize()});
  },
  methods: {
    deletePlayer() {
      this.$emit('delete-player', this.team.id, this.player.id)
    },
    getPicture(){
      return this.picture? this.glb.getMediaUrl(this.picture): require('../../assets/media/coolicon-mail.svg');
    },
    showEdit() {
      this.activeEdit = true;
      this.$nextTick(() => this.$refs.edit.focus());
    },
    saveChanges (playerId) {
      let vm = this;
      this.axios.patch(
          `/api/user/${playerId}/name/`,
          {
            first_name: vm.playerFirstName,
          },
          {headers:{
              'Authorization': `Token ${vm.$store.state.user.authToken}`
            }
          })
          .then(function (response) {
            console.log(response);
            vm.activeEdit = false;
            vm.$emit('change-name')
          })
          .catch(function (error){
            console.log(error);
          });
    },
    setCheck(event){
      if (this.loading) return
      this.loading = true
      setTimeout(()=>this.loading=false, 5000)
      if (event.target.tagName === 'INPUT') {
        event.preventDefault()
      }
      if (!this.player.playAnAnotherTeam || !(this.inUnitedTeam && !this.itIsMe)){
        let check = event.target.tagName === 'INPUT' ? !this.$refs.inGameCheck.checked : this.$refs.inGameCheck.checked
        this.$emit('set-check', this.player.id, check, this.itIsMe)
      }

    },
    addParticipant(player){
      this.$emit('add-participant', player)
    },
    deleteParticipant(player){
      this.$emit('delete-participant', player)
    },
    onResize(){
      this.windowWidth = window.innerWidth
    }
  }
}
</script>

<style scoped>
.arrow {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 6px;
}
.checkPlayerWrap {
  position: absolute;
  width: 30%;
  height: 100%;
}
.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.checkPlayer {
  background-color: #3B3C8D;
  height: 60px;
  width: 60px;
  border-radius: 1rem;
  margin-right: 4px;
  padding: 10px;
}

.playerWrap {
  background-color: #3B3C8D;
  border-radius: 1rem;
  padding: 10px 15px;
}
.participantCount {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  margin: auto 8px;
  min-width: 12px;
}
.hollow {
  padding: 0px 0px;
  height: 30px;
  border-radius: 12px;
  width: 30px;
  margin-left: 4px;
  margin-right: 4px;
}

.foo {
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  max-width: calc(70vw - 10px);
  padding-left: 0;
}
.form-check-input[type=checkbox]{
  height: 2em;
  width: 2em;
  border-radius: 50%;
  border: 2px solid #CCCCCCFF;
  background-color: transparent;
}
.navAvatar{
  background-position: center;
  background-size: cover;
  width: 2.5rem;
  height: 2.5rem;
  max-width: 40px;
  border-radius: 50%;
}
.form-check-input:checked[type=checkbox] {
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid #E32289;
  background-image: url(@/assets/media/check_bold.svg);
}
.edit-form, .edit-form:active, .edit-form:focus, .edit-form:hover {
  display: block;
  width: 100%;
  padding: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  background-color: transparent;
  border: none;
  outline: 0;
  outline-offset: 0;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-left: 8px;
}
.edit-span {
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid #ffffff;
  background-position: center;
  margin-right: 8px;
  padding: 0px 4px;
  height: 24px;
}
.playerInTeam {
  padding-right: 0;
  padding-left: 0;
  margin-bottom: 4px
}
.noText {
  border: 2px solid #E32289;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-height: 34px;
  width: 100%;
  max-width: 34px;
  border-radius: 50%;
  margin-right: 4px;
}
span{
  margin-left: 7px;
}

 .spinner-border {
    --bs-spinner-width: 2rem;
    --bs-spinner-height: 2rem;
    --bs-spinner-border-width: 0.25rem;
}
@media (max-width: 575px) {
  .playerWrap {
    padding: 10px;
  }
  .participantCount {
    margin: auto 0px;
    margin-left: 4px;
  }
  .checkPlayer {
    height: 16px;
    width: 16px;
    padding: 0px;
  }
 .form-check-input[type=checkbox] {
   height: 1.5rem;
   width: 1.5rem;
   margin: 0;
 }
 .spinner-border {
    --bs-spinner-width: 24px;
    --bs-spinner-height: 24px;
    --bs-spinner-border-width: 4px;
}


}

</style>
