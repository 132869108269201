<template>
    <transition name="fade">
        <a v-if="showScrollButton" @scroll="computeScroll" @click="scrollToTop" class="back_to_top px-3 pb-3 pt-1" title="Наверх">&uarr;</a>
    </transition>
</template>

<script>

export default {
    name: "ScrollUp",
    data() {
        return {
            showScrollButton: false,
        }
    },
    mounted() {
        window.addEventListener('scroll', this.computeScroll);
    },
    methods: {
    scrollToTop() {
      window.scrollTo(0,0);
    },
    computeScroll() {
      this.showScrollButton = window.pageYOffset>document.documentElement.clientHeight;
    },
    }
}
</script>

<style scoped>
.back_to_top {
  border: 0.15rem solid #E32289;
  background-color: #E32289;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5rem;
  color: white;
  position: fixed;
  bottom: 2rem;
  right: 1rem;
  font-size: 1.5rem;
  line-height: 1.5rem;
  cursor: pointer;
  z-index: 12;
}

@media screen and (min-width: 1200px) {
    .back_to_top {
    bottom: 8rem;
    right: 3rem;
  }
}
</style>