<template>
<pre-loader v-if="loading"/>
<div v-else>
  <div  class="col-12 flex-column" v-if="!withoutEventInfo">
    <div v-on:click="visible=!visible" class="blockWrapper d-flex justify-content-between align-items-center">
      <div class="d-flex flex-column justify-content-center text-start align-items-start">
        <div><span class="fw-600 fs-18" v-html="event.event.title"></span></div>
        <div class="eventDate opacity-75 text-start">{{glb.getFullDate(event.event.date)}} <span class="fs-14 ms-3"><img class="icon me-2" src="@/assets/media/place.svg" alt="geo" style="height: 14px"/>{{ event.event.place }}</span></div>
      </div>
      <div class="arrowBtn">
        <div><img v-show="!uncollapsed" :class="{'flip': visible}" class="ms-1" src="@/assets/media/arrow_down.svg" /></div>
      </div>
    </div>
  </div>
  <collapse-transition>
    <div v-if="!visible || uncollapsed">
    <table v-if="event.result.length" class="table table-shq">
      <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col" class="text-start">КОМАНДА</th>
        <th scope="col" v-for="(tour, index) in event.result[0].tour_score" :key="index" class="d-none d-md-table-cell">{{ index }}</th>
        <th scope="col">ИТОГ</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(team, index) in event.result"
          :key="index"
          :class="setClass(index)"
      >
        <th scope="row">
          <div class="d-md-none mb-4 pt-1"></div>
          <div class="ms-1 fs-16 fw-light" style="line-height: unset">{{ index+1 }}.</div>
        </th>
        <td class="">
          <div class="foo">{{ team.team_title }}</div>
          <div class="info d-flex w-100 justify-content-around">
            <div scope="col"
                 v-for="(score, index) in team.tour_score"
                 :key="index"  class="mobileView d-md-none d-table-cell"><span class="me-1 fs-6"> {{ score }} </span>
            </div>
          </div>

        </td>
        <th scope="col" v-for="(score, index) in team.tour_score" :key="index" class=" d-none d-md-table-cell">{{ score }}</th>
        <td>
          <div class="d-md-none mb-4"></div>
          <div class="fs-6 fw-bold text-center">{{ team.total_score }}</div>
        </td>
      </tr>
      </tbody>
    </table>
    <div v-else class="my-5 text-center fs-4 w-100">Нет результатов 🤐</div>
    <div class="my-5 mx-auto fs-4 w-100"></div>
    </div>
  </collapse-transition>
</div>
</template>

<script>
import PreLoader from "../UI/PreLoader";
import CollapseTransition from '@ivanv/vue-collapse-transition/src/CollapseTransition.vue';

export default {
  name: "GameResultTable",
  components: {PreLoader, CollapseTransition},
  props: {
    eventId: {
      type: Number,
      required: true
    },
    eventData:{
      type: Object,
      required: false
    },
    uncollapsed: {
      type: Boolean,
      default: false
    },
    withoutEventInfo:{
      type: Boolean,
      default: false
    }
  },
  data: function (){
    return {
      event: null,
      visible: true,
      loading: true
    }
  },
  mounted() {
    if (this.eventData){
      this.event = this.eventData
      this.loading = false
    } else {
      this.getEventResults()
    }
  },
  methods: {
    setClass(index){
      if (index===0) {
        return 'primary'
      }else if(index===1) {
        return 'secondary'
      }else if(index===2) {
        return 'third'
      }else {
        return 'base-color'
      }
    },
    getEventResults() {
      let vm = this
      vm.loading = true
      this.$api.getEventResults(this.eventId)
          .then(data=>{
            vm.event = data
          })
          .catch(function (error) {
            vm.$toast.error(error)
          })
          .finally(()=>vm.loading=false)
    },
  },

}
</script>

<style scoped>

.blockWrapper {
  background-color: #33347E;
  border-radius: 1rem;
  padding: 10px 10px;
  margin-bottom: 10px;
  max-width: none;
}
.eventDate {
  font-weight: 600;
  font-size: 14px;
  line-height: 143%;
  color: #FFFFFF;
  opacity: 0.6;
  display: block;
}
h3 {
  text-align: left;
}
.arrowBtn {
  transform: rotate(180deg);
}
.flip {
  transform: rotate(180deg);
}
h2 {
  margin-bottom: 20px;
}
.table {
  color: #ffffff;
}
table {
  border-collapse: separate;
  border-spacing: 0 4px;
}
td {
  border: solid 1px #000;
  border-style: hidden;
  padding: 10px;
  font-size: 14px;
}
tbody {
  position: relative;
}

tbody:before {
  content: '';
  background-color: rgb(255 255 255 / 10%);
  height: 1px;
  width: 100%;
  display: block;
  position: absolute;
  top: -4px;
}
th {
  border-style: hidden;
  font-size: 14px;
  line-height: 143%;
  font-weight: 400;
}
th:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
th:last-child {
  padding-right: 0;
  padding-left: 0;
}
td:last-child {
  border-right-style: hidden;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: none;
}
thead *{
  opacity: 0.6;
  font-weight: 400!important;
  font-size: 12px;
  line-height: 100%;
}
.foo {
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  max-width: calc(62vw - 10px);
  text-align: left;
  padding-left: 0;
}
.table>:not(caption)>*>* {
  padding: 0.5rem 0.5rem;
}
.primary {
  background-color: #AC2683;
}
.secondary {
  background-color: #7C3E8B;
}
.third {
  background-color: #593b8d;
}
.base-color{
  background-color: #3b3c8d;
}
.mobileView {
  font-size: 10px;
  line-height: 100%;
  opacity: 0.8;
  border-right: 1px solid rgb(255 255 255 / 20%);
  /*padding-right: 5px;*/
  /*padding-left: 2px;*/
  margin-top: 11px;
  height: min-content;
}

.mobileView:last-child {
  border-right: none;
}
.mobileView:first-child {
  padding-left: 0;
}
.info {
  /*width: 160px;*/
  /*overflow-x: scroll;*/
}

/* xs - устройства (до 576px) */
/* CSS для ширины, которая меньше 575px (включительно) */

/* sm-устройства (больше или равно 576px) */
@media (min-width: 576px) {
  /* CSS для: 576px <= ширины <= 767px */
  .blockWrapper {
    padding: 15px 30px;
  }
}

/* md-устройства (больше или равно 768px) */
@media (min-width: 768px) {
  /* CSS для: 768px <= ширины <= 991px */

  .foo {
    max-width: calc(50vw - 10px);
  }
}

/* lg-устройства (больше или равно 992px) */
@media (min-width: 992px) {
  /* CSS для: 992px <= ширины <= 1119px */
}

/* xl-устройства (больше или равно 1200px) */
@media (min-width: 1200px) {
  /* CSS для: ширины >= 1200px */

}



</style>
