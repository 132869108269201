<template>
  <pre-loader v-if="loading"/>
  <div class="catalogContainer d-flex flex-column h-100">
    <div class="catalogNavigatorContainer d-flex justify-content-between">
      <div class="d-flex" style="overflow-x: scroll; overflow-y: hidden">
        <div @click="selectCategory()" class="catalogCategoryItem">
          <p>Меню</p>
          <div class="arrowSimulator"></div>
        </div>
        <div
          v-for="parentCategory in category.parents"
          :key="'pc'+parentCategory.id"
          @click="selectCategory(parentCategory.id)"
          class="catalogCategoryItem"
        >
          <p class="catalogCategoryText">{{parentCategory.title}}</p>
          <div class="arrowSimulator"></div>
        </div>
        <div v-if="category.title" class="catalogCategoryItem">
          <p class="catalogCategoryText">{{category.title}}</p>
          <div class="arrowSimulator"></div>
        </div>
      </div>
      <div @click="isList=!isList" class="viewAdjuster">
        <p>Вид</p>
        <div :class="isList?'listMenuIcon':'viewIcon'"></div>
      </div>
    </div>
      <div class="categories row" :class="{'row-cols-1': isList, 'row-cols-2 row-cols-lg-3 row-cols-xl-4': !isList}">
        <transition-group name="categoriesAnimation">
          <category
            v-for="category in category.children"
            :key="'category'+category.id"
            @click="selectCategory(category.id)"
            :title="category.title"
            :image="category.picture"
            :isList="isList"
          ></category>
        </transition-group>
      </div>
      <div class="products row" :class="{'row-cols-1': isList, 'row-cols-2 row-cols-lg-3 row-cols-xl-4': !isList}">
        <transition-group name="productAnimation">
          <product
          v-for="product in category.products"
          :key="'product'+product.id"
          :product="product"
          :cart="cart"
          :event-team="eventTeam"
          :isList="isList"
          >
          <discount v-if="discount"></discount>
          </product>
        </transition-group>
      </div>
      <div class="seperator"></div>
    </div>
</template>

<script>
import Product from "./Product";
import Discount from "./Discount";
import Category from './Category';
import PreLoader from "@/App.vue";

const rootCategory = {
    title: "",
    picture: "",
    children: [],
    parent: null,
    products: [],
    parents:[],
  }

export default {
  name: "CatalogCategory",
  components: {PreLoader, Product, Discount, Category},
  props: ['categoryId', 'event', 'cart', 'eventTeam'],
  data() {
    return {
      category: {...rootCategory},
      loading: true,
      isList: false,
    }
  },
  mounted() {
    this.loadData();
  },
  methods:{
    loadData(){
      this.$api.getMenuByPlaceId(this.event.place.id)
          .then(data=>{
            this.category = {...rootCategory}
            this.category.children = data
          })
          .finally(()=>this.loading=false)
    },
    loadCategory(categoryId){
      this.$api.getMenuCategoryDetail(this.event.place.id, categoryId)
          .then((data)=>{
            this.category = data
          })
          .catch(function (error) {
            this.$toast.error(error);
          });
    },
    selectCategory(categoryId){
      if (!categoryId){
        this.loadData()
      } else{
        this.loadCategory(categoryId)
      }
    },
  }
}
</script>

<style scoped>
.catalogCategoryText {
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  max-width: calc(35vw - 10px);
}
.initialPrice {
  font-weight: 400;
  font-size: 0.8125rem;
  line-height: 1.375rem;
  text-decoration-line: line-through;
  opacity: .7;
}
.categories {
  padding: 0;
  /*margin-bottom: 1.25rem;*/
}
.products {
  margin-bottom: 1.25rem;
}
.seperator {
  /*width: 90%;*/
  border-bottom: 1px solid white;
  opacity: 0.1;
  margin-left: auto;
  margin-right: auto;
}
.catalogContainer {
  position: relative;
  /*padding: 0.625rem;*/
  padding-top: 3.5rem;
  padding-bottom: 9rem;
}
.catalogNavigatorContainer {
  background-color: #33347E;
  height: 2.5rem;
  width: 106%;
  position: absolute;
  top: 0;
  left: -0.625rem;
  display: flex;
  align-items: center;
  padding-left:1.25rem;
}
.catalogCategoryItem {
  font-size: 14px;
  position: relative;
  margin-right: 1.875rem;
}
.arrowSimulator {
  position: absolute;
  width: 3.125rem;
  height: 3.125rem;
  top: -0.8125rem;
  right: -0.625rem;
  transform: rotate(45deg);
  border-right: 4px solid #2C2D73;
  border-top: 4px solid #2C2D73;
}
.viewAdjuster {
  margin-left: auto;
  display: flex;
  align-items: center;
  background-color: #3B3C8D;
  height: 100%;
  padding: 0 1.25rem;
  border-left: 4px solid #2C2D73;
}
.viewIcon {
  width: 1.125rem;
  height: 1.125rem;
  background-image: url(../../assets/media/row-vertical.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: 0.625rem;
}
.listMenuIcon {
  width: 1.125rem;
  height: 1.125rem;
  background-image: url(../../assets/media/listToggle.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: 0.625rem;
}
.categoriesAnimation-enter-active,
.categoriesAnimation-leave-active {
  transition: all 0.5s ease;
}
.categoriesAnimation-enter-from,
.categoriesAnimation-leave-to {
  opacity: 0;
  transform: translateY(100%);
}

.productAnimation-enter-active,
.productAnimation-leave-active {
  transition: all 0.5s ease;
}
.productAnimation-enter-from,
.productAnimation-leave-to {
  opacity: 0;
  transform: translateY(100%);
}

@media (min-width: 576px) {
  .viewAdjuster {
    border-radius: 0px 12px 12px 0px;
  }
  .catalogNavigatorContainer {
    border-radius: 12px;
    left: 0;
    width: 100%;
  }
}
@media screen and (min-width: 1200px) {
  
}
</style>