<template>
<div style="background: #33347E;">
    <p class="promoGame">ПРОБНАЯ ИГРА</p>
    <h1>{{theme.title}}</h1>
    <div class="position-relative frameContainer" ref="currentFrameWrap">
      <pre-loader style="padding-top: 65%;" v-if="!currentFrame"/>
      <div v-else>
        <div class="d-flex justify-content-between align-items-center mb-3">
          <my-button cls="purple" disabled="">Задание <strong class="ms-3">{{ currentTaskNumber }} / {{ this.frames.length / 2}}</strong></my-button>
          <my-button cls="purple" disabled="">{{currentFrame.isQuestion? 'Вопрос': 'Ответ'}}</my-button>
        </div>
        <pre-loader style="padding-top: 50%; " v-if="!currentFrame.html"/>
        <div v-else class="position-relative overflow-hidden" ref="currentFrame" style="padding-top: 50%; ">
        <transition
          class="animate__animated"
          :enter-active-class="currentFrame.isQuestion? 'animate__fadeInRightBig' : 'animate__fadeIn'"
          :leave-active-class="currentFrame.isQuestion? 'animate__fadeOutLeftBig' : 'animate__fadeOut'"
        >
          <div v-if="currentFrame.html" :key="currentFrame.id" class="presentation" :style="{ zoom: currentFrameZoom, height: '918px' }" v-html="currentFrame.html"></div>
        </transition>
        </div>
      </div>
    </div>
    <div v-if="currentFrame.html" class="d-flex justify-content-around align-items-center">
      <my-button  class="mt-3 mx-auto w-50" @click="nextFrame">Далее </my-button>
    </div>
</div>
</template>

<script>
import MyButton from "@/components/UI/MyButton";
import PreLoader from "@/components/UI/PreLoader.vue";

export default {
  name: "PromoGame",
  props: ['theme'],
  components: {PreLoader, MyButton},
  data() {
    return {
      frames: [],
      trialTasks: [],
      currentFrame: {},
      currentFrameZoom: null,
    }
  },
  mounted(){
    this.loadTrialGamePackage()
    this.setZoomTemplate()
  },
  computed:{
    currentTaskNumber(){
      return Math.floor(this.frames.indexOf(this.currentFrame) / 2) + 1
    }
  },
  methods: {
    parseFrames(gamePackage){
      const frames = []
      gamePackage.tours.forEach(tour=>{
        tour.task_blocks.forEach(tb=>{
          this.trialTasks = this.trialTasks.concat(tb.tasks)
          tb.tasks.forEach(task=>{
            frames.push({
              isQuestion:true,
              context: this.getQuestionFrameContext(task),
              ...task.question_frame,
            })
            frames.push({
              isQuestion: false,
              context: this.getAnswerFrameContext(task),
              ...task.answer_frame})
          })
        })
      })
      return frames
    },
    async loadTrialGamePackage(){
      const themeData = await this.$api.getGameThemeTrialGamePackage(this.theme.id)
      this.frames = this.parseFrames(themeData.trial_game_package)
      this.currentFrame = this.frames[0]
      await this.renderFrame(this.frames[0])
      await this.initFrame()
      this.frames.slice(1).forEach(frame=>this.renderFrame(frame))
    },
    async renderFrame(frame){
      frame.html = await this.$templatesApi.renderTemplate(frame.context, frame.template_name)
    },
    async nextFrame(){
      const currentFrameIndex = this.frames.indexOf(this.currentFrame)
      this.cleanMedia()
      if (currentFrameIndex<this.frames.length-1){
        this.currentFrame = this.frames[currentFrameIndex+1]
      } else {
        this.currentFrame = this.frames[0]
      }
      await this.initFrame()
    },
    async cleanMedia(){
      const currentFrame = this.$refs.currentFrame
      const medias = currentFrame.querySelectorAll('video, audio')
      medias.forEach(media=>{
        media.pause()
        media.src = ""
        media.load()
      })
    },
    async initFrame(){
      await this.$nextTick()
      const currentFrame = this.$refs.currentFrame.lastChild
      console.log(currentFrame)
      const media = currentFrame.querySelector('video, audio')
      if (media) setTimeout(()=>media.play(),500)
      this.glb.textFit(currentFrame)
    },
    setZoomTemplate() {
      this.currentFrameZoom = this.$refs.currentFrameWrap.offsetWidth / 1920
    },
    getQuestionFrameContext(task){
      const context = {
        question_part: this.getQuestionText(task)
      }
      task.question_media.forEach((media, index)=>{
        context[`question_media_${index}_src`] = media.url
      })
      return context
    },
    getAnswerFrameContext(task){
      const context = {
        answer: task.answer,
        answer_tip: task.answer_tip
      }
      task.question_media.forEach((media, index)=>{
        context[`question_media_${index}_src`] = media.url
      })
      const isAudioFrame = task.answer_frame.template_name.includes('audio')
      if (isAudioFrame){
        try{
          context[`answer_media_0_src`] = task.answer_media.filter(media=>media.type === 'audio')[0].url
          context[`answer_media_1_src`] = task.answer_media.filter(media=>media.type === 'image')[0].url
          context[`answer_media_1_src`] = task.answer_media.filter(media=>media.type === 'image')[1].url
        } catch(err){
          console.log(err)}
      } else {
        task.answer_media.forEach((media, index)=>{
          context[`answer_media_${index}_src`] = media.url
        })
      }
      return context
    },
    getQuestionText(task){
      let questionText
      let logicalTipFromIndex = task.logical_tip.indexOf('>');
      let questionPartFromIndex = task.question_part.indexOf('>');
      if (task.preamble && task.logical_tip && task.question_part) {
          questionText = task.preamble.slice(0, task.preamble.length - 4) + ' ' + task.logical_tip.slice(logicalTipFromIndex + 1, task.logical_tip.length - 4) + ' ' + task.question_part.slice(questionPartFromIndex + 1, task.question_part.length)
      } else if (task.logical_tip && task.question_part) {
          questionText = task.logical_tip.slice(0, task.logical_tip.length - 4) + ' ' + task.question_part.slice(questionPartFromIndex + 1, task.question_part.length)
      } else if (task.preamble && task.question_part) {
          questionText = task.preamble.slice(0, task.preamble.length - 4) + ' ' + task.question_part.slice(questionPartFromIndex + 1, task.question_part.length)
      } else if (task.question_part) {
          questionText = task.question_part
      }
      return questionText
    }
  },
}

</script>

<style scoped>
      .question {
        /*padding: 160px;*/
        margin-top: 20px;
        justify-content: center;
        margin-bottom: 32px;
        align-items: center;
        background: #2E3077;
        width: 100%;
      }
      .question_picture {
        height: auto;
        min-height: 360px;
        width: auto;
        max-width: 100%;
        -o-object-fit: contain;
        object-fit: contain;
      }
      h1 {
        font-weight: 600;
        font-size: 24px;
        line-height: 24px;
        color: #FFFFFF;
        margin-bottom: 30px;
      }
      .inputAnswer {
        background: #2E3077;
        border-radius: 10px;
        width: 100%;
        border-color: transparent;
        padding: 15px;
        margin-top: 20px;
        margin-bottom: 60px;
        font-weight: 600;
        font-size: 18px;
        line-height: 150%;
        color: #FFFFFF;
      }
      .inputAnswer::placeholder {
        font-weight: 600;
        font-size: 18px;
        line-height: 150%;
        text-align: center;
        color: #FFFFFF;
        opacity: 0.2;
      }
      .promoGame {
        font-weight: 400;
        font-size: 10px;
        line-height: 24px;
        letter-spacing: 0.165em;
        color: #FFFFFF;
        opacity: 0.6;
      }
      .number {
        background: #2E3077;
        width: 34px;
        height: 34px;
        text-align: center;
        color: #FFFFFF;
        margin-left: 24px;
        font-weight: 600;
        font-size: 18px;
        line-height: 120%;
        align-items: center;
        display: flex;
        justify-content: center;
        border-radius: 10px;
      }
      .number:first-child {
        margin-left: 0;
      }
      .numberActive {
        background: #E32289;
      }
      .questionText {
        font-weight: 600;
        font-size: 26px;
        line-height: 150%;
        color: #FFFFFF;
        /* padding: 53px 47px 27px 47px; */
        width: auto;
        min-width: 320px;
        text-align: center;
        margin-bottom: 16px;
      }
      .questionText span {
          color: #FFBC37;
      }

.presentation{
  font-family: 'Mont';
  position: absolute;
  font-weight: 600;
  top:0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 1920px;
  max-width: 1920px;
  height: 1080px;
  overflow: hidden;
}
</style>
