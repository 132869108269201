<template>
 <div class="event-detail mb-4">
    <div v-if="event.game.theme" class="d-sm-flex align-items-stretch">
      <div class="gamePictureWrap position-relative">
          <div class="gamePicture position-absolute top-0 bottom-0 left-50" :style="{backgroundImage: `url(${event.game.theme.picture_thumb})`, zIndex: 2}"></div>
          <div
              class="gamePictureBckg"
              :style="{backgroundImage: `url(${event.game.theme.picture})`}"
          ></div>
        </div>
      <div class="d-md-block mt-2 mb-3">
        <div class="fs-3 fw-bold mt-2" v-html="event.game.theme.title +' '+ event.postfix "></div>
        <div v-if="event.unitedEvents">
            <place-detail
                v-for="place in event.unitedEvents.map(ev=>ev.place)"
                :key="'place'+place.id"
                :place="place"
                with-address
                class="mb-2 mt-0"
            />
          </div>
        <div class="eventPlace mt-0 mb-2" v-else-if="event.place">
              <place-detail with-address :place="event.place"/>
          </div>
        <div class="eventDate mt-3">
            <img class="icon" src="@/assets/media/calendar_white.svg" />
            {{glb.getDate(event.date)}}
            <img class="icon ms-3" src="@/assets/media/alarm_white.svg" />
            {{glb.getTime(event.date)}}
          </div>
      </div>
    </div>
    <div v-if="event.meta_data.post && !isPassed" class="mt-3 pt-3 border-top border-1" v-html="event.meta_data.post"></div>
</div>
</template>

<script>
import PlaceDetail from "@/components/main/PlaceDetail.vue";

export default {
  name: "EventDetail",
  components: {PlaceDetail},
  props: ['event'],
  computed: {
    isPassed(){
      return new Date() > new Date(this.event.date)
    },
  },

}
</script>

<style scoped>
.gamePictureWrap {
  width: 100%;
  border-radius: 8px;
  height: 120px;
  overflow: hidden;
}
.gamePictureBckg, .gamePicture{
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 120px;
}
.gamePictureBckg{
  filter: blur(13px) contrast(0.8);
}
.gamePicture{
    background-size: contain;
}
.eventPicture{
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 10rem;
  border-radius: 1rem;
}

@media (min-width: 576px) {
  .gamePictureWrap {
    height: auto;
    width: 50%;
    margin-right: 1rem;
  }
  .gamePictureBckg, .gamePicture{
    height: 100%;
  }
}
@media (min-width: 992px) {
  /*.gamePictureWrap {*/
  /*  width: 25%;*/
  /*}*/
}
</style>
