import {useToast} from "vue-toastification";

const toast = useToast()

function responseDataToErrorMessage(data){
    let errorString = ''
    Object.keys(data).forEach(key=>{
        errorString += key + ' – '
        if (Array.isArray(data[key])){
            data[key].forEach((err, index)=>{
                errorString+=err
                if (index < data[key].length-1){
                    errorString+= ', '
                }
            })
        } else {
            errorString += data[key]
        }
        errorString+= '\n'
    })
    return errorString
}

export function handleError(error, timeout= 3000){
    let errorMessage
    if (error.response && error.response.data && typeof error.response.data === 'object'){
        errorMessage = responseDataToErrorMessage(error.response.data.detail || error.response.data)
    } else {
        errorMessage = error.toString()
    }
    console.error(errorMessage)
    toast.error(errorMessage, { timeout: timeout })
}