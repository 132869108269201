import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/MainView.vue'
import RegOnTeam from "../components/team/RegOnTeam";
import TeamCabinet from "../components/team/TeamCabinet";
import RegTeamOnEvent from "../components/team/RegTeamOnEvent";
import PersonalCabinet from "../views/PersonalCabinetView";
// import RegOnEvent from "../components/main/RegOnEvent";
import RegNewTeam from "../components/team/RegNewTeam";
import GameResults from "../views/GameResultsView";
import Rating from "../views/RatingView";
import GameResult from "../components/main/GameResult";
import store from "../store/index";
import Preorder from "../components/preorder/Preorder";
import MyTeams from "../views/MyTeamsView";
import Base from "../views/BaseView";
import Games from "../views/GamesView";
import PromoGame from "../components/main/PromoGame";
import SittingModule from "../components/sitting/SittingModule";
import PhotoReport from "@/views/PhotoReportView";
import Reviews from "../views/EventReviewView";
// import RegOnEventFormView from "../views/RegOnEventFormView";
import LoginView from "../views/LoginView";
import GameVoteView from "../views/GameVoteView.vue";
import SecretProrderPage from "@/views/Preorder/SecretProrderPage.vue";
import PreorderCart from "@/components/preorder/PreorderCart.vue";
import EventView from "@/views/EventView.vue";

const routes = [
  {
    path: '/',
    name: 'Base',
    component: Base,
    children: [

      {path: '', name: 'home', component: Home},
      {path: 'cabinet', name: 'cabinet', component: PersonalCabinet},
      {path: 'login', name: 'login', component: LoginView},

      {path: 'event/:eventId', name: 'eventPage', component: EventView},
      {path: 'event/:eventId/team/:teamId/preorder', name: 'Preorder', component: Preorder},
      {path: 'event/:eventId/registration', name: 'RegOnEvent', component: EventView},
      // {path: 'event/:eventId/registration', name: 'RegOnEvent', component: RegOnEventFormView},
      {path: 'event/:eventId/team/:teamId/event_registration', name: 'RegTeamOnEvent', component: RegTeamOnEvent, meta: {requiresAuth:true}},
      {path: 'event/:eventId/photoReport', name: 'PhotoReport', component: PhotoReport},
      {path: 'event/:eventId/reviews', name: 'Reviews', component: Reviews, meta: {requiresAuth:true}},

      {path: 'event_team/:eventTeamId/preorder/cart', name: 'Cart', component: PreorderCart, meta: {requiresAuth:true}},
      {path: 'reg_on_team/:slug', name: 'RegOnTeam', component: RegOnTeam, meta: {requiresAuth:true}},

      {path: 'preorder/:secret', name: 'secretEventPreorder', component: SecretProrderPage},
      {path: 'theme_vote', name: 'themeVote', component: GameVoteView, meta: {requiresAuth:true}},

      {path: 'game_results', name: 'GameResults', component: GameResults},
      {path: 'games', name: 'Games', component: Games},
        {path: 'promo_game', name: 'PromoGame', component: PromoGame},
      {path: 'game_result/:eventId', name: 'GameResult', component: GameResult},
      {path: 'rating', name: 'Rating', component: Rating},
      {path: 'sitting/:eventId', name: 'SittingModule', component: SittingModule},
      // SECURE ROUTES
      {path: 'team/new_team', name: 'RegNewTeam', component: RegNewTeam, meta: {requiresAuth:true}},
      {path: 'my_teams', name: 'MyTeams', component: MyTeams, meta: {requiresAuth:true}},
      {path: 'team/:slug', name: 'TeamCabinet', component: TeamCabinet, meta: {requiresAuth:true}},
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    redirect: {name: 'home'}
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes,
  scrollBehavior(){
    return {top: 0, behavior: 'smooth'}
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next()
      return
    }
    console.log('redirect to login')
    next ({name: 'login', query: {from: to.path}})
  } else {
    next()
  }
})

export default router
