<template>
<div>
    <div @click="isCollapsed=!isCollapsed"
         class="row eventTeamHeader mx-0 d-flex justify-content-between">
      <div v-if="!editEventTeamTitle" class="col-11 col-sm-8 d-flex align-items-center">
        <!-- <my-button class="disabled" :icon="require(`../../assets/media/${event_team.team.rank}.png`)">
        </my-button> -->
        <h3 class="m-md-0 mb-0 foo">{{ event_team.title }}</h3>
        <img @click="showEditEventTeamTitle()"
             src="../../assets/media/pinkEdit.svg"
             style="margin-right: auto; margin-left: 8px" alt=""/>
      </div>
      <div v-else class="d-flex">
        <input ref="editEventTeamTitle"
               type="text"
               v-model="eventTeamTitle"
               :aria-describedby="event_team.id + '-et'"
               class="editEventTeamTitle"
               onfocus="this.style.width = ((this.value.length + 1) * 10) + 'px';"
               @focusout="updateEventTeamTitle">
      </div>
      <div class="freePlace d-none d-md-block col-sm-3">
        <p>Свободно мест</p>
        <span>{{ freePlaces }} из {{ maxPlayerInTeam }}</span>
      </div>
      <div class="p-0 col-1 arrowBtn">
        <a href="#">
          <img :class="{'flip': isCollapsed}"
               src="../../assets/media/arrow_down.svg"
               style="margin-left: auto; margin-right: 8px" alt=""/>
        </a>
      </div>
      <div class="col-11 freePlace d-md-none d-block">
        <p>Свободно мест</p>
        <span>{{ freePlaces }} из {{ maxPlayerInTeam }}</span>
      </div>
    </div>
    <collapse-transition>
      <div v-if="event_team.team.players.length > 0 && isCollapsed">
        <div class="teamHeader d-flex justify-content-between">
          <div class="d-flex">
            <h5 class="d-none d-md-block">СТАТУС</h5>
            <h5>УЧАСТНИК</h5>
          </div>
          <h5>С НИМ ИДУТ</h5>
        </div>
        <div style="
              margin-bottom: 20px">
          <player-in-event-team
              v-for="player in event_team.team.players"
              :key="player.id"
              :player="player"
              :picture="player.avatar"
              :team="event_team.team"
              :is-captain="isCaptain"
              :in-united-team="event_team.team.is_united"
              @delete-player="deletePlayer"
              @set-check="setCheck"
              @add-new-player="addNewPlayer"
              @add-participant="addParticipant"
              @delete-participant="deleteParticipant"
              @change-name="$emit('refresh-lists')">
          </player-in-event-team>
        </div>
        <div class="row">
          <div class="col">
            <div class="text-center mb-3 d-md-flex justify-content-end">
              <my-button
                v-if="!event_team.team.is_united"
                class="btn mb-2 w-100"
                style="margin-right: 12px"
                @click="$refs.addPlayerModal.open()">Добавить участника
              </my-button>
              <my-button
                 v-if="!event_team.team.is_united"
                 style="margin-right: 12px"
                 class="btn mb-2 hollow w-100"
                 v-on:click="$emit('add-event-team')">Добавить состав
              </my-button>
              <my-button
                  v-if="!event_team.team.is_united"
                  class="btn mb-2 blue w-100"
                 v-on:click="deleteEventTeam(event_team.id)">Удалить состав
              </my-button>
            </div>
          </div>
        </div>
      </div>
    </collapse-transition>
    <my-modal ref="addPlayerModal">
      <add-new-player
          @add-new-player="addNewPlayer"
          class="mb-3 text-center"
          :team="event_team.team">
      </add-new-player>
    </my-modal>
    <confirm-modal ref="confirmDiaologue"></confirm-modal>
  <PlayerRegisterInfoModal ref="registerInfoModal"/>
</div>
</template>

<script>

import ConfirmModal from "../UI/ConfirmModal";
import PlayerInEventTeam from "./PlayerInEventTeam";
import AddNewPlayer from "./AddNewPlayer";
import MyButton from "../UI/MyButton";
import MyModal from "../UI/MyModal";
import CollapseTransition from '@ivanv/vue-collapse-transition/src/CollapseTransition.vue';
import PlayerRegisterInfoModal from "@/components/team/registration/PlayerRegisterInfoModal.vue";
import {handleError} from "@/utils/error";


export default {
  name: "event-team-manage",
  components: {
    PlayerRegisterInfoModal,
    MyModal, MyButton, ConfirmModal, PlayerInEventTeam, AddNewPlayer, CollapseTransition},
  props: {
    event_team: Object,
    event: Object,
    index: Number,
    isCaptain: Boolean,
  },
  data: function () {
    return {
      activeEdit: false,
      editEventTeamTitle: false,
      eventTeamTitle: '',
      isCollapsed: true
    }
  },
  mounted() {
    this.eventTeamTitle = this.event_team.title
  },
  computed: {
    // участвующие в игре
    participantsCount: function() {
      let count = 0
      if (this.event_team.event_players !== undefined){
        count = count + this.event_team.event_players.length
        for (let ep of this.event_team.event_players) {
          if (ep.participant_count>1) {
            count = count + ep.participant_count-1;
          }
        }
      }
      return count
    },
    // количество свободных мест
    // eslint-disable-next-line vue/return-in-computed-property
    freePlaces: function () {
      return this.maxPlayerInTeam-this.participantsCount;
    },
    maxPlayerInTeam(){
      if (this.event && this.event.place && this.event.place.max_players_in_team){
        return this.event.place.max_players_in_team
      } else {
        return 10
      }
    }
  },
  methods: {
    addNewPlayer() {
      this.$emit('refresh-lists')
      this.$refs.addPlayerModal.close()
    },
    activeChange: function (event) {
      let label = event.target.parentNode.previousElementSibling.childNodes[0];
      label.classList.add('d-none');
      label.nextElementSibling.classList.remove('d-none');
      label.nextElementSibling.getElementsByTagName('input')[0].focus();
      this.activeEdit = true;
      console.log(this.$refs.edit);
      this.$nextTick(() => this.$refs.edit.focus());

    },
    async deletePlayer(team_id, player_id) {
      const ok = await this.$refs.confirmDiaologue.show({
        message: 'Уверены, что хотите убрать игрока из команды?',
      })
      if (ok) {
        this.$emit('delete-player', team_id, player_id)
      } else {
        console.log('cancel');
      }
    },
    async deleteEventTeam(event_team_id) {
      const ok = await this.$refs.confirmDiaologue.show({
        message: 'Убрать дополнительный состав?'
      })
      if (ok) {
        this.$emit('delete-event-team', event_team_id);
      } else {
        console.log('cancel');
      }
    },
    showEditEventTeamTitle(){
      this.editEventTeamTitle=!this.editEventTeamTitle
      this.$nextTick(()=>{
        this.$refs.editEventTeamTitle.focus()
      })
    },
    updateEventTeamTitle(){
      let vm = this
      console.log(this.eventTeamTitle)
      this.editEventTeamTitle=false
      this.$api.updateEventTeam(vm.event_team.id, {title: vm.eventTeamTitle}, true)
          .then(()=>vm.$emit('refresh-lists'))
          .catch(handleError);
    },
    setCheck(playerId, check, itIsMe) {
      const vm = this;
      if (!check) {
        if (vm.participantsCount >= this.maxPlayerInTeam) {
          this.limitPlacesWarning()
        } else {
          this.$api.registerUserInEventTeam(playerId, vm.event_team.id)
              .then(()=>{
                vm.$emit('refresh-lists')
                vm.$store.dispatch('GET_USER_DETAILS')
                if (itIsMe) vm.$refs.registerInfoModal.registered()
              })
              .catch(handleError);
        }
      } else {
        const eventPlayer = vm.event_team.event_players.filter(ep => ep.user.id === playerId)[0]
        this.$api.deleteEventPlayer(eventPlayer.id)
            .then(()=>{
              vm.$emit('refresh-lists')
              vm.$store.dispatch('GET_USER_DETAILS')
              if (itIsMe) vm.$refs.registerInfoModal.unregitered()
            })
            .catch(error=>handleError(error));
      }
    },
    addParticipant: function (player) {
      const vm = this;
      let cp = vm.event_team.team.players.filter(p => p.id === player.id)[0];
      let eps = vm.event_team.event_players.filter(ep => ep.user.id === player.id);
      if (eps.length>0) {
        let ep = eps[0]
        if (vm.participantsCount < this.maxPlayerInTeam) {
          let preParticipnatCount = cp.participantCount + 1;
          this.$api.updateEventPlayer(ep.id, {
            user: player.id,
            event_team: this.event_team.id,
            participant_count: preParticipnatCount
          })
            .then(()=>{
              cp.participantCount = preParticipnatCount;
              ep.participant_count = cp.participantCount;
            })
            .catch(error=>handleError(error));
        } else {
          this.limitPlacesWarning()
        }
      }
    },
    limitPlacesWarning(){
      this.$refs.confirmDiaologue.show({
          message: `Количество участников в одной команде для заведения "${this.event.place.title}" может быть не больше ${this.maxPlayerInTeam} человек. Для участия можете добавить ещё один состав.`,
          okButton: 'OK',
          cancelButton: 'disable'
      })
    },
    deleteParticipant: function (player) {
      const vm = this;
      let cp = vm.event_team.team.players.filter(p => p.id === player.id)[0];
      let eps = vm.event_team.event_players.filter(ep => ep.user.id === player.id);
      if (eps.length>0){
        let ep = eps[0]
        if (cp.participantCount > 1) {
          let preParticipnatCount = cp.participantCount - 1;
          this.$api.updateEventPlayer(ep.id, {
            user: player.id,
            event_team: this.event_team.id,
            participant_count: preParticipnatCount
          })
              .then(()=>{
                cp.participantCount = preParticipnatCount;
                ep.participant_count = cp.participantCount;
              })
              .catch(handleError)
        }
      }
    },
  },
}
</script>

<style scoped>

.arrowBtn {
  direction: rtl;
}
.teamHeader {
  padding-left: 6px;
}
.eventTeamHeader {
  align-items: center;
  background-color: #33347E;
  border-radius: 1rem;
  padding: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.freePlace p{
  font-size: 12px;
  line-height: 100%;
  opacity: 0.6;
  text-transform: uppercase;
}
.freePlace span{
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  opacity: 1;
  margin-top: 4px;
  display: block;
}
h3 {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin-right: 8px
}
.disabled {
  background: transparent!important;
  border: none!important;
  padding: 0!important;
  margin-right: 8px!important;
}
.disabled .btnIcon {
  margin-left: 0!important;
}
.edit {
  max-width: 20px;
}
.editEventTeamTitle {
  background: transparent;
  display: block;
  border: none;
  color: white
}
.form-check-input{
  height: 2em;
  width: 2em;
}
.form-check-input:checked {
  background-color: #9619b4;
  border-color: #460653;
}
.change-icon, .delete-icon , .save-name-icon ,.bi-dash ,.bi-plus {
  cursor: pointer;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@media (max-width: 576px) {
  .freePlace span {
    text-align: left;
  }
  .freePlace {
    /*margin-left: 30px;*/
  }
  .eventTeamHeader {
    padding: 16px 0;
  }

  .edit {
    margin: 0 8px 0 0;
  }
  .freePlace p {
    margin-top: 8px;
    text-align: left;
  }
  .teamHeader h5:last-child {
    margin-left: 0;
  }
}


@media (min-width: 577px) {
  .checkPlayer {
    height: 16px;
    width: 16px;
  }
}
@media (min-width: 767px) {
  .freePlace {
    margin-left: auto;
  }
  .freePlace p {
    text-align: right;
  }
  .freePlace span {
    text-align: right;
  }
  .edit {
    margin-right: auto;
    margin-left: 8px;
  }
}
</style>
