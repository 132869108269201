<template>
<transition name="fade">
  <pre-loader v-if="loading"/>
  <div v-else-if="vote.themes.length>0 && isVoted === false">
      <div>
        <div class="formWrap formWrap__vote blockWrapper">
          <h4 class="formTitle formTitle__vote">
            Голосование
          </h4>
          <p class="formSubtitle formSubtitle__vote">
            Выберите одну или две темы. На следующей неделе
            будет та, которая наберет больше всего голосов.
          </p>
          <div class="row m-0 m-lg-4">
            <my-button 
              class="d-lg-flex justify-content-between col col-lg-6 themeVote"
              v-for="theme in vote.themes"
              :key="theme.id"
              @click="selectTheme(theme)"
              :cls="theme.isSelected?'purple':'blue'" 
              ><img :src="glb.getMediaUrl(theme.picture)" alt="">
              <p class="themeTitle">
                {{theme.title}}
              </p>
            </my-button>
          </div>
          <div class="formBtns formBtns__vote d-md-flex">
            <my-button 
            cls="blue"
            @click="$emit('close')"
            >
              Назад
            </my-button>
            <my-button 
            cls="pink" 
            mailIcon
            @click="voteTheme()"
            >
              Отправить
            </my-button>
          </div>
        </div>
      </div>
  </div>
  <div v-else-if="voteSent || isVoted">
    <div class="row justify-content-center">
      <div class="col" style="max-width: 400px">
      <h2 class="text-center my-5">Спасибо, <br> Ваш голос учтён!</h2>
      <my-button @click="$router.push({name: 'home'})">На главную</my-button>
      </div>
    </div>
  </div>
</transition>
</template>

<script>
import {mapActions} from "vuex";
import MyButton from "../UI/MyButton";
import PreLoader from "../../App.vue";

export default {
  name: "GameVote",
  components: {PreLoader, MyButton},
  data() {
    return {
      vote: {
        themes: []
      },
      selectedThemes: [],
      loading: true,
      isVoted: false,
      viewed: false,
      buttonCls: 'blue',
      chosenTheme: {},
      voteSent: false
    }
  },
  created: function () {
    this.loadData();
  },

  methods: {
    ...mapActions(['SHOW_AUTH_MODAL']),
    loadData: function () {
      const vm = this;
      vm.loading = true
      this.axios.get(
          `/api/vote/`,
          {
            headers: {
              'Authorization': `Token ${vm.$store.state.user.authToken}`
            }
          })
          .then(function (response) {
            console.log(response.data);
            let vote = response.data
            vote.all_votes = vote.user_votes.length
            vote.themes.forEach(function (theme) {
              theme.isSelected = false
            })
            // vote.themes.forEach(function (theme) {
            //   theme.isSelected = vote.user_votes.filter(uv => uv.user === vm.$store.state.user.id && uv.theme === theme.id).length>0;
            //   theme.votes = vote.user_votes.filter(uv => uv.theme === theme.id).length;
            //   theme.percent = (vote.all_votes>0) ? (theme.votes)/(vote.all_votes)*100 : 0;
            // })
            vm.isVoted = vote.user_votes.filter(uv => uv.user === vm.$store.state.user.id).length>0;
            vm.vote = vote
          })
          .catch(function (error) {
            console.log(error)
            vm.$toast.error(error)
          })
      .finally(()=>vm.loading=false)
    },
    voteTheme: function () {
      const vm = this;
      this.axios.post(
          `/api/vote/${vm.vote.id}/`,
          {themes: vm.selectedThemes.map(theme=>theme.id)},
          {
            headers: {
              'Authorization': `Token ${vm.$store.state.user.authToken}`
            }
          })
          .then(function (response) {
            console.log(response.data);
            // vm.chosenTheme.votes += 1;
            // vm.vote.all_votes += 1;
            // vm.vote.themes.forEach(function (theme) {
            //   theme.percent = theme.votes/vm.vote.all_votes*100
            // })
            vm.isVoted = true;
            vm.voteSent = true
            setTimeout(function() {
              vm.voteSent = false
              vm.$emit('close')
            }, 3000)
            
            // document.getElementById(`theme${theme.id}`).classList.add('pink')
            console.log(vm.isVoted)

          })
          .catch(function (error) {
            console.log(error)
            vm.$toast.error(error)
          });
    },
    selectTheme: function(theme) {
      if (this.selectedThemes.includes(theme)) {
        this.selectedThemes = this.selectedThemes.filter(th=>th!==theme)
      } else {
        if (this.selectedThemes.length > 1) this.selectedThemes.shift()
        this.selectedThemes.push(theme)
      }
      this.vote.themes.forEach((voteTheme)=>{
        voteTheme.isSelected = this.selectedThemes.includes(voteTheme)
      })
    },
  },
}
</script>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
.blockWrapperTitle {
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.375rem;
  margin: 0;
}
.blockWrapper {
  max-width: 24rem;
  margin: 0 auto;
  padding: 0;
}
.formWrap {
  background: #33347E;
  border-radius: 20px;
}
.formWrap__vote {
  padding: 0;
}
.formTitle {
  font-size: 10px;
  line-height: 24px;
  letter-spacing: 0.165em;
  text-transform: uppercase;
  opacity: 0.6;
  text-align: left;
}
.formTitle__vote {
  padding: 30px 30px 0 30px;
}

.formSubtitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
  margin-bottom: 20px;
}
.formSubtitle__vote {
  padding: 0 30px;
}
.formDescription {
  text-align: left;
  font-size: 14px;
  margin-bottom: 30px;
}
.formBtns {
  padding: 10px;
}
.formBtns__vote {
  padding: 20px 30px;
}
.button img{
  width: 140px;
  border-radius: 8px;
}
.button {
  padding: .625rem;
  width: 100%;
  margin-bottom: .5rem
}
.themeVote {
  border: none;
  min-height: 120px;
}
.themeTitle {
  font-weight: 600;
  font-size: 14px;
  line-height: 143%;
  margin-right: auto;
  margin-left: 1rem;
}

/* xs - устройства (до 576px) */
/* CSS для ширины, которая меньше 575px (включительно) */

/* sm-устройства (больше или равно 576px) */
@media (min-width: 576px) {
  /* CSS для: 576px <= ширины <= 767px */
  .newsItem {
    margin-right: 20px;
  }
  /* полоса прокрутки (скроллбар) */
  ::-webkit-scrollbar {
    width: 1px; /* ширина для вертикального скролла */
    height: 100%;
    border-radius: 16px;
    background: rgba(255, 255, 255, 0.1);
  }

  /* ползунок скроллбара */
  ::-webkit-scrollbar-thumb {
    width: 3px!important;
    background: #E32289;
    border-radius: 16px;
  }
}

/* md-устройства (больше или равно 768px) */
@media (min-width: 768px) {
  /* CSS для: 768px <= ширины <= 991px */
  .gameImg {
    border-radius: 1.375rem 0 0 1.375rem;
  }
.formBtns__vote button {
    padding: 10px 20px;
    margin-left: 10px;
  }
}

/* lg-устройства (больше или равно 992px) */
@media (min-width: 992px) {
  /* CSS для: 992px <= ширины <= 1119px */
}

/* xl-устройства (больше или равно 1200px) */
@media (min-width: 1200px) {
  /* CSS для: ширины >= 1200px */
  .themeVote {
    width: 48%;
    margin: 4px auto;
    border-radius: 1rem;
  }
  .formWrap__vote {
    max-width: none;
  }
  .formBtns__vote {
    padding: 20px 30px;
    margin-left: auto;
    width: 50%;
  }

}

</style>