<template>
    <div class="teamDetailsContainer">
        <p class="infoSpan">Информация о команде</p>
        <p class="teamName">Команда название команды В</p>
        <p class="infoHeader marginBottom">Регистрация на игру</p>
        <div class="registrationTable"></div>
        <div class="detailsRow infoHeader">
            <p>Зарегистрировалось</p>
            <p>{{teamDetails.players.length}}</p>
        </div>
        <div class="detailsRow infoHeader">
            <p>Средний чек состава</p>
            <p>210 ₽</p>
        </div>
        <div class="detailsRow infoHeader">
            <p>Показатель активности</p>
            <p>?</p>
        </div>
        <div class="detailsRow infoHeader">
            <p>Прием легионеров</p>
            <p v-if="teamDetails.is_recruiting == true">Да</p>
            <p v-if="teamDetails.is_recruiting == false">Нет</p>
        </div>
        <div class="detailsRow infoHeader">
            <p>Все члены легионеры</p>
            <p>Да</p>
        </div>
        <div class="section">
            <p class="infoHeader">Состав</p>
            <player-in-sitting-team
                v-for="player in teamDetails.players"
                :key="player.phone"
                :playerDetails="player"
                :teamDetails="teamDetails"
            ></player-in-sitting-team>
        </div>
        <my-button class="blue w-100">Закрыть</my-button>
    </div>
</template>

<script>
import MyButton from '../UI/MyButton';
import PlayerInSittingTeam from './PlayerInSittingTeam';
export default {
    name: 'SittingTeamDetails',
    components: { PlayerInSittingTeam, MyButton },
    props: [ 'teamDetails' ]
}
</script>
<style scoped>
.section {
    margin-bottom: 2rem;
    margin-top: 2rem;
}
.detailsRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.marginBottom {
    margin-bottom: 1rem;
}
.teamDetailsContainer {
    padding: 1rem;
    background: #33347E;
}
.infoSpan {
    font-size: 10px;
    line-height: 24px;
    opacity: 0.6;
    margin-bottom: 1rem;
}
.teamName {
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 2rem;
}
.inforHeader {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
}
.registrationTable {
    width: 100%;
    height: 180px;
    background: #2E3077;
}
</style>