<template>
  <footer class="footer" id="footer">
    <div class="container">
      <div class="row d-flex justify-content-between align-items-center">
        <div class="d-none d-md-block col col-md-4">
          <div class="">
            <p class="" style="margin-top: 4px">
              {{ new Date().getFullYear() }} © All Rights Reserved
            </p>
          </div>
        </div>
        <div class="col col-md-4 justify-content-center d-flex">
          <div class="footerLogo">
<!--            <img v-if="common.logo" :src="glb.getMediaUrl(common.logo)" alt="">-->
            <img class="position-absolute" src="../../assets/media/logo.png" style="max-width: 86px"/>
            <div class="position-relative logoBackground"></div>
          </div>
        </div>
        <div class="d-none d-md-flex col-md-4 justify-content-end">
<!--            <a href="#" class="">-->
<!--              <img class="mx-4" src="../../assets/media/soc_icon_01_facebook.svg" />-->
<!--            </a>-->
<!--            <a href="#" class="">-->
<!--              <img class="mx-4" src="../../assets/media/soc_icon_02_instagram.svg" />-->
<!--            </a>-->
<!--            <a href="#" class="">-->
<!--              <img class="mx-4" src="../../assets/media/soc_icon_03_vk.svg" />-->
<!--            </a>-->
          <div class="d-flex align-items-center">
            <img class="" src="../../assets/media/soc_icon_04_instagram.svg" />
            <p style="margin-left: 8px;margin-top: 4px">+79934516288</p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "MainFooter",
  props: ['common'],
}
</script>

<style scoped>
.footer {
  background: radial-gradient(100% 9772.83% at 50% 58.74%, rgba(252, 166, 217, 0.45) 0%, rgba(62, 63, 139, 0) 52.88%);
}
.footerLogo {
  padding-top: 1.5rem;
}
.logoBackground {
  width: 5.5rem;
  height: 5.5rem;
  background: rgba(213,35,136,.3);
  filter: blur(4rem);
}
</style>
