<template>
<div class="container">
  <login/>
</div>
</template>

<script>
import Login from "../components/Login";
export default {
  name: "LoginView",
  components: {Login}
}
</script>

<style scoped>

</style>