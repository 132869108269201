<template>
<div class="mb-3">
      <div v-if="!isList" class="product col">
        <div>
          <div class="productImage"
          @click="$refs.modal.open()"
          :style="'background-image: url('+product.picture+')'"
        >
        </div>
        <div class="productDescriptionDiv">
          <p class="productTitle">{{product.title}}</p>
          <p class="productDescription">{{product.description}}</p>
        </div>
        </div>
        <div class="productAction">
          <div class="productPrice">
            <p>{{product.price}}</p>
            <p class="ms-1">₽</p>
<!--            <p class="initialPrice ms-2">280</p>-->
<!--            <p class="initialPrice">₽</p>-->
          </div>
          <div @click="addToCart(product)" class="productIconContainer">
            <div class="productIcon"></div>
            <p class="productIcontext">В корзину</p>
          </div>
        </div>
      </div>
      <div v-if="isList" class="listContainer">
        <div class="listImage"
          @click="$refs.modal.open()"
          :style="'background-image: url('+product.picture+')'"
        ></div>
        <div>
          <p class="listTitle">{{product.title}}</p>
          <p class="listDescription">{{product.description}}</p>
          <p class="listPriceSM">{{product.price}}&nbsp;₽</p>
        </div>
          <p class="listPrice">{{product.price}}&nbsp;₽</p>
        <div class="listCartContainer" @click="addToCart(product)">
          <div class="listCartIcon"></div>
        </div>
      </div>
      <my-modal ref="modal">
        <div class="modalContainer">
          <div class="bigImage" :style="'background-image: url('+product.picture+')'"></div>
            <div class="modalContentContainer">
            <p class="bigHeader">{{product.title}}</p>
            <p class="bigDescription">{{product.description}}</p>
            <div class="bigPriceDiv">
              <div class="d-flex align-items-center">
                <p class="bigPrice">{{product.price}}&nbsp;₽</p>
              </div>
              <div class="d-flex justify-content-between align-items-center">
                <my-button @click="minusQuantity" class="minusBtn" cls="hollow">-</my-button>
                <p style="width: 12px">{{quantity}}</p>
                <my-button @click="plusQuantity" class="plusBtn" cls="hollow">+</my-button>
              </div>
            </div>
            <my-button @click="addToCart(product)" cls="blue" class="w-100 mb-2">В Корзину</my-button>
          </div>
        </div>
      </my-modal>
</div>
</template>

<script>
import MyModal from "../UI/MyModal";
import MyButton from "../UI/MyButton";
import {handleError} from "@/utils/error";

export default {
  name: "Product",
  components: {MyButton, MyModal},
  props: ['product', 'cart', 'isList', 'eventTeam'],
  data () {
    return {
      showModal: false,
      quantity: 1,
    }
  },
  computed:{
    total() {
      return this.product.price * this.quantity
    }
  },
  methods: {
    async addToCart() {
      const vm = this
      if (!vm.$store.state.user.authToken){
        console.log('show auth')
        vm.emitter.emit('show-auth-modal')
        return
      }
      let cart = vm.cart
      if (!cart){
        try{
          cart = await vm.$api.createEventTeamPlayerCart(vm.eventTeam.id)
        } catch (error){
          return handleError(error)
        }
      }
      try{
        await vm.$api.createProductToCart({
              cart: cart.id,
              product: vm.product.id,
              quantity: vm.quantity,
            })
      } catch(error){
        return vm.$toast.error(error)
      }
      this.emitter.emit('load-cart')
    },
    minusQuantity() {
      if (this.quantity>1) {
        this.quantity-=1
      }
    },
    plusQuantity() {
      if (this.quantity<99) {
        this.quantity+=1
      }
    }
  },

}
</script>

<style scoped>
.modalContainer {
  max-width: 22.5rem;
}
.listPriceSM {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin-left: 1rem;
  margin-top: 0.5rem;
}
.listPrice {
  display: none;
  font-weight: 600;
  font-size: 32px;
  margin-right: 2rem;
}
.listTitle {
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
  margin-left: 1rem;
}
.listDescription {
  display: none;
  margin-left: 1rem;
  font-size: 13px;
  line-height: 15px;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  max-width: calc(30vw - 10px);
}
.listCartContainer {
  position: absolute;
  right: 0;
  width: 60px;
  height: 100%;
  background: #3B3C8D;
  border-radius: 0px 20px 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.listCartContainer:active {
  background: #cecece;
}
.listCartIcon {
  background-image: url(../../assets/media/bag.svg);
  width: 24px;
  height: 24px;
  background-position: center;
  background-size: cover;
}
.listContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
  background: #33347E;
  border-radius: 20px;
  padding-left: 90px;
  padding-right: 60px;
  height: 80px;
}
.listImage {
  position: absolute;
  left: 10px;
  margin-top: 8px;
  margin-bottom: 8px;
  width: 80px;
  height: 64px;
  border-radius: 12px;
  background-position: center;
  background-size: cover;
}
.minusBtn {
  font-weight: 400;
  margin-right: 1rem;
  font-size: 2.5rem;
  padding-bottom: 6px;
  padding-right: 6px;
  padding-left: 6px;
  padding-top: 0px;
  border-radius: 12px;
}
.plusBtn {
  padding: 3px;
  margin-left: 1rem;
  font-size: 2.5rem;
  font-weight: 400;
  border-radius: 12px;
}
.bigImage {
  width: 18rem;
  height: 18rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 20px;
  margin-left: auto;
  margin-right: auto;
}
.bigDescription {
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  margin-bottom: 1rem;
}
.bigHeader {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}
.bigPriceDiv {
  display: flex;
  height: 5rem;
  padding: 30px;
  background-color: #3B3C8D;
  border-radius: 20px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}
.bigPrice {
  font-weight: 600;
  font-size: 2rem;
  margin-right: 12px;
}
.initialPrice {
  font-weight: 400;
  font-size: 0.8125rem;
  line-height: 1.375rem;
  text-decoration-line: line-through;
  opacity: .7;
}
.product {
  padding: 0;
  margin-bottom: 2.5rem;
  display: flex;
  flex-direction: column;
  /*align-items: center;*/
  /*justify-content: center;*/
  justify-content: space-between;
  height: 100%;
}
.productTitle {
  /*width: 9.7rem;*/
  width: 100%;
  font-weight: 600;
  font-size: 0.8125rem;
  line-height: 1.375rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.productDescriptionDiv {
  /*width: 9.7rem;*/
  width: 100%;
  margin-bottom: 1rem;
}
.productDescription {
  font-weight: 400;
  font-size: 0.6875rem;
  line-height: 0.9375rem;
}
.productPrice {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #33347E;
  border-radius: 8px 0px 0px 8px;
  width: 7.1875rem;
  height: 2.5rem;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.375rem;
}
.productAction {
  display: flex;
}
.productIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  background: #3B3C8D;
  border-radius: 0px 8px 8px 0px;
  cursor: pointer;
}
.productIcon {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(../../assets/media/bag.svg);
  width: 1.125rem;
  height: 1.125rem;
}
.productImage {
  position: relative;
  /*width: 9.7rem;*/
  width: 100%;
  height: 7.75rem;
  border-radius: 16px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.productIcontext {
  display: none;
  font-size: 14px;
  line-height: 143%;
  opacity: 0.6;
}
.productIcontext:active {
  font-size: 16px;
  line-height: 143%;
  opacity: 1;
}
@media (min-width: 576px) {
  .listContainer {
    height: 100px;
    padding-left: 110px;
  }
  .listImage {
    width: 100px;
    height: 80px;
  }
  .listPriceSM {
    display: none;
  }
  .listPrice {
    display: block;
  }
  .listTitle {
    font-size: 16px;
    line-height: 22px;
  }
  .productImage {
      height: 11.25rem;
    }
    /*.productDescriptionDiv {*/
    /*  width: 14rem;*/
    /*}*/
    .productTitle {
      font-size: 14px;
    }
    .productDescription {
      font-size: 13px;
      line-height: 15px;
    }
    /*.productAction {*/
    /*  width: 14rem;*/
    /*}*/
    .productPrice {
      width: 100%;
    }
    .productIconContainer {
      width: 100%;
    }
    .productIcon {
      display: none;
    }
    .productIcontext {
      display: block;
    }
}
@media (min-width: 768px) {
  .listDescription {
    display: block;
  }
  .listCartContainer {
    width: 100px;
  }
  .listContainer {
    padding-right: 100px;
  }
  .listCartIcon {
    width: 32px;
    height: 32px;
  }
  .modalContainer {
    display: flex;
    align-items: center;
    max-width: 100%;
  }
  .modalContentContainer {
    max-width: 22.5rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .bigHeader {
    font-size: 26px;
    line-height: 32px;
  }
}
@media (min-width: 1200px) {

}
</style>
