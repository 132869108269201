<template>
<div class="cartItemContainer">
  <div class="d-flex">
    <div class="cart_item__picture"
         :style="{backgroundImage: 'url('+item.product.picture+')'}"
    ></div>
<!--    <img :src="item.product.picture" style="height: auto; width: 80px;">-->
    <div class="detailsContainer">
      <div class="productTitle ms-2">{{item.product.title}}</div>
      <div v-if="!foreign" class="d-flex align-items-center mt-3 ms-2">
        <my-button @click="minusQuantity" class="minusBtn" cls="hollow">-</my-button>
        <p>{{item.quantity}}</p>
        <my-button @click="plusQuantity" class="plusBtn" cls="hollow">+</my-button>
      </div>
      <div v-else class="d-flex justify-content-between align-items-center mt-3 ms-2">
          <p>{{item.quantity}} шт.</p>
          <p>{{ total }}&nbsp;₽</p>
      </div>
    </div>
    <div class="ms-auto">
      <div v-if="!foreign" class="d-flex flex-column align-items-end">
        <my-button @click="deleteItem" class="deleteButton"><span class="buttontext">X</span></my-button>
        <div class="text-end pe-2 mt-3" style="width: max-content">{{ total }}&nbsp;₽</div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "CartItem",
  props: ['item', 'foreign'],
  computed: {
    total() {
        let sum = this.item.product.price * this.item.quantity
      return sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
  },
  methods: {
    minusQuantity() {
      if (this.item.quantity>1) {
        this.updateItem(this.item.quantity-1);
      }
    },
    plusQuantity() {
      if (this.item.quantity<99) {
        this.updateItem(this.item.quantity+1);
      }
    },
    updateItem(quantity) {
      let vm = this;
      vm.axios.patch(
          `/api/preorder/cart_item`,
          {
            cart: vm.item.cart,
            product: vm.item.product.id,
            quantity: quantity,
          },
          {headers: {'Authorization': `Token ${vm.$store.state.user.authToken}`}}
      )
          .then((response) => {
            console.log(response);
            this.emitter.emit('load-cart');
          })
          .catch((error) => {
            vm.$toast.error(error);
          })
    },
    deleteItem() {
      let vm = this;
      vm.axios.delete(
          `/api/preorder/cart_item/${vm.item.id}`,
          {headers: {'Authorization': `Token ${vm.$store.state.user.authToken}`}}
      )
          .then((response) => {
            console.log(response);
            this.emitter.emit('load-cart');
          })
          .catch((error) => {
            vm.$toast.error(error);
          })
    },
  }
}
</script>

<style scoped>
.detailsContainer {
  width: 8rem;
}
.cartItemContainer {
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  padding-top: 1rem;
  margin-bottom: 1rem;
}
.productTitle {
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
}
.cart_item__picture{
  width: 100px;
  height: 80px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 8px;
}
.minusBtn {
  font-weight: 400;
  margin-right: 0.5rem;
  font-size: 2.5rem;
  padding-bottom: 6px;
  padding-right: 6px;
  padding-left: 6px;
  padding-top: 0px;
  border-radius: 12px;
}
.plusBtn {
  padding: 3px;
  margin-left: 0.5rem;
  font-size: 2.5rem;
  font-weight: 400;
  border-radius: 12px;
}
.deleteButton {
  font-size: 1rem;
  font-weight: 400;
  padding: 5px 10px;
  background: #33347E;
  border-radius: 1rem;
  border: none;
}
.buttontext {
  opacity: .5;
}
</style>
