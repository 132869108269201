<template>
  <div v-if="count" class="notification position-absolute">
    {{ count }}
  </div>
</template>

<script>
export default {
  name: "Notification",
  props: {
    count: {
      type: Number,
      required: true
    }
  }

}
</script>

<style scoped>
.notification {
  background-color: #E32289;
  border-radius: 50%;
  font-size: 10px;
  line-height: 143%;
  top: -8px;
  right: -24px;
  width: 16px;
  padding: 2px 2px 0 2px;
  text-align: center;
}
</style>