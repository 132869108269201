import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueAxios from "vue-axios";
import axios, {Axios} from "axios";
import { MonthPicker, MonthPickerInput } from 'vue-month-picker'
import { global_objects } from "./global_objects";
import components from './components/UI';
import Toaster from '@meforma/vue-toaster';
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.min"
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'animate.css'
import mitt from "mitt";
import Vue3TouchEvents from "vue3-touch-events";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import api from './api'
import logApi from './api/logApi'
import templatesApi from './api/templatesApi'
import YmapPlugin from 'vue-yandex-maps'
import "./assets/css/style.css"


const emitter = mitt();
const app = createApp(App,{
    http: Axios,
})

app.config.globalProperties.emitter = emitter;

app.config.globalProperties.$api = api
app.config.globalProperties.$logApi = logApi
app.config.globalProperties.$templatesApi = templatesApi
app.config.globalProperties.glb = global_objects
components.forEach(component => {
    app.component(component.name, component)
})
app.config.devtools = true

const yandexMapSettings = {
  apiKey: '621024c2-1678-471e-b891-3bc96929df7c',
  lang: 'ru_RU',
  coordorder: 'latlong',
  enterprise: false,
  version: '2.1'
}
app.use(YmapPlugin, yandexMapSettings)

app.use(MonthPicker)
app.use(MonthPickerInput)
app.use(Toaster, { position:"top", duration:2300})
app.use(Toast, {})
app.use(store)
app.use(router)
app.use(VueAxios, axios)
app.use(Vue3TouchEvents);

app.mount('#app')
// const token = global_objects.getCookie('quiz_token');
// if (token) {
//     vm.$http.defaults.headers.common['Authorization'] = `Token ${token}`
// }
