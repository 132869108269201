import axios from "axios";
import store from "@/store";
import { useToast } from "vue-toastification";
import {global_objects} from "../global_objects";

const toast = useToast()

class BaseApi {
    constructor(BASE_URL = "/api/") {
        this.BASE_URL = BASE_URL
    }

    _request(params, toastification=false) {
        if (!params.method) params.method = 'get'
        if (store.state.user.authToken) {
            params.headers = {'Authorization': `Token ${store.state.user.authToken}`}
        }
        params.baseURL = this.BASE_URL
        return new Promise((resolve, reject) => {
            axios(params)
                .then(res => resolve(res.data))
                .catch(err => {
                    if (err.response) {
                        if (err.response.status === 401) {
                            store.dispatch('LOGOUT')
                            if(toastification){
                                toast.error(err.toString())
                            }
                        }
                        // if (err.response.data.detail) toast.error(err.response.data.detail)
                    }
                    reject(err)
                })
        })
    }
    updateUser(data){
        return this._request({
            method: 'patch',
            url: 'user/update/',
            data: data
        })
    }
    getOrCreateUser(phone, firstName){
        return this._request({
            method: 'post',
            url: 'create_user',
            data: {
                phone: global_objects.formatPhone(phone),
                name: firstName
            },
        })
    }
    updateUserPut(data){
        return this._request({
            method: 'put',
            url: 'user/',
            data: data
        })
    }
    confirmNewPhone(code){
        return this._request({
            method: 'post',
            url: 'user/update/phone_confirm/',
            data: {code: code}
        })
    }
    register_on_event_on_united_team(eventId){
        return this._request({
            method: 'post',
            url:`event/${eventId}/register_on_united_team/`}
        )
    }

    // GamesView
    getFutureGames(){
        return this._request({url:'/games/future/'})
    }
    getMyGames(){
        return this._request({url:'/games/my/'})
    }
    getInDevelopingGames(){
        return this._request({url:'/games/developing/'})
    }
    getFavoriteGames(){
        return this._request({url:'/games/favorite/'})
    }
    getPastGames(page=1){
        return this._request({url:`/games/past/?page=${page}`})
    }

    // EventTeams

    getOrCreateEventTeam(eventId, teamId){
        return this._request({url: `/event/${eventId}/team/${teamId}/`})
    }
    createEventTeam(eventId, teamId){
        return this._request({
            method: 'post',
            url: '/event_team/',
            data:{
                event: eventId,
                team: teamId
            }
        })
    }
    updateEventTeam(eventTeamId, data, partial=false){
        return this._request({
            method: partial ? 'patch' : 'put',
            url: `/event_team/${eventTeamId}/`,
            data: data
        })
    }
    deleteEventTeam(eventTeamId){
        return this._request({
            method: 'delete',
            url:`/event_team/${eventTeamId}/`
        })
    }
    registerUserInEventTeam(userId, eventTeamId){
        return this._request({
            method: 'post',
            url: '/event_player/',
            data:{
                user: userId,
                event_team: eventTeamId
            }
        })
    }
    deleteEventPlayer(eventPlayerId){
        return this._request({
            method: 'delete',
            url: `/event_player/${eventPlayerId}/`
        })
    }
    updateEventPlayer(eventPlayerId, data){
        return this._request({
            method: 'put',
            url: `/event_player/${eventPlayerId}/`,
            data: data
        })
    }
    deleteEventPlayerByTeamId(teamId, userId){
        return this._request({
            method: 'delete',
            url: `/team/${teamId}/user/${userId}/`
        })
    }
    getEventDetails(eventId){
        return this._request({url:`/event/${eventId}/`})
    }
    getThemes(){
        return this._request({url: '/theme/'})
    }
    getEventDetailsWithoutToken(eventId){
        return this._request({url:`/allow_any/event/${eventId}/`})
    }
    getEventListWithoutToken(params){
        return this._request({url: 'allow_any/event/', params: params})
    }
    sendEventRegistrationForm(eventId, data){
        return this._request({
            method: 'post',
            url: `event_registration_form/${eventId}/`,
            data: data
        })
    }
    validateAuthCode(phone, code){
        return this._request({
            method: 'post',
            url: 'validate/',
            data: {
                phone: global_objects.formatPhone(phone),
                code: code
            }
        })
    }
    getEventPreorder(eventId){
        return this._request({url: `event/${eventId}/preorder/`})
    }
    getEventPreorderBySecretSlug(secretSlug, date=undefined){
        return this._request({url: `secret_preorder/${secretSlug}/`, params:{date: date}})
    }
    getMenuByPlaceId(placeId){
        return this._request({
            url: `preorder/?place=${placeId}`,
        })
    }
    getMenuCategoryDetail(placeId, categoryId){
        return this._request({
            url: `preorder/${categoryId}/?place=${placeId}`,
        })
    }
    getEventTeamDetails(eventTeamId){
        return this._request({
            url: `event_team/${eventTeamId}/`
        })
    }
    getEventTeamPlayerCart(eventTeamId){
        return this._request({
            url: `preorder/event_team/${eventTeamId}/cart/`
        })
    }
    listEventTeamCarts(eventId, eventTeamId) {
      return this._request({
        url: `preorder/${eventId}/${eventTeamId}/carts/`
      })
    }
    deleteEventTeamPlayerCart(cartId) {
      return this._request({
          method: 'delete',
          url: `preorder/carts/${cartId}/`,
      })
    }
    generateAnonymousPreorderToken(eventTeamId) {
      return this._request({ url: `preorder/${eventTeamId}/token/` })
    }
    sendAnonymousPreorderToken(eventId, token, data) {
      return this._request({
        method: 'post',
        url: `preorder/event/${eventId}/token/${token}/`,
        data: data,
      })
    }
    hasPhoneOrInvited(eventId) {
      return this._request({url: `preorder/event/${eventId}/access/`})
    }
    createEventTeamPlayerCart(eventTeamId){
        return this._request({
            method: 'post',
            url: `preorder/event_team/${eventTeamId}/cart/`
        })
    }
    createProductToCart(data){
        return this._request({
            method: 'post',
            url: `preorder/cart_item`,
            data: data
        })
    }
    setLike(data){
        return this._request({
            method: 'post',
            url: 'user/like',
            data: data
        })
    }
    getEventResults(eventId){
        return this._request({
            url: `events_scores/${eventId}`
        })
    }
    getGameThemeTrialGamePackage(gameThemeId){
        return this._request({
            url: `theme/${gameThemeId}/trial_game_package/`
        })
    }
    addPlayerInTeam(teamId, data){
        return this._request({
            method: 'post',
            url: `team/${teamId}/new_player/`,
            data: data
        })
    }
    patchTeam(teamId, data){
        return this._request({
            method: 'patch',
            url: `team/${teamId}/`,
            data: data
        })
    }
    createTeam(data){
        return this._request({
            method: 'post',
            url: `team/`,
            data: data
        })
    }
}


export default new BaseApi()
