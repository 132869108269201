import store from "@/store";
import api from "@/api";

function getUserEventTeamFromEvent(event, userId){
    if (!userId) userId = store.state.user.id
    try{
        return event.event_teams.filter(et=>{
            return et.event_players.filter(ep=>ep.user.id==userId).length
        })[0]
    } catch (err){
        return false
    }
}
function userIsRegisteredOnEvent(event, userId){
    if (!userId) userId = store.state.user.id
    return getUserEventTeamFromEvent(event, userId)
}

function userIsRegisteredOnEventTeam(eventTeam, userId){
    if (!userId) userId = store.state.user.id
    try{
        return eventTeam.event_players.filter(ep=>ep.user.id==userId).length
    } catch (err){
        return false
    }
}

function getUnitedEventPlacesForSelect(event){
    let options = [{'id': '', 'title': 'Выберите место' }]
    if (!event.unitedEvents) return options
    const getPlaceTitle = (place)=>{
        try{
          return `${place.title}, ${place.address}`
        } catch (error) {
          return `неизвестно`
        }
    }
    options = options.concat(event.unitedEvents.map(ev=> {
        return {
          id: ev.id,
          title: getPlaceTitle(ev.place)
        }
    }))
    return options

}

async function getEventDetails(eventId){
    const eventData = await api.getEventDetailsWithoutToken(eventId)
      if (eventData.united && eventData.united_tag){
          const unitedEvents = await api.getEventListWithoutToken({
              united_tag: eventData.united_tag,
              date: eventData.date
          })
          eventData.unitedEvents = unitedEvents
      }
    return eventData
}

export {
    getUserEventTeamFromEvent,
    userIsRegisteredOnEventTeam,
    userIsRegisteredOnEvent,
    getEventDetails,
    getUnitedEventPlacesForSelect
}


