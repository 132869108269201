<template>
<div class="container">
  <game-vote/>
</div>
</template>

<script>
import GameVote from "../components/cabinet/GameVote.vue";

export default {
  name: "GameVoteView",
  components: {GameVote}
}
</script>

<style scoped>

</style>