<template>
    <div class="discountContainer">
        <p> {{content}} </p>
    </div>
</template>

<script>
export default {
    name: 'Discount',
    props: ['content'],
}
</script>

<style scoped>
.discountContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;

    position: absolute;
    height: 2rem;
    right: 0;
    top: 1rem;

    background: #E32289;
    opacity: 0.8;
    border-radius: 8px 0px 0px 8px;
    font-weight: 600;
    font-size: 0.6875rem;
    line-height: 1.375rem;
}
@media screen and (min-width: 1200px) {
    .discountContainer {
        font-size: 14px;
        line-height: 22px;
    }
}
</style>