<template>
  <div class="gameWrap col d-md-flex">
    <div class="col col-md-5 themePicture" :style="{'background-image': 'url('+theme.picture_thumb+')'}">
    </div>
    <div class="col col-md-7 gameInfo">
      <div class="gameTitle" v-html="theme.title"></div>
      <div class="gamePlace mb-4">
        В подготовке. <br>
        Ожидается в ближайшее время.
      </div>
      <my-button
         @click="clickLike"
         type="button"
         with-margin
         :icon="theme.isLiked? require('@/assets/media/heart_active.png'):require('@/assets/media/heart.svg')">
         Я бы сыграл
      </my-button>
      <my-button
           v-if="theme.trial_game_package"
           @click="$refs.promoGame.open()"
           cls="hollow"
           class="mt-3"
           type="button"
           style="color: #ffffff"
           :icon="require('../../assets/media/Timer.svg')"
           with-margin
       >Опробовать</my-button>
    </div>
    <my-modal auto-width v-if="theme.trial_game_package" ref="promoGame">
      <promo-game :theme="theme"></promo-game>
    </my-modal>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import MyButton from "../UI/MyButton";
import MyModal from "@/components/UI/MyModal.vue";
import PromoGame from "@/components/main/PromoGame.vue";

export default {
  name: "ThemeCard",
  components: {PromoGame, MyModal, MyButton},
  props: ['theme'],
  computed: {

  },
  methods: {
    ...mapActions([
      'SHOW_AUTH_MODAL',
    ]),
    clickLike(event){
      let target = event.target
      let vm = this
      if(this.$store.getters.isAuthenticated) {
        vm.likeRequest(vm.theme.id, !vm.theme.isLiked, target);
      } else {
        this.$emit('show-auth-modal');
        this.$store.commit('SET_TARGET_CLICK_ELEMENT_ON_STATE', target);
      }
    },
    likeRequest: function (gameId, status, likeEl) {
      let vm = this;
      this.$api.setLike({
            theme_id: gameId,
            status: status,
          })
          .then(function (response) {
            console.log(response)
            likeEl.classList.toggle('heart-active');
            vm.$emit('set-like', vm.theme.id, !vm.theme.isLiked)
            console.log(likeEl)
            // if (target.tagName === 'BUTTON') {
            //           //   target.previousElementSibling.previousElementSibling.children[0].classList.toggle('heart-active')
            //           // } else {
            //           //   target.classList.toggle('heart-active');
            //           // }
          })

    },

    // clickLike: function (event){
    //   let target = event.currentTarget
    //   let vm = this
    //   if(this.$store.state.user.authToken) {
    //     axios.post(
    //         '/api/user/like',
    //         {
    //           theme_id: vm.theme.id,
    //           status: !vm.theme.isLiked,
    //         },
    //         {headers: {
    //             'Authorization': `Token ${vm.$store.state.user.authToken}`
    //           }
    //         })
    //         .then(function (response) {
    //           console.log(response)
    //           vm.$emit('set-like', vm.theme.id, !vm.theme.isLiked)
    //           // if (target.tagName === 'BUTTON') {
    //           //   target.previousElementSibling.previousElementSibling.children[0].classList.toggle('heart-active')
    //           // } else {
    //           //   target.classList.toggle('heart-active');
    //           // }
    //         })
    //         .catch(function (error) {
    //           console.log(error);
    //           vm.$toast.error(error);
    //         });
    //   } else {
    //     this.SHOW_AUTH_MODAL(true)
    //     this.$store.commit('SET_TARGET_CLICK_ELEMENT', target);
    //   }
    // },
  },
}
</script>

<style scoped>

</style>
