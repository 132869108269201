<template>
<div v-if="step==='AUTH'" class="window formWrap" >
  <div class="d-flex flex-column blockWrapper">
    <h4 class="formSubtitle">Авторизация</h4>
    <p class="info-note ">Вам будет отправлен код для подтверждения вашего номера</p>
  </div>
  <div class="contacts contacts__modal">
    <div class="contact__inputs">
      <MyInput
         label="Телефон"
         ref="phoneInput"
         phone-input
         data-tel-input
         autocomplete="tel"
         type="tel"
         maxlength="18"
         v-model="userPhone"
         v-on:keyup.enter="sendPhone"
      ></MyInput>
    </div>
    <div v-if="errorPhoneModal" class="invalid-feedback">ЧТО-ТО НЕ ТАК!</div>
  </div>
  <my-button type="button" @click="sendPhone" class="w-100" id="submit_phone_and_name_btn">
    <span>
      ОТПРАВИТЬ КОД
    </span>
    <span v-if="pending" class="spinner-grow spinner-grow-sm ms-3" role="status" aria-hidden="true"></span>
  </my-button>
</div>

<div v-if="step==='CONFIRM'" class="window formWrap" >
  <div class="d-flex flex-column blockWrapper">
    <h4 class="formSubtitle">Авторизация</h4>
    <p v-if="codeSendBy==='whatsapp'" class="info-note ">Введите 4-х значный код, отправленный вам в WhatsApp на номер <strong>{{userPhone}}</strong>, для подтверждения.</p>
    <p v-else-if="codeSendBy==='sms_aero'" class="info-note ">Введите 4-х значный код, отправленный вам в SMS на номер <strong>{{userPhone}}</strong>, для подтверждения.</p>
    <p v-else-if="codeSendBy==='flash_call'" class="info-note ">Авторизация через вызов. На номер <strong>{{userPhone}}</strong> поступит звонок. Введите последние 4-ре цифры номера входящего звонка.</p>
    <p v-else class="info-note ">Введите 4-х значный код, отправленный вам на номер <strong>{{userPhone}}</strong>, для подтверждения.</p>
    <p v-if="errorCodeModal" class="info-note mb-0 mt-3 py-2 alert alert-danger">{{errorCodeModal}}</p>
  </div>
  <div class="position-relative">
  <MyInput label="Код подтверждения"
           ref="inputCode"
           phone-input
           type="text"
           v-model="validateCode"
           v-on:keyup.enter="sendValidateCode"
           maxlength="4"
           class="nameModal"
           id="validCodeModal"
           autocomplete="off"
  ></MyInput>
  <div class="loaderInInput">
    <span v-if="pending" class="spinner-grow spinner-grow-sm ms-3" role="status" aria-hidden="true"></span>
  </div>
  </div>

  <my-button :cls="!canResendCode?'hollow':''" @click="sendPhone" class="w-100">
    <span>ОТПРАВИТЬ СНОВА</span>
    <span v-if="resendTimer" class="ms-3">{{resendTimer}}</span>
  </my-button>
</div>

<div v-if="step==='USERNAME'" class="window formWrap">
    <h4 class="formTitle">Авторизация</h4>
      <div class="d-flex flex-column">
        <p class="formSubtitle">Мы еще не знакомы.<br> Напишите ваше имя.</p>
      </div>
      <div class="position-relative">

      <MyInput label="Имя"
               ref="inputName"
               name-input
               type="text"
               maxlength="18"
               v-model="userName"
               v-on:keyup.enter="setUserName"
               class="nameModal mb-4" id="nameModal" placeholder="Имя" autocomplete="off"
      ></MyInput>
      <div class="loaderInInput">
        <span v-if="pending" class="spinner-grow spinner-grow-sm ms-3" role="status" aria-hidden="true"></span>
      </div>
      </div>
    <div class="modal-footer set-name-footer">
      <my-button @click="setUserName" type="button" class="btn btn-primary" id="submit_name__btn">
          <span>
              Сохранить
          </span>
      </my-button>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import MyInput from "@/components/UI/MyInput";

const TIMEOUT = 5

export default {
  name: "Login",
  components: {MyInput},
  data: function () {
    return {
      step: 'AUTH',
      userPhone: '',
      validateCode: '',
      userName: '',
      errorPhoneModal: '',
      errorCodeModal: '',
      resolvePromise: undefined,
      rejectPromise: undefined,
      pending: false,
      canResendCode: false,
      lastResendTimer: 0,
      resendTimer: TIMEOUT,
      resendTimerInterval: null,
      codeSendBy: null
    }
  },
  watch: {
    validateCode() {
      if (`${this.validateCode}`.length>3) this.sendValidateCode();
    },
    step(step){
      if (step==='CONFIRM'){
        this.startTimer()
      }
    }
  },
  mounted() {
    this.auth()
  },
  methods: {
    ...mapActions([
      'SET_USER_TOKEN',
      'SET_USER_FIELDS',
      'GET_USER_DETAILS',
      'SHOW_AUTH_MODAL'
    ]),
    startTimer(){
      if (this.resendTimerInterval) clearInterval(this.resendTimerInterval)
      this.canResendCode = false
      this.lastResendTimer += TIMEOUT
      this.resendTimer = this.lastResendTimer
      this.resendTimerInterval = setInterval(()=>{
        if (this.resendTimer>0){
          this.resendTimer-=1
        } else {
          this.canResendCode=true
          clearInterval(this.resendTimerInterval)
        }
      }, 1000)
    },
    auth() {
      this.step = 'AUTH';
      this.$nextTick(()=> {
        this.$refs.phoneInput.focus();
        this.glb.phoneInputFormatter();
      });
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    sendPhone: function () {
      let vm = this;
      if (vm.pending || (vm.step==='CONFIRM' && !vm.canResendCode)) return
      vm.pending = true
      vm.$logApi.log({
        logs:{
          event: 'user login',
          phone: vm.userPhone,
          timestamp: new Date().toISOString(),
        }
      })
      vm.$api.getOrCreateUser(vm.userPhone, '')
        .then((res)=>{
            vm.codeSendBy = res.send_by
            vm.step = 'CONFIRM'
            vm.$nextTick(()=>vm.$refs.inputCode.focus())
        })
        .catch(function (error) {
          if (error.response.data){
            let errorMessage = ''
              for (let key in error.response.data) {
                errorMessage += `${key}: ${error.response.data[key]}\n`
              }
              vm.$toast.error(errorMessage)
          } else {
            vm.$toast.error(error, {duration: 3500})
          }
          console.log(error)
        })
        .finally(()=>{
          vm.pending = false
          vm.startTimer()
        })
    },
    sendValidateCode: function () {
      let vm = this;
      if (vm.pending) return
      vm.pending = true
      vm.$api.validateAuthCode(vm.userPhone, vm.validateCode)
      .then(data=>{
            vm.SET_USER_TOKEN(data.token);
            if (data.first_name.length < 2){
              vm.step = 'USERNAME'
              vm.$nextTick(()=>vm.$refs.inputName.focus())
            } else {
              vm.finishAuth(data)
            }
          })
          .catch(function (error) {
            let response = error.response
            if (response.data &&
                response.data.detail &&
                response.data.detail.toLowerCase().includes('invalid')){
                vm.errorCodeModal = 'Неправильный код!'
                return vm.$toast.error('Неправильный код')
            }
            vm.$toast.error('Что-то пошло не так...');
          }).finally(()=>vm.pending = false)
    },
    setUserName: function () {
      let vm = this;
      if (vm.pending) return
      vm.pending = true
      vm.$api.updateUserPut({'first_name': vm.userName})
        .then(function (response) {
          vm.finishAuth(response.data)
        })
        .catch(function (error){
          vm.$toast.error(error);
        }).finally(()=>vm.pending = false)
    },
    async finishAuth(data) {
      this.SET_USER_FIELDS(data);
      await this.GET_USER_DETAILS();
      this.step = ''
      this.validateCode = ''
      this.userPhone = ''
      this.userName = ''
      if (this.$store.state.targetClick) {
        console.log('TARGET CLICK >> ', this.$store.state.targetClick);
        try{
          this.$store.state.targetClick.click();
        } finally {
          this.$store.dispatch('SET_TARGET_CLICK_ELEMENT', null)
        }
      }
      if (this.$route.query.from) {
        console.log('REDIRECT TO', this.$route.query.from);
        this.$router.push(this.$route.query.from)
      } else if (this.$route.name === 'login'){
        this.$router.push('/')
      }
      this.resolvePromise()
    },

  },
  beforeUnmount() {
    if (this.rejectPromise) this.rejectPromise()
    if (this.resendTimerInterval) clearInterval(this.resendTimerInterval)
  }
}
</script>

<style scoped>
.modal-footer {
  border: none;
}
.window {
  max-width: 340px;
  margin: 0 auto;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.loaderInInput{
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(50%, -50%);
  margin-right: 3rem;
}
</style>