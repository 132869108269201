<template>
<div class="container">
  <div class="row">
    <div class="col d-sm-flex justify-content-between align-items-center" style="position: relative">
      <h2 class="">Результаты игр</h2>
        <my-button
            cls='hollow'
            with-margin
            :icon="require('@/assets/media/arrow_down.svg')"
            @click="showMonthPicker()">
            {{ selectedDate }}
<!--          <i class="bi bi-caret-down-fill"></i>-->
        </my-button>
      <div class="col month-picker-input-container">
        <month-picker
            v-show="monthPickerVisible"
            :default-year="defaultYear"
            lang="ru"
            :months="months"
            :default-month="date.getMonth()+1"
            :show-year="showYear"
            :clearable="clearable"
            :variant="variant"
            :editable-year="editableYear"
            :max-date="maxDate"
            :min-date="minDate"
            :year-only="yearOnly"
            :range="range"
            @input="populateInput"
            @change="updateDate"/>
      </div>
    </div>
  </div>
  <div class="result-container mt-3">
    <transition
        name="custom-classes-transition"
        enter-active-class="animated fadeInLeft"
        leave-active-class="animated fadeOutRight">
    <pre-loader v-if="isLoading"></pre-loader>
    <div v-else-if="!isLoading && events.length === 0" class="h4 mt-5">Нет результатов</div>
    <div v-else>
      <div class="text-center"
       v-for="(event, index) in events"
       :key="index">
        <game-result-table
          :event-data="event"
        ></game-result-table>
      </div>
    </div>
  </transition>
  </div>
</div>
</template>

<script>
import PreLoader from "../components/UI/PreLoader";
import GameResultTable from "../components/main/GameResultTable";
import monthPicker from "vue-month-picker/src/month-picker";
import {MonthPicker} from "vue-month-picker";
import MyButton from "../components/UI/MyButton";


export default {
  name: "GameResults",
  components: {MyButton, PreLoader, GameResultTable, MonthPicker},
  data() {
    return {
      events:[],
      filterMonth: null,
      date: new Date(),
      isLoading: false,
      selectedDate: '',
      monthPickerVisible: false,
    }
  },
  mixins: [monthPicker],
  methods: {
    loadData: function () {
      const vm = this;
      this.axios.get(`/api/events_scores/`)
          .then(function (response) {
            console.log(response.data);
            vm.events = response.data.events.filter(event => event.result.length>0)
          })
          .catch(function (error) {
            console.log(error)
            vm.$toast.error(error)
          });
    },
    filterResults(date) {
      console.log(date)
      const vm = this;
      vm.isLoading = true;
      this.axios.get(`/api/events_scores/?month=${date.year}-${date.monthIndex}`)
          .then(function (response) {
            console.log(response.data);
            vm.events = response.data.events.filter(event => event.result.length>0)
            vm.isLoading = false;
          })
          .catch(function (error) {
            console.log(error)
            vm.$toast.error(error)
          });
    },
    populateInput(date) {
      if (this.range) {
        this.selectedDate = `${date.rangeFromMonth} - ${date.rangeToMonth}, ${date.year}`;
      } else {
        this.selectedDate = `${date.month}, ${date.year}`;
      }
      this.monthPickerVisible = false;
    },
    showMonthPicker() {
      this.monthPickerVisible = !this.monthPickerVisible;
    },
    hide() {
      console.log('HIDE')
      this.monthPickerVisible = false;
    },
    updateDate(date){
      if (this.range) {
        this.selectedDate = `${date.rangeFromMonth} - ${date.rangeToMonth}, ${date.year}`;
      } else {
        this.selectedDate = `${date.month}, ${date.year}`;
      }
      this.filterResults(date)
    }
  },
}
</script>

<style>
.month-picker__container {
    width: 300px;
  box-shadow: none;
}
.result-container{
  position: relative;
}
.table>:not(caption)>*>*:last-child {
  padding: 0.5rem 0.75rem;
}
.month-picker__year {
  margin-bottom: 0!important;
}
.month-picker-input-container {
  position: absolute;
  top: 56px;
  z-index: 2;
  width: 100%;
}
.month-picker__month.selected, .month-picker__month.selected-range-first, .month-picker__month.selected-range-second {
    box-shadow: none;
}
.month-picker__year button:first-child {
    background: url("../assets/media/long_right.svg");
  background-repeat: no-repeat;
  background-size: contain;
  border: 2px solid #E32289;
  transform: rotate(180deg);
}
.month-picker__year button:last-child {
    background: url("../assets/media/long_right.svg");
  background-repeat: no-repeat;
  background-size: contain;
  border: 2px solid #E32289;
}
.month-picker__year button {
  color: transparent!important;
  border-radius: 50px!important;
  top: 14px!important;
  height: 3rem!important;
  width: 3rem!important;
}
.month-picker__year button:hover {
    background-color: transparent;
  border: 2px solid #fff;
}
.month-picker__year {
    padding: 0.5rem!important;
}
.month-picker__month {
  border: 1px solid transparent;
}
h2 {
  margin-bottom: 32px!important;
}
/*@media screen and (max-width: 576px){*/
/*  .table th, .table td {*/
/*    font-size: 12px;*/
/*  }*/

/*}*/
/* sm-устройства (больше или равно 576px) */
@media (min-width: 576px) {
  /* CSS для: 576px <= ширины <= 767px */
  /*.month-picker-input-container {*/
  /*  right: 0;*/
  /*  top: 60px;*/
  /*  width: 70%;*/
  /*}*/
  h2 {
    margin-bottom: 0!important;
  }
  .table>:not(caption)>*>* {
    padding: 0.5rem 0.15rem;
  }
  .month-picker__container {
    width: 100%!important;
}
}
/* md-устройства (больше или равно 768px) */
@media (min-width: 768px) {
  /* CSS для: 768px <= ширины <= 991px */
  .container {
    max-width: 860px;
  }

}

/* lg-устройства (больше или равно 992px) */
@media (min-width: 992px) {
  /* CSS для: 992px <= ширины <= 1119px */
}
</style>
