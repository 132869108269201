<template>
  <div class="d-flex justify-content-between searchWrap align-items-center" >
    <input class="search" type="text" :placeholder=placeholder v-model="searchQuery" @input="updateInput">
    <img class="searchIcon" src="../../assets/media/Search.svg" />
  </div>
</template>

<script>
export default {
  name: "Search",
  props: {
    placeholder: {
      type: String,
      default: "",
      required: false
    },
    searchArray: null,
    arrayField: null,
  },
  data() {
    return {
      searchQuery: null
    }
  },
  methods: {
    resultSearchQuery() {
      if (this.searchQuery!=null){
        return this.searchArray.filter((arrayElement)=>{
              return this.searchQuery.toLowerCase().split(' ').every(v => arrayElement[this.arrayField].toLowerCase().includes(v))
            }
        )
      }else {
        return this.searchArray;
      }
    },
    updateInput(event) {
      this.$emit('update:modelValue', event.target.value)
    },
  },
}
</script>

<style scoped>
.search{
  font-family: Montserrat;
  font-style: normal;
  background: transparent;
  border: none;
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 143%;
  color: #FFFFFF;
  opacity: 0.6;
}
input:active, :hover, :focus {
  outline: 0;
  outline-offset: 0;
}
input::placeholder,
input::-webkit-input-placeholder {
  color: #fff;
}
.searchWrap {
  border-radius: 1.25rem;
  align-items: center;
  width: 100%;
  max-width: 21rem;
  color: #fff;
  padding: 12px 20px 12px 12px;
  border: 2px solid #E32289;
  margin-bottom: 1rem;
}
.searchIcon {

}
</style>