<template>
  <div class="preorder container">
    <preorder-nav
       v-if="event && isInvited"
       :event="event"
       :eventTeam="eventTeam"
       :cart="cart"
       :isRegisteredOnEvent="isRegisteredOnEvent"
       :isRegisteredOnEventTeam="isRegisteredOnEventTeam"
    ></preorder-nav>
    <catalog
        v-if="event && event.place && isInvited"
        :event="event"
        :event-team="eventTeam"
        :cart="cart"
    ></catalog>
    <my-modal ref="regOnEvent" v-if="$store.state.authToken">
      <reg-on-event
          show-event-detail
          @close="$refs.regOnEvent.close()"
          :event-id="event.id"
      ></reg-on-event>
    </my-modal>
    <my-modal ref="notificationModal">
      <p class="mb-3" v-html="notification.message"></p>
      <my-button v-if="notification.btnAction" @click="notification.btnAction" class="w-100">{{notification.btnTitle}}</my-button>
    </my-modal>
    <auth-modal ref="auth"></auth-modal>
    <anonymous-auth-modal
        ref="anonymousAuth"
        :token="this.$route.query.t"
    ></anonymous-auth-modal>
  </div>
</template>

<script>
import Catalog from "./Catalog";
import PreorderNav from "./PreorderNav";
import MyModal from "../UI/MyModal";
import mapGetters from "vuex/dist/vuex.mjs";
import RegOnEvent from '../main/RegOnEvent';
import AuthModal from '../AuthModal';
import AnonymousAuthModal from '../AnonymousAuthModal';
import MyButton from "@/components/UI/MyButton.vue";
import {getUserEventTeamFromEvent, userIsRegisteredOnEvent, userIsRegisteredOnEventTeam} from "@/utils/event";

export default {
  name: "Preorder",
  components: { MyButton, MyModal, PreorderNav, Catalog, RegOnEvent, AuthModal, AnonymousAuthModal },
  data() {
    return {
      catalog: [],
      eventTeam: null,
      anotherEventTeam: null,
      event: null,
      isInvited: false,
      notification:{
        message: '',
        btnTitle: 'Ok',
        btnAction: ()=>{}
      },
      isPreorderOpened: true,
      isRegisteredOnEvent: false,
      isRegisteredOnEventTeam: false,
      cart: null,
      selectedCategory: null,
      showCart: false,
    }
  },
  async mounted() {
    document.getElementById('footer').style.display = 'none'
    this.emitter.on('load-cart', () => {
      this.loadCart();
    })
    this.emitter.on('show-auth-modal', () => {
      this.$refs.auth.showAuthModal();
    })
    await this.loadData()
    const isPreorderOpened = this.checkIsPreoderOpened()
    if (!isPreorderOpened) return
    this.isInvited = (await this.$api.hasPhoneOrInvited(this.event.id)).status
    if (!this.$store.state.user.authToken) {
      try{
       // await this.$refs.auth.showAuthModal()
       this.$refs.auth.showAuthModal()
      } catch(err){
        console.error(err)
        return
      }
       await this.loadData()
       this.init()
    } else if (this.$store.state.user.authToken) {
      this.init()
    }
  },
  watch:{
    "$route.params.teamId"(current, previous){
      if(current !== previous){
        this.loadData()
            .then(()=>this.init())
      }
    },
    "$store.state.user.authToken"(current, previous){
      if(current !== previous){
        this.loadData()
            .then(()=>this.init())
      }
    }
  },
  beforeUnmount() {
    document.getElementById('footer').style.display = 'block'
  },
  methods: {
    ...mapGetters['isAuthenticated'],
    init(){
      this.generateAnonymousPreorderToken()
      this.checkIsUserRegistrationOrRegisterUser()
      this.checkIsUserRegistrationOnAnotherTeam()
      this.loadCart()
    },
    loadData(){
      const vm = this
      return new Promise((resolve, reject) => {
        let promise = this.$store.state.user.authToken ?
        this.$api.getEventDetails(this.$route.params.eventId)
        :this.$api.getEventDetailsWithoutToken(this.$route.params.eventId)
        promise
          .then(data=>{
            this.event = data
            this.eventTeam = data.event_teams.filter(et=>{
              return (vm.$store.state.user.authToken ? et.team.id : et.team) == vm.$route.params.teamId
            })[0]
            if (vm.$store.state.user.authToken){
              this.setIsRegisteredStatuses()
            }
            resolve()
          })
          .catch(err=>reject(err))
      })
    },
    loadCart() {
      this.$api.getEventTeamPlayerCart(this.eventTeam.id)
          .then(data=>this.cart=data)
          .catch(()=>{});
    },
    async generateAnonymousPreorderToken() {
      if (this.isRegisteredOnEvent && !this.$route.query.t) {
          const res = await this.$api.generateAnonymousPreorderToken(this.eventTeam.id)
          this.$router.push({query: {t: res.t}})
      }
    },
    async checkIsUserRegistrationOrRegisterUser() {
      if (!this.isRegisteredOnEvent && !this.$route.query.t) {
         this.notification.message = `<p>Вы не зарегистрированы на игру
         <strong>${this.event.game.theme.title} ${this.event.postfix}.</strong>
         Если добавите продукт в корзину, то регистрация в команде <strong>${this.eventTeam.title}</strong>
         будет автоматической.</p><p>Если вы хотите сделать предзаказ без ввода номера телефона, то
         попросите другого члена команды поделиться с вами их ссылкой на эту страницу.</p>`
         this.notification.btnAction = ()=>this.$refs.notificationModal.close()
         this.notification.btnTitle = "Ок"
         this.$refs.auth.close();
         this.$refs.notificationModal.open()
      } else {
          if (!this.$store.getters.isAuthenticated) {
             this.$refs.auth.close();
             this.$refs.anonymousAuth.open();
          } else if (!this.isInvited) {
              try {
                 await this.$api.sendAnonymousPreorderToken(this.event.id, this.$route.query.t, {})
                 this.isInvited = true
              } catch(e) {
                 this.notification.message = `<p>Для того, чтобы сделать предзаказ
                 без ввода телефона - перейдите по ссылке от другого члена команды.</p>
                 <p>Либо вы можете ввести номер телефона в личном кабинете и получить
                 полный доступ к предзаказу и участию в рандомайзере на игре</p>`
                 this.$refs.notificationModal.close = () => { this.$router.push({name: 'cabinet'}) }
                 this.notification.btnAction = ()=>this.$refs.notificationModal.close()
                 this.notification.btnTitle = "В кабинет"
                 this.$refs.notificationModal.open()
              }
          }
      }
    },
    checkIsUserRegistrationOnAnotherTeam(){
      if (this.isRegisteredOnEvent && !this.isRegisteredOnEventTeam){
         this.anotherEventTeam = getUserEventTeamFromEvent(this.event)
         this.notification.message = `
         Вы уже зарегистрированы на игру
         <strong>${this.event.game.theme.title} ${this.event.postfix}$</strong>
         в команде – <strong>${this.anotherEventTeam.title}</strong>`
         this.notification.btnAction = this.goToAnotherTeamPreorder
         this.notification.btnTitle = 'Перейти к предзаказу'
         this.$refs.notificationModal.open()
       }
    },
    checkIsPreoderOpened(){
      console.log('this.event.is_preorder_opened', this.event.is_preorder_opened)
      if (!this.event.is_preorder_opened){
         this.anotherEventTeam = getUserEventTeamFromEvent(this.event)
         this.notification.message = `
         <strong>Предзаказ не доступен.</strong><br>
         Предзаказ будет доступен в день игры...⏳`
         this.notification.btnAction = ()=>this.$router.push({ name: 'RegTeamOnEvent', params: this.$route.params })
         this.notification.btnTitle = 'Перейти к регистрации'
         this.$refs.notificationModal.open()
         return false
       }
      return true
    },
    setIsRegisteredStatuses(){
      this.isRegisteredOnEvent = userIsRegisteredOnEvent(this.event)
      this.isRegisteredOnEventTeam = userIsRegisteredOnEventTeam(this.eventTeam)
    },
    selectCategory(categoryId) {
      this.selectedCategory = categoryId;
    },
    goToAnotherTeamPreorder(){
      console.log({eventId: this.event.id, teamId: this.anotherEventTeam.team.id})
      this.$router.push({name: 'Preorder', params:{eventId: this.$route.params.eventId, teamId: this.anotherEventTeam.team.id}})
      this.$refs.notificationModal.close()
    },
    closeCart(){
      console.log('PREORDER close cart')
      this.$refs.cart.close()
    },
    anyEvent(){
      console.log('ANY EVENT')
    }
  }
}
</script>

<style scoped>

.category-list-item-img:hover{
  cursor: pointer;
}
.catalog_path a {
  font-weight: bold;
  color: #6b6363;
  margin: 0 10px;
}
.catalog_path a:hover {
  color: #ffffff;
}
footer {
  display: none !important;
}
</style>
