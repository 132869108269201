<template>
<div class="container container-sm pt-3">
  <pre-loader v-if="!placePreorders.length"/>
  <div v-else>
    <div v-for="eventPreorder in placePreorders" :key="eventPreorder.event.id" class="mb-5">
      <div v-if="eventPreorder">
        <div class="fs-1 fw-bold">Предзаказ</div>
        <div>
          <h3 v-html="eventPreorder.event.game.theme.title +' '+ eventPreorder.event.postfix "></h3>
          <div class="eventPlace mb-2" v-if="eventPreorder.event.place">
            <place-detail with-address :place="eventPreorder.event.place"/>
          </div>
          <div class="eventDate">
            <img class="icon" src="../../assets/media/calendar_white.svg" />
            {{glb.getDate(eventPreorder.event.date)}}
            <img class="icon" src="../../assets/media/alarm_white.svg" />
            {{glb.getTime(eventPreorder.event.date)}}
          </div>
        </div>

        <div class="">
          <div v-for="et in eventPreorder.preorder.filter(et=>et.carts.length && et.totalPrice > 0)" :key="et.title">
            <div class="mt-4"><span class="fs-6">Команда:</span> <span class="fs-5 fw-bold"> {{ et.title }} </span></div>
            <div class="ms-3 mt-3" v-for="cart in et.carts.filter(cart=>cart.totalPrice>0)" :key="cart.user.phone">
              <div class="d-flex justify-content-between">
                <div>{{ cart.user.first_name }} ({{ cart.user.phone }})</div>
                <div> всего: <span class="fw-bold">{{cart.totalPrice}}₽</span></div>
              </div>
              <div class="ms-3 d-flex justify-content-between mt-3" v-for="item in cart.order" :key="cart.user.phone + item.product.title">
                <div><span class="fw-bold">{{item.product.category}}. {{item.product.title}}</span> <span class="fst-italic">{{item.quantity}} шт. </span></div>
                <div><span class="fst-italic ms-3">{{ item.product.price*item.quantity }}₽</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import PreLoader from "@/App.vue";
import PlaceDetail from "@/components/main/PlaceDetail.vue";

export default {
  name: "SecretProrderPage",
  components: {PlaceDetail, PreLoader},
  data:()=>{
    return{
      placePreorders: [],
      // eventPreorder: null
    }
  },
  mounted() {
    this.load()
  },
  methods:{
    processPreorderData(data){
      data.map(event=>{
        return event.preorder = event.preorder.map(et=>{
          et.carts = et.carts.map(cart=>{
            cart.totalPrice = cart.order.reduce((total, item)=>{return item.product.price*item.quantity+total} , 0)
            return cart
          })
          et.totalPrice = et.carts.reduce((total, et)=> et.totalPrice+total, 0)
          return et
        })
      })

      this.placePreorders=data
    },
    load(){
      this.$api.getEventPreorderBySecretSlug(this.$route.params.secret, this.$route.query.date)
          .then(data=>{
            this.processPreorderData(data)
          })
    },
  }
}
</script>

<style scoped>

</style>
