<template>
<transition name="fade">
   <div @click.self="close" v-if="show" class="popup-modal d-flex justify-content-center">
       <div class="window formWrap">
        <h4 class="formTitle">Авторизация</h4>
          <div class="d-flex flex-column">
            <p class="formSubtitle">Мы еще не знакомы.<br> Напишите ваше имя.</p>
          </div>
          <div class="position-relative">

          <MyInput label="Имя"
                   ref="inputName"
                   name-input
                   type="text"
                   maxlength="18"
                   v-model="userName"
                   v-on:keyup.enter="setUserName"
                   class="nameModal mb-4" id="nameModal" placeholder="Имя" autocomplete="off"
          ></MyInput>
          <div class="loaderInInput">
            <span v-if="pending" class="spinner-grow spinner-grow-sm ms-3" role="status" aria-hidden="true"></span>
          </div>
          </div>
        <div class="modal-footer set-name-footer">
          <my-button @click="setUserName" type="button" class="btn btn-primary" id="submit_name__btn">
              <span>
                  Сохранить
              </span>
          </my-button>
        </div>
       </div>
    </div>
</transition>
</template>

<script>
import {mapActions} from "vuex";
import MyInput from "@/components/UI/MyInput";

export default {
  name: "AnonymousAuthModal",
  components: {MyInput},
  props: ['token'],
  data: function () {
    return {
      show: false,
      userName: '',
      pending: false,
    }
  },
  watch: {
    validateCode() {
      if (`${this.validateCode}`.length>3) this.sendValidateCode();
    },
  },
  methods: {
    ...mapActions([
      'SET_USER_TOKEN',
      'SET_USER_FIELDS',
      'GET_USER_DETAILS',
    ]),
    open: function() {
      this.show = true
    },
    close: function () {
      this.show = false
    },
    setUserName: async function () {
      if (this.pending) return;
      this.pending = true;
      const res = await this.$api.sendAnonymousPreorderToken(
        this.$route.params.eventId,
        this.token,
        {first_name: this.userName},
      )
      this.SET_USER_TOKEN(res.authToken)
      this.SET_USER_FIELDS(res.user)
      await this.GET_USER_DETAILS();
      window.location.reload()
    },
  },
}
</script>

<style scoped>

.popup-modal {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.5rem;
  align-items: center;
  z-index: 1100;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-footer {
  border: none;
}
.window {
  max-width: 340px;
  margin: 0 auto;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.loaderInInput{
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(50%, -50%);
  margin-right: 3rem;
}

</style>
