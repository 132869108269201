<template>
  <pre-loader v-if="loading"/>
  <div v-else class="container">
    <div v-show="isActual" class=" mb-4">
      <div class="row">
        <div v-if="event" class="col-12">
            <div class="fs-4 mb-1">Регистрация на игру:</div>
            <div class="fs-3 fw-bold mt-0 mb-1" v-html="event.game.theme.title +' '+ event.postfix "></div>
            <div v-if="event.game.theme" class="d-md-flex">
              <div class="gamePictureWrap position-relative">
                <div class="gamePicture position-absolute top-0 bottom-0 left-50" :style="{backgroundImage: `url(${event.game.theme.picture})`, zIndex: 2}"></div>
                <div
                    class="gamePictureBckg"
                    :style="{backgroundImage: `url(${event.game.theme.picture})`}"
                ></div>
              </div>
              <div>
                <div class="d-flex d-md-block justify-content-between w-100 mt-0 mb-3">
                  <div>
                    <div class="eventPlace mt-1 mb-2" v-if="event.place">
                        <place-detail :place="event.place"/>
                      </div>
                      <div class="eventDate ">
                      <img class="icon" src="../../assets/media/calendar_white.svg" />
                      {{glb.getDate(event.date)}}
                      <img class="icon" src="../../assets/media/alarm_white.svg" />
                      {{glb.getTime(event.date)}}
                    </div>
                  </div>
                <div v-if="userEventTeamId">
                  <my-button
                      class="mt-2"
                      @click="openPreorder">
                    Сделать заказ 🍴
                  </my-button>
                </div>
                </div>

              </div>
            </div>
          </div>
      </div>
      <event-team-manage
          v-for="(event_team, index) in event_teams"
          :event="event"
          :key="event_team.id"
          v-bind:event_team="event_team"
          v-bind:index="index"
          v-bind:is-captain="isCaptain"
          @delete-player="deletePlayer"
          @add-new-player="addNewPlayer"
          @delete-event-team="deleteEventTeam"
          @add-event-team="addEventTeam"
          @refresh-lists="loadData">
      </event-team-manage>
    </div>
    <div v-if="!isActual" class="text-center">
      <div class="h2 mt-5">Игра уже прошла.</div>
    </div>
    <my-modal ref="preorder">
      <div class="fs-1 text-center my-4"><i class="bi bi-clock-history me-3"></i> Уже скоро!</div>
      <div class="text-center my-4">Организатор ещё не открыл предзаказ.</div>
    </my-modal>
    <PlayerRegisterInfoModal ref="registerModal"/>
  </div>
</template>

<script>

import EventTeamManage from "./EventTeamManage";
import {mapActions} from "vuex";
import PreLoader from "../UI/PreLoader";
import MyModal from "../UI/MyModal";
import PlaceDetail from "@/components/main/PlaceDetail.vue";
import MyButton from "@/components/UI/MyButton.vue";
import PlayerRegisterInfoModal from "@/components/team/registration/PlayerRegisterInfoModal.vue";
import {handleError} from "@/utils/error";

export default {
  name: "RegTeamOnEvent",
  components: {PlayerRegisterInfoModal, MyButton, PlaceDetail, MyModal, PreLoader, EventTeamManage },
  data() {
    return {
      loading: true,
      intervalLoading: null,
      isCaptain: false,
      event_teams: [],
      newPlayerName: '',
      newPlayerPhone: '',
      showAddPlayer: false,
      showErrorModal: false,
      event: null,
      error: {
        title: '',
        description: '',
        display: 'none',
      },
    }
  },
  mounted() {
    this.intervalLoading = setInterval(()=>this.loadData(), 10000)
  },
  beforeUnmount() {
    if (this.intervalLoading) clearInterval(this.intervalLoading)
  },
  created: function () {
    const vm = this;
    //получение данных о команде и игре
    if (this.$store.state.user.authToken) {
      vm.loadData();
    } else {
      vm.SHOW_AUTH_MODAL(true)
    }
  },
  computed: {
    userEventTeamId(){
      let eventTeams = this.event_teams.filter(et => et.event_players.filter(ep => ep.user.id === this.$store.state.user.id).length>0)
      return eventTeams.length>0 ? eventTeams[0].id : null
    },
    isActual() {
      return (new Date() < new Date(this.event.date))
    },
  },
  methods: {
    ...mapActions(['SHOW_AUTH_MODAL']),
    loadData: function() {
      const vm = this;
      if(!vm.event_teams.length) vm.loading = true
      this.$api.getOrCreateEventTeam(vm.$route.params.eventId, vm.$route.params.teamId)
        .then(function (data) {
            try {
              vm.event = data[0].event
            } catch (err){
              console.log(err)
            }
            vm.processEvenTeams(data)
            if (vm.$store.state.user.captain.indexOf(vm.event_teams[0].team.id) !== -1) {
              vm.isCaptain = true;
            }
        })
          .catch(function (error) {
            console.log(error);
            vm.$toast.error(error);
          })
         .finally(()=>vm.loading = false)
    },
    addNewPlayer: function () {
      this.showAddPlayer = false;
      this.loadData();
    },
    addEventTeam: function () {
      let vm = this;
      vm.$api.createEventTeam(vm.$route.params.eventId, vm.$route.params.teamId)
        .then(function (response) {
          console.log(response)
          vm.loadData();
            // window.scrollBy(0, document.getElementById('main-container').offsetHeight);
        })
    },
    processEvenTeams: function (event_teams=null) {
      let vm = this;
      if (event_teams === null) event_teams=vm.event_teams
      const allreadyRegistered = event_teams[0].event_players.filter(ep => ep.id === vm.$store.state.user.id).length
      const setCheck = vm.$route.query.set_check
      if (setCheck) vm.$router.push({name: 'RegTeamOnEvent', params:vm.$route.params})
      if (!allreadyRegistered && setCheck) {
        vm.$api.registerUserInEventTeam(vm.$store.state.user.id, event_teams.slice(-1)[0].id)
            .then(()=>vm.$refs.registerModal.registered())
            .catch((err)=>handleError(err, 7000))
            .finally(vm.loadData)
      }
      for (let event_team of event_teams) {
        for (let player of event_team.team.players) {
          player.inGame = false;
          player.participantCount = 1;
          player.eventPlayerId = null;
          player.playAnAnotherTeam = false;
        }
        for (let player of event_team.team.players) {
          for (let ep of event_team.event_players) {
            if (player.id === ep.user.id ){
              player.inGame = true;
              player.participantCount = ep.participant_count;
              player.eventPlayerId = ep.id;
            }
          }
          for (let pep of player.event_players) {
            if (pep.event_team.event === event_team.event.id && pep.event_team.id !== event_team.id) {
              player.playAnAnotherTeam = true;
            }
          }
        }
      }
      vm.event_teams = event_teams;
    },
    deletePlayer: function (team_id, player_id) {
      const vm = this;
      vm.$api.deleteEventPlayerByTeamId(team_id, player_id)
          .then(function (response) {
            console.log(response)
            for (let event_team of vm.event_teams) {
              let new_team_players = event_team.team.players.filter(p => p.id !== player_id)
              let new_event_players = event_team.event_players.filter(ep => ep.user !== player_id)
              event_team.team.players = new_team_players
              event_team.event_players = new_event_players
            }
          })
    },

    deleteEventTeam: function (eventTeamId) {
      const vm = this;
      vm.$api.deleteEventTeam(eventTeamId)
        .then(function (response) {
          console.log(response)
          vm.event_teams = vm.event_teams.filter(et => et.id !== eventTeamId);
        })
    },
    openPreorder(){
      if(this.event.place.preorder_url){
        window.open(this.event.place.preorder_url);
      } else if ( this.event.is_preorder_opened ){
        this.$router.push(`/event/${this.$route.params.eventId}/team/${this.$route.params.teamId}/preorder`)
      } else {
        this.$refs.preorder.open()
      }
    },
    raiseError: function (title, description) {
      this.error.title = title;
      this.error.description = description;
      this.error.display = 'block';
      // this.showErrorModal = true;
    },
  },
}
</script>

<style scoped>

/*.gamePicture {*/
/*  width: 100%;*/
/*  border-radius: 8px;*/
/*}*/
.gamePictureWrap {
  width: 100%;
  border-radius: 8px;
  /*height: 150px;*/
  overflow: hidden;
}
.gamePictureBckg, .gamePicture{
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 120px;
}
.gamePictureBckg{
  filter: blur(3px);
}
.gamePicture{
    background-size: contain;
}
.icon {
  margin-bottom: 4px;
}
.eventDate, .eventPlace {
  font-size: 14px;
  line-height: 20px;
  color: #fff;
}
.eventPlace {
  margin-bottom: 8px;
}

@media (max-width: 576px) {

  .freePlace span {
    text-align: left;
  }
}

/* sm-устройства (больше или равно 576px) */
@media (min-width: 577px) {
  /* CSS для: 576px <= ширины <= 767px */

  /* полоса прокрутки (скроллбар) */
  ::-webkit-scrollbar {
    width: 1px; /* ширина для вертикального скролла */
    height: 100%;
    border-radius: 16px;
    background: rgba(255, 255, 255, 0.1);
  }

  /* ползунок скроллбара */
  ::-webkit-scrollbar-thumb {
    width: 3px!important;
    background: #E32289;
    border-radius: 16px;
  }
}

/* md-устройства (больше или равно 768px) */
@media (min-width: 768px) {
  /* CSS для: 768px <= ширины <= 991px */

  .gamePictureWrap {
    height: auto;
    width: 35%;
    margin-bottom: 16px;
    margin-right: 32px;
  }

}

/* lg-устройства (больше или равно 992px) */
@media (min-width: 992px) {
  /* CSS для: 992px <= ширины <= 1119px */
  .gamePictureWrap {
    width: 25%;
  }
}

/* xl-устройства (больше или равно 1200px) */
@media (min-width: 1200px) {
  /* CSS для: ширины >= 1200px */
}
</style>
