<template>
  <pre-loader v-if="loading"/>
  <div v-else>
    <div v-if="!isCompleted && !eventIsNotPassed">
      <div class="container" v-if="step=='step_1' &&  eventInfo">
        <div class="row">
          <div class="reviewsHeader d-flex justify-content-center justify-content-md-center">
            <div class="col-4 reviewsHeaderItem reviewsHeaderItem_step1 align-items-center justify-content-center" @click="step='step_1'">
              <img src="../assets/media/game.svg" alt="">
              <p class="reviewsHeaderItemText">Игра</p>
            </div>
            <div class="col-4 reviewsHeaderItem reviewsHeaderItem_step2 align-items-center justify-content-center" @click="step='step_2'">
              <img src="../assets/media/Cookie.svg" alt="">
              <p class="reviewsHeaderItemText">Ресторан</p>
            </div>
            <div class="col-4 reviewsHeaderItem reviewsHeaderItem_step3 align-items-center justify-content-center" @click="step='step_3'">
              <img src="../assets/media/message-favorite.svg" alt="">
              <p class="reviewsHeaderItemText">О&nbsp;нас</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col text-center text-lg-start">
        <span class="stepNumber">
          ШАГ 1 - Оценка игры
        </span>
            <p class="gameTitle">
              {{eventInfo.game.theme.title}}
            </p>
            <vue-select
                v-if="!eventTeamIsConfirmed"
                v-model="userReview.event_team"
                :options="eventInfo.event_teams"
                label="В какой команде вы играли?"
                :error="!validFormFields.event_team"
            />
            <div class="reviewList">
              <div class="reviewItem d-md-flex align-items-center justify-content-between">
                <div class="reviewTitle">
                  Оцените вопросы
                </div>
                <div class="claim text-center" @click="addClaim()">
                  Претензия к вопросу
                </div>
                <star-rating
                    v-model:rating="userReview.questions_assessment"
                    :show-rating="false"
                    inactive-color="#2C2D73"
                    active-color="#ffffff"
                    :padding="20"
                    text-class="starShadow"
                    :star-size="36"
                    style="justify-content: center; margin-right: -20px;"
                ></star-rating>
              </div>
              <div class="reviewItem d-lg-flex align-items-center justify-content-between">
                <div class="reviewTitle">
                  Организация игры
                </div>
                <star-rating
                    v-model:rating="userReview.event_organization_assessment"
                    :show-rating="false"
                    inactive-color="#2C2D73"
                    active-color="#ffffff"
                    :padding="20"
                    style="justify-content: center; margin-right: -20px;"
                    :star-size="36"></star-rating>
              </div>
              <div class="reviewItem d-lg-flex align-items-center justify-content-between">
                <div class="reviewTitle">
                  Атмосфера игры
                </div>
                <div class="smiles d-flex justify-content-center">
                  <div class="estimationWrap" @click="userReview.event_atmosphere_assessment = 'bad'" :class="{ estimationWrap_active : userReview.event_atmosphere_assessment === 'bad' }">
                    <div class="estimation">
                      <img src="../assets/media/icon_bad.svg" alt="">
                    </div>
                  </div>
                  <div class="estimationWrap" @click="userReview.event_atmosphere_assessment = 'neutral'" :class="{ estimationWrap_active : userReview.event_atmosphere_assessment === 'neutral' }" >
                    <div class="estimation">
                      <img src="../assets/media/icon_norm.svg" alt="">
                    </div>
                  </div>
                  <div class="estimationWrap" @click="userReview.event_atmosphere_assessment = 'good'" :class="{ estimationWrap_active : userReview.event_atmosphere_assessment === 'good' }">
                    <div class="estimation">
                      <img src="../assets/media/icon_good.svg" alt="">
                    </div>
                  </div>
                </div>
              </div>
              <div class="reviewItem d-lg-flex align-items-center justify-content-between">
                <div class="reviewTitle">
                  Наш Ведущий
                </div>
                <div class="smiles d-flex justify-content-center">
                  <div class="estimationWrap" @click="userReview.event_host_assessment = 'so_so'" :class="{ estimationWrap_active : userReview.event_host_assessment === 'so_so' }">
                    <div class="estimation_text">
                      Так себе
                    </div>
                  </div>
                  <div class="estimationWrap" @click="userReview.event_host_assessment = 'normal'" :class="{ estimationWrap_active : userReview.event_host_assessment === 'normal' }">
                    <div class="estimation_text">
                      Норм
                    </div>
                  </div>
                  <div class="estimationWrap" @click="userReview.event_host_assessment = 'handsome'" :class="{ estimationWrap_active : userReview.event_host_assessment === 'handsome' }">
                    <div class="estimation_text">
                      Красавчик
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="reviewBtns d-flex justify-content-end">
              <my-button cls='blue' style="margin-right: 16px" @click="sendReview(true)">Я&nbsp;закончил</my-button>
              <my-button @click="step='step_2'" :icon="require('../assets/media/long_right.svg')" with-margin>Дальше</my-button>
            </div>
          </div>
        </div>
      </div>
      <div class="container" v-if="step=='step_2'">
        <div class="row">
          <div class="reviewsHeader d-flex justify-content-center justify-content-md-center">
            <div class="col-4 reviewsHeaderItem reviewsHeaderItem_step1_disabled align-items-center justify-content-center" @click="step='step_1'">
              <img src="../assets/media/game.svg" alt="">
              <p class="reviewsHeaderItemText">Игра</p>
            </div>
            <div class="col-4 reviewsHeaderItem reviewsHeaderItem_step2_active align-items-center justify-content-center" @click="step='step_2'">
              <img src="../assets/media/Cookie.svg" alt="">
              <p class="reviewsHeaderItemText">Ресторан</p>
            </div>
            <div class="col-4 reviewsHeaderItem reviewsHeaderItem_step3 align-items-center justify-content-center" @click="step='step_3'">
              <img src="../assets/media/message-favorite.svg" alt="">
              <p class="reviewsHeaderItemText">О&nbsp;нас</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col text-center text-lg-start">
        <span class="stepNumber">
          ШАГ 2 - Оценка ресторана
        </span>
            <p class="gameTitle">
              {{ eventInfo.place? eventInfo.place.title:'' }}
            </p>
            <div class="reviewList">
              <div class="reviewItem d-lg-flex align-items-center justify-content-between">
                <div class="reviewTitle">
                  Обслуживание ресторана
                </div>
                <div class="smiles d-flex justify-content-center">
                  <div class="estimationWrap d-none d-lg-block" @click="userReview.restaurant_service_assessment = 'unused'" :class="{ estimationWrap_active : userReview.restaurant_service_assessment === 'unused' }" style="margin-right: 10px">
                    <div class="estimation_text">
                      Не ел
                    </div>
                  </div>
                  <div class="estimationWrap m-0" @click="userReview.restaurant_service_assessment = 'bad'" :class="{ estimationWrap_active : userReview.restaurant_service_assessment === 'bad' }">
                    <div class="estimation">
                      <img src="../assets/media/icon_bad.svg" alt="">
                    </div>
                  </div>
                  <div class="estimationWrap" @click="userReview.restaurant_service_assessment = 'neutral'" :class="{ estimationWrap_active : userReview.restaurant_service_assessment === 'neutral' }">
                    <div class="estimation">
                      <img src="../assets/media/icon_norm.svg" alt="">
                    </div>
                  </div>
                  <div class="estimationWrap" @click="userReview.restaurant_service_assessment = 'good'" :class="{ estimationWrap_active : userReview.restaurant_service_assessment === 'good' }">
                    <div class="estimation">
                      <img src="../assets/media/icon_good.svg" alt="">
                    </div>
                  </div>
                </div>
                <div class="estimationWrap d-lg-none" style="margin: 0 auto;width: auto;max-width: 410px;" @click="userReview.restaurant_service_assessment = 'unused'" :class="{ estimationWrap_active : userReview.restaurant_service_assessment === 'unused' }">
                  <div class="estimation_text">
                    Не ел
                  </div>
                </div>
              </div>
              <div class="reviewItem d-lg-flex align-items-center justify-content-between">
                <div class="reviewTitle">
                  Качество еды и напитков
                </div>
                <div class="smiles d-flex justify-content-center">
                  <div class="estimationWrap d-none d-lg-block" @click="userReview.food_and_drink_quality = 'unused'" :class="{ estimationWrap_active : userReview.food_and_drink_quality === 'unused' }" style="margin-right: 10px">
                    <div class="estimation_text">
                      Не пил
                    </div>
                  </div>
                  <div class="estimationWrap m-0" @click="userReview.food_and_drink_quality = 'bad'" :class="{ estimationWrap_active : userReview.food_and_drink_quality === 'bad' }">
                    <div class="estimation">
                      <img src="../assets/media/icon_bad.svg" alt="">
                    </div>
                  </div>
                  <div class="estimationWrap" @click="userReview.food_and_drink_quality = 'neutral'" :class="{ estimationWrap_active : userReview.food_and_drink_quality === 'neutral' }">
                    <div class="estimation">
                      <img src="../assets/media/icon_norm.svg" alt="">
                    </div>
                  </div>
                  <div class="estimationWrap" @click="userReview.food_and_drink_quality = 'good'" :class="{ estimationWrap_active : userReview.food_and_drink_quality === 'good' }">
                    <div class="estimation">
                      <img src="../assets/media/icon_good.svg" alt="">
                    </div>
                  </div>
                </div>
                <div class="estimationWrap d-lg-none" style="margin: 0 auto;width: auto;max-width: 410px;" @click="userReview.food_and_drink_quality = 'unused'" :class="{ estimationWrap_active : userReview.food_and_drink_quality === 'unused' }">
                  <div class="estimation_text">
                    Не пил
                  </div>
                </div>
              </div>
              <div class="reviewItem" style="height: 100%;position: relative">
                <div class="reviewTitle">
                  Ваш комментарий
                </div>
                <textarea class="" v-model="userReview.restaurant_comment"></textarea>
                <div class="d-flex align-items-center position-relative">
                  <div class="check" @click="this.checked=!checked">
                    <input class="form-check-input form-check-input-lg"
                           :checked=false
                           v-model="userReview.want_restaurant_responce"
                           type="checkbox">
                  </div>
                  <div class="text-start">Я хочу получить реакцию от ресторана</div>
                </div>
              </div>
            </div>
            <div class="reviewBtns d-flex justify-content-end">
              <my-button cls='blue' style="margin-right: 16px" @click="step='step_1'" :icon_left="require('../assets/media/long_right.svg')" with-margin-right>Назад</my-button>
              <my-button @click="step='step_3'" :icon="require('../assets/media/long_right.svg')" with-margin>Дальше</my-button>
            </div>
          </div>
        </div>
      </div>
      <div class="container" v-if="step=='step_3'">
        <div class="row">
          <div class="reviewsHeader d-flex justify-content-center justify-content-md-center">
            <div class="col-4 reviewsHeaderItem reviewsHeaderItem_step1_disabled align-items-center justify-content-center" @click="step='step_1'">
              <img src="../assets/media/game.svg" alt="">
              <p class="reviewsHeaderItemText">Игра</p>
            </div>
            <div class="col-4 reviewsHeaderItem reviewsHeaderItem_step2_disabled align-items-center justify-content-center" @click="step='step_2'">
              <img src="../assets/media/Cookie.svg" alt="">
              <p class="reviewsHeaderItemText">Ресторан</p>
            </div>
            <div class="col-4 reviewsHeaderItem reviewsHeaderItem_step3 reviewsHeaderItem_step3_active align-items-center justify-content-center" @click="step='step_3'">
              <img src="../assets/media/message-favorite.svg" alt="">
              <p class="reviewsHeaderItemText">О&nbsp;нас</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col text-center text-lg-start">
        <span class="stepNumber">
          ШАГ 3 - Пожелания
        </span>
            <p class="gameTitle">
              Расскажите нам о нас
            </p>
            <div class="reviewList">
              <div class="reviewItem d-lg-flex align-items-center justify-content-between">
                <div class="reviewTitle">
                  Вероятность, что порекомендуете pro100 квиз друзьям и коллегам
                </div>
                <div class="smiles d-flex justify-content-center">
                  <div class="estimationWrap" @click="userReview.recommendation_probability = 1"
                       :class="{ estimationWrap_active : userReview.recommendation_probability === 1 }">
                    <div class="estimation">
                      <img src="../assets/media/icon_bad.svg" alt="">
                    </div>
                  </div>
                  <div class="estimationWrap" @click="userReview.recommendation_probability = 50"
                       :class="{ estimationWrap_active : userReview.recommendation_probability === 50 }">
                    <div class="estimation">
                      <img src="../assets/media/icon_norm.svg" alt="">
                    </div>
                  </div>
                  <div class="estimationWrap" @click="userReview.recommendation_probability = 100"
                       :class="{ estimationWrap_active : userReview.recommendation_probability === 100 }">
                    <div class="estimation">
                      <img src="../assets/media/icon_good.svg" alt="">
                    </div>
                  </div>
                </div>
              </div>
              <div class="reviewItem d-lg-flex align-items-center justify-content-between">
                <div class="reviewTitle">
                  Наш портал
                </div>
                <star-rating v-model:rating="userReview.portal"
                             :show-rating="false"
                             inactive-color="#2C2D73"
                             active-color="#ffffff"
                             :padding="20"
                             style="justify-content: center; margin-right: -20px;"
                             :star-size="36"></star-rating>
              </div>
              <div class="reviewItem" style="height: 100%;position: relative">
                <div class="reviewTitle">
                  Пожелания и комментарии
                </div>
                <textarea class="" v-model="userReview.wishes_and_recommendations"></textarea>
                <div class="d-flex align-items-center position-relative">
                  <div class="check" @click="this.checked=!checked">
                    <input class="form-check-input form-check-input-lg"
                           :checked=false
                           v-model="userReview.want_orginizer_responce"
                           type="checkbox">
                  </div>
                  <div class="text-start">Ожидаю получить реакцию от организаторов</div>
                </div>
              </div>
            </div>
            <div class="reviewBtns d-flex justify-content-end">
              <my-button
                  cls='blue'
                  style="margin-right: 16px"
                  @click="step='step_2'"
                  :icon_left="require('../assets/media/long_right.svg')"
                  with-margin-right>Назад
              </my-button>
              <my-button @click="sendReview(true)">Отправить</my-button>
            </div>
          </div>
        </div>
      </div>
      <div class="container" v-if="step=='step_4'"><h1 class="text-center mt-5">Спасибо за оставленный отзыв</h1>
        <my-button cls='blue' @click="this.$router.push({name: 'Games'})" style="margin-right: 16px" :icon_left="require('../assets/media/long_right.svg')" with-margin-right>Назад</my-button>
      </div>
      <my-modal ref="claimModal">
        <claim-modal
            :event-info="eventInfo"
            :tours="tours"
            @close="$refs.claimModal.close"
            @send-claim="sendClaim"
        />
      </my-modal>
    </div>
    <div v-else class="">
      <div class="container">
        <h1 v-if="eventIsNotPassed" class="text-center mt-5 mb-4">Игра ещё не прошла</h1>
        <h1 v-else class="text-center mt-5 mb-4">Вы уже оставили отзыв на эту игру</h1>
        <div class="w-100 d-flex justify-content-center">
          <my-button cls='blue' style="margin-right: 144px; text-align: center" @click="this.$router.back()" :icon_left="require('../assets/media/long_right.svg')" with-margin-right>Назад</my-button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import MyButton from "@/components/UI/MyButton";
import MyModal from "@/components/UI/MyModal";
import ClaimModal from "@/components/main/ClaimModal";
import StarRating from 'vue-star-rating'
import PreLoader from "../components/UI/PreLoader";
import VueSelect from "../components/UI/VueSelect.vue";
import {useToast} from "vue-toastification";

export default {
  name: "Reviews",
  components: {VueSelect, PreLoader, MyButton, StarRating, MyModal, ClaimModal},
  data() {
    return {
      loading: true,
      eventInfo: null,
      step: 'step_1',
      tours: null,
      userReview: {
        event_team: null,
        questions_assessment: null,
        event_organization_assessment: null,
        event_atmosphere_assessment: null,
        event_host_assessment: null,
        restaurant_service_assessment: null,
        food_and_drink_quality: null,
        restaurant_comment: null,
        want_restaurant_responce: false,
        recommendation_probability: null,
        portal: null,
        wishes_and_recommendations: null,
        want_orginizer_responce: false,
        is_completed: false,
        claims:[],
      },
      validFormFields: {
        event_team: true,
      },
      eventTeamIsConfirmed: false,
      pastEvents: [],
      isCompleted: null,
    }
  },
  mounted() {
    this.loadEventInfo().then(()=>this.sendReview())
  },
  setup(){
    const toast = useToast()
    return {toast}
  },
  computed:{
    eventIsNotPassed(){
      const event = this.eventInfo
      if (event){
        return new Date() < new Date(event.date)
      }
      return true
    }
  },
  methods: {
    addClaim(){
      this.$refs.claimModal.open()
    },
    loadEventInfo(){
      let vm = this
      return new Promise((resolve, reject)=>{
        this.axios.get(`/api/event/${vm.$route.params.eventId}/with_game_package/`,
        {
              headers: {
                'Authorization': `Token ${vm.$store.state.user.authToken}`
              }
            }
        )
         .then(function (response) {
              vm.isCompleted = response.data.is_completed
              response.data.game.theme.title = response.data.game.theme.title.replace(/&nbsp;/g, " ")
              vm.eventInfo = response.data
              vm.tours = response.data.game.game_package.tours
              const userEventTeam = vm.eventInfo.event_teams.filter(et=>et.event_players.filter(ep=>ep.user.id===vm.$store.state.user.id).length)[0]
              vm.userReview.event_team = userEventTeam ? userEventTeam.id : null
              vm.eventTeamIsConfirmed = !!userEventTeam
              console.log(vm.eventTeamIsConfirmed)
              resolve(response.data)
            })
            .catch(function (error) {
              console.log(error)
              vm.$toast.error(error)
              reject(error)
            })
      })
    },
    sendClaim(claim){
      this.userReview.claims.push({...claim, review: this.userReview.id })
      this.sendReview(false)
    },
    formIsValid(){
      // if (!vm.userReview.event_team){
      //   vm.validFormFields.event_team = false
      //   vm.toast.error('Укажите команду за которую вы играли.')
      //   vm.step = 'step_1'
      //   return false
      // }
      return true
    },
    sendReview(isSubmit=false){
      const vm = this
      if (isSubmit && !this.formIsValid()) return
      let submitData = {...vm.userReview}
      if (isSubmit) submitData.is_completed = true
      Object.keys(submitData).forEach(key => submitData[key]===null ? delete submitData[key]: '' )
      this.axios.post(`/api/event_review/`,
          {
            ...submitData,
            event: vm.eventInfo.id,

          },
      {headers: {'Authorization': `Token ${vm.$store.state.user.authToken}`}}
          )
       .then(function (response) {
            if (isSubmit) {
              vm.step='step_4'
            } else {
              vm.userReview = response.data
            }
          })
          .catch(function (error) {
            if (error.response) {
              if (error.response.status === 403) {
                vm.isCompleted = true
              } else {
                vm.$toast.error(error)
              }
            } else {
              vm.$toast.error(error)
            }
          })
          .finally(()=>vm.loading=false)

    },
  }
}
</script>

<style scoped>
.reviewsHeader {
  margin-bottom: 60px;
  margin-top: 20px;
  padding: 0;
}
textarea {
  resize: none!important;
}
.container {
  max-width: 960px;
}
.reviewsHeaderItem {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #FFFFFF;
  padding: 10px 18px;
  background: #E32289;
  display: flex;
  position: relative;
}
.reviewsHeaderItem img{
  margin-right: 4px;
  width: 15px;
}
.reviewsHeaderItem_step1 {
    border-radius: 0;
    margin-right: 8px;
}
.reviewsHeaderItem_step1_disabled {
  background-color: #33347E;
  border-radius: 12px 0 0 12px;
  margin-right: 8px;
}
.reviewsHeaderItem_step1_disabled:after {
    content: '';
    position: absolute;
    right: -35px;
    top: 0px;
    z-index: 3;
    border: 21px solid transparent;
    border-left: 14px solid #33347e;
}
.reviewsHeaderItem_step2 {
  border-radius: 0;
  /*margin-right: 8px;*/
  background-color: #33347E;
}
.reviewsHeaderItem_step3 {
  border-radius: 0px 12px 12px 0px;
  margin-left: 8px;
  background-color: #33347E;
}
.reviewsHeaderItem_step1:after {
  content: '';
  position: absolute;
  right: -35px;
  top: 0px;
  z-index: 3;
  border: 21px solid transparent;
  border-left: 14px solid #e32289;
}
.reviewsHeaderItem_step2:before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
  border: 21px solid transparent;
  border-left: 14px solid #2c2d73;
}
.reviewsHeaderItem_step2_active:after {
    content: '';
    position: absolute;
    top: 0px;
    z-index: 2;
    left: 0px;
    border: 21px solid transparent;
    border-left: 14px solid #2c2d73;
}
.reviewsHeaderItem_step2_active:before {
  content: '';
  position: absolute;
      top: 0px;
  z-index: 4;
        right: -35px;
    border: 21px solid transparent;
    border-left: 14px solid #E32289;
}
.reviewsHeaderItem_step2_disabled:after {
    content: '';
    position: absolute;
    top: 0px;
    z-index: 2;
    left: 0px;
    border: 21px solid transparent;
    border-left: 14px solid #2c2d73;
}
.reviewsHeaderItem_step2_disabled:before {
  content: '';
  position: absolute;
      top: 0px;
  z-index: 4;
        right: -35px;
    border: 21px solid transparent;
    border-left: 14px solid #33347e;
}
.reviewsHeaderItem_step2:after {
    content: '';
    position: absolute;
    right: -35px;
    top: 0px;
  z-index: 4;
    border: 21px solid transparent;
    border-left: 14px solid #33347e;
}
.reviewsHeaderItem_step2_disabled {
    font-weight: 600;
    line-height: 22px;
    color: #FFFFFF;
    background: #33347e;
    display: flex;
    position: relative;
}
.reviewsHeaderItem_step3:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0px;
  z-index: 3;
  border: 21px solid transparent;
  border-left: 14px solid #2c2d73;
}
.reviewsHeaderItem_step3_active {
    border-radius: 0px 12px 12px 0px;
    margin-left: 8px;
    background-color: #e32289;
}
.stepNumber {
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  text-transform: uppercase;
  color: #FFFFFF;
  opacity: 0.6;
}
.gameTitle {
  font-weight: 600;
  font-size: 20px;
  line-height: 130%;
  color: #FFFFFF;
  margin-top: 10px;
}
.reviewList {
  margin-top: 40px;
  margin-bottom: 60px;
}
.reviewItem {
  background: #33347E;
  border-radius: 0px;
  padding: 12px 10px 48px;
  margin-left: -10px;
  margin-right: -10px;
  /*height: 180px;*/
  margin-bottom: 10px;
}
.reviewItem:last-child {
  margin-bottom: 0;
}
.reviewTitle {
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  color: #FFFFFF;
  margin-top: 37px;
  margin-bottom: 30px;
}
.claim {
  background: #3B3C8D;
  border-radius: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 143%;
  color: #FFFFFF;
  opacity: 0.7;
  padding: 10px 32px;
  margin-left: 18px;
}
.claim:hover {
  opacity: 1;
}
.stars img {
  margin-right: 20px;
  height: 22px;
  width: 22px;
}
.stars img:last-child {
  margin-right: 0;
}
.estimationWrap {
  background: #2E3077;
  box-shadow: inset 0px 2px 3px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-left: 10px;
  width: 130px;
}
.estimationWrap:nth-child(1) {
  margin-left: 0px;
}
.smiles {
  padding: 10px 0;
}
.estimation {
  font-weight: 600;
  font-size: 32px;
  text-align: center;
  color: #FFFFFF;
  height: 54px;
}
.estimation_text {
  font-weight: 500;
  font-size: 16px;
  line-height: 143%;
  color: #FFFFFF;
  opacity: 0.7;
  padding: 16px 0;
  text-align: center;
}
.estimationWrap_active {
  /*background: #3B3C8D;*/
  background: #E32289;
  border-radius: 10px;
}
.reviewBtns {
  margin-bottom: 30px;
}
textarea {
  width: 100%;
  margin-top: 20px;
  resize: none;
  border: none;
  color: #fff;
  height: 126px;
  background: #2E3077;
  box-shadow: inset 0px 2px 3px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
}
textarea::placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #FFFFFF;
  opacity: 0.4;
}
.check {
  height: 48px;
  width: 48px;
  border-radius: 1rem;
  padding: 7px;
}
.form-check-input[type=checkbox] {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  border: 2px solid #ffffff4a;
  background-color: transparent;
}
.form-check-input:checked[type=checkbox] {
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid #E32289;
  background-image: url("../assets/media/check_bold.svg");
}
.form-check-input:focus {
  outline: 0;
  box-shadow: none;
}
.starShadow {
  box-shadow: inset 0px 2px 3px rgba(0, 0, 0, 0.1);
}
button {
  padding: 0.5rem 1.5rem;
}

@media (min-width: 767px) {

  .reviewsHeaderItem {
    padding: 9px 86px;
    font-size: 16px;
  }
  .reviewItem {
    margin-left: 0;
    margin-right: 0;
    border-radius: 20px;
    padding: 12px 28px;
    min-height: 80px;
  }
  .estimationWrap:nth-child(1) {
    margin-left: 10px;
  }
  .reviewsHeaderItem img{
    width: 24px;
  }
  .reviewsHeaderItem_step1 {
    border-radius: 12px 0px 0px 12px;
  }
  /*.reviewTitle {*/
  /*  margin-top: 0;*/
  /*  margin-bottom: 0;*/
  /*}*/
  .estimation_text {
    padding: 16px 16px;
  }
  .gameTitle {
    font-size: 28px;
  }
}
@media (min-width: 992px) {
  .reviewsHeaderItem {
    padding: 9px 108px;
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  .claim {
    margin-bottom: 18px;
  }
}
</style>
