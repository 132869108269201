<template>
<transition>
  <div>
    <div v-if="!isList" class="category col">
        <div class="categoryImage" :style="'background-image: url('+image+')'">
        </div>
        <div class="categoryTitle">
          <p>{{title}}</p>
        </div>
      </div>
      <div v-if="isList" class="listCetegory">
        <div class="d-flex align-items-center">
          <div class="listImage" :style="'background-image: url('+image+')'"></div>
          <p class="listTitle">{{title}}</p>
        </div>
        <div class="listCartDiv">
          <div class="listCartIcon"></div>
        </div>
      </div>
  </div>
</transition>
</template>

<script>
export default {
    name: 'Category',
    props: ['title', 'image', 'isList'],
}
</script>

<style scoped>
.listCetegory {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
  background: #33347E;
  border-radius: 20px;
  padding-left: 90px;
  padding-right: 60px;
  height: 80px;
}
.listImage {
  position: absolute;
  left: 10px;
  margin-top: 8px;
  margin-bottom: 8px;
  width: 80px;
  height: 64px;
  border-radius: 12px;
  background-position: center;
  background-size: cover;
}
.listTitle {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  margin-left: 1rem;
}
.listCartDiv {
  position: absolute;
  right: 0;
  width: 60px;
  height: 100%;
  background: #3B3C8D;
  border-radius: 0px 20px 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.listCartIcon {
  background-image: url(../../assets/media/arrow_down.svg);
  width: 17px;
  height: 10px;
  background-position: center;
  background-size: cover;
  transform: rotate(-90deg);
}
.category {
  padding: 0;
  display: flex;
  flex-direction: column;
  /*align-items: center;*/
  margin-bottom: 1.25rem;
}
.categoryImage {
  position: relative;
  border-radius: 16px 16px 0px 0px;
  /*width: 9.7rem;*/
  width: 100%;
  height: 7.75rem;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.categoryTitle {
  background-color: #3B3C8D;
  /*width: 9.7rem;*/
  width: 100%;
  font-weight: 600;
  font-size: 0.6875rem;
  line-height: 0.8125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 0px 8px 8px;
  padding: 0.45rem 0.5rem;
}
@media (min-width: 576px) {
    .categoryImage {
        /*width: 14rem;*/
      width: 100%;
        height: 11.25rem;
    }
    .categoryTitle {
        /*width: 14rem;*/
      width: 100%;
        font-size: 1rem;
        line-height: 22px;
    }
    .listImage {
      width: 100px;
      height: 80px;
    }
    .listCetegory {
      height: 100px;
      padding-left: 110px;
    }
    .listTitle {
      font-size: 20px;
      line-height: 22px;
    }
}
@media (min-width: 768px) {
  .listCetegory {
    padding-right: 100px;
  }
  .listCartContainer {
    width: 100px;
  }
  .listCartIcon {
    width: 24px;
    height: 13px;
  }
}
</style>