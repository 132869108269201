<template>
    <div class="wrapInput mb-2">
        <label class="my-2 fs-14" :class="{'text-danger': error}">{{ label }}</label>
        <div class="select-wrap" :class="{'select-wrap-danger': error}">
            <select
                ref="select"
                @change="update"
                :value="modelValue"
                :disabled="this.disabled"
                :required="required"
            >
                <option v-for="(option, index) in options" :key="index" :value="option.id">{{ option.title }}</option>
            </select>
          <div class="position-absolute end-0 me-3 top-50 translate-middle-y"><img src="@/assets/media/arrow_down.svg"></div>
        </div>
    </div>
</template>

<script>
import eventReviewView from "../../views/EventReviewView.vue";

export default {
    name: "VueSelect",
  computed: {
    eventReviewView() {
      return eventReviewView
    }
  },
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        error: {
          type: Boolean,
          default: false
        },
        required: {
          type: Boolean,
          default: false
        },
        modelValue: {},
        placeholder: {},
        options: {
          type: Array,
          required: true
        },
        label: {},
    },
    methods: {
        update() {
            this.$emit('update:modelValue', this.$refs.select.value)
        }
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&display=swap');
.wrapInput{
  margin: 1.75rem auto 1.75rem;
  max-width: 28rem;
}
label {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    text-transform: uppercase;
    display: flex;
    line-height: 1.25rem;
    color: #fff;
    font-weight: 500;
    font-size: 0.625rem;
    letter-spacing: 0.2em;
    top: -1rem;
    left: 1.5rem;
    text-transform: uppercase;
    /* padding: 0.375rem 0.625rem; */
}

.select-wrap {
    height: 3.25rem;
    border-radius: 1.25rem;
    border: 1px solid #fff;
    padding: 0.5rem 1rem;
    width: 100%;
    position: relative;
}
.select-wrap-danger{
      border: 1px solid var(--bs-danger);
}
/* .select-wrap select:focus-visible, */
/* .select-wrap select:hover {
    outline: -webkit-focus-ring-color auto 1px;
} */

select:focus-visible {
    border: none;
    outline: none;
}

select {
  -webkit-appearance: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    background: transparent;
    width: 100%;
    color: #fff;
    width: 100%;
    height: 100%;
    background: none;
    border: none;
}

option {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    background-color: none !important;
    color: black;
}
</style>