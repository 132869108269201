import axios from "axios";
import { useToast } from "vue-toastification";

const toast = useToast()

class TemplatesApi {
    constructor(BASE_URL) {
        this.BASE_URL = BASE_URL
    }

    _request(params) {
        if (!params.method) params.method = 'get'
        // if (store.state.user.authToken) {
        //     params.headers = {'Authorization': `Token ${store.state.user.authToken}`}
        // }
        params.baseURL = this.BASE_URL
        return new Promise((resolve, reject) => {
            axios(params)
                .then(res => resolve(res.data))
                .catch(err => {
                    console.error(err)
                    if (err.response) {
                        if (err.response.status === 401) {
                            toast.error(err.toString())
                        }
                    }
                    reject(err)
                })
        })
    }

    renderTemplate(data, template_type,) {
        return this._request({
            method: 'post',
            url: `/set/prostoquiz/basic/the_last/task/${template_type}/`,
            data: data,
            params: {
                include_bootstrap: 0
            }
        })
    }
}
export default new TemplatesApi('https://templates.pro100quiz.ru')