<template>
  <div class="d-flex flex-xl-column align-items-center avatarContainer mx-auto">
    <div class="changePictureWrap">
      <label for="picture" :style="{ backgroundImage: 'url('+ getPicture() +')'}" class="labelPicture" ref="imageDisplay"></label>
      <input @change="change()" ref="fileinput" type="file" accept="image/*" class="d-none" autocomplete="off">
    </div>
    <div class="m-auto pt-3">
<!--      <p>{{ requirementsText }}</p>-->
        <MyButton class="editBtn" @click="$refs.fileinput.click()" :icon="require('../../assets/media/edit.svg')" cls="noText"></MyButton>
    </div>
  </div>
</template>

<script>
import MyButton from "../UI/MyButton";
export default {
  name: "ChangePicture",
  components: {MyButton},
  props: {
    picture: {
      type: String
    }
  },
  data: ()=>{
    return{
    }
  },

  methods:{
    getPicture(){
      return this.picture? this.glb.getMediaUrl(this.picture): require('../../assets/media/Cookie.svg');
    },
    change(){
      let vm = this
      let file = this.$refs.fileinput.files[0]
      let fileName = this.$refs.fileinput.value
      let ext = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase()
      if (file &&  (ext === "png" || ext === "jpeg" || ext === "jpg")){
        let reader = new FileReader()
        reader.onload = function (e) {
          console.log(vm.$refs.imageDisplay)
           vm.$refs.imageDisplay.style.backgroundImage=`url('${e.target.result}')`
        }
        reader.readAsDataURL(file);

        this.$emit('update:modelValue', file)
      }
    }
  }
}
</script>

<style scoped>
.avatarContainer {
  max-width: 360px;
}
.changePictureWrap {
  border-radius: 1.25rem;
  border: 1px solid #fff;
  height: 162px;
  width: 162px;
  /*padding: 46px 50px;*/
}
.labelPicture{
  cursor: pointer;
  background-repeat: no-repeat;
  border-radius: 1.25rem;
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
}
.editBtn {
  width: 50px;
  height: 50px;
  box-shadow: 0px 5px 24px -12px rgba(103, 15, 118, 0.87);
  border-radius: 16px;
}

</style>
