<template>
    <div class="sittingPlayerContainer">
        <div class="pathImg">
            <div class="topPath"></div>
            <div v-if="playerDetails.phone != teamDetails.players[teamDetails.players.length-1].phone" class="bottomPath"></div>
        </div>
        <div class="avatarImg"></div>
        <div class="playerDetails">
            <p class="playerName">{{playerDetails.first_name}} {{playerDetails.last_name}}</p>
            <p class="playerID">ID {{playerDetails.phone}}</p>
        </div>
        <div class="lockerIcon"></div>
        <my-button v-if="0" cls="noText" :icon="require('../../assets/media/redo.svg')"></my-button>
        <my-button v-if="playerDetails.is_legionary == true" cls="noText" :icon="require('../../assets/media/userClose.svg')"></my-button>
    </div>
</template>

<script>
import MyButton from '../UI/MyButton';
export default {
    name: 'PlayerInSittingTeam',
    components: { MyButton },
    props: [ 'playerDetails', 'teamDetails' ],
    methods: {
        handleData() {
            console.log(this.playerDetails)
        }
    },
    mounted() {
        this.handleData()
    }
}
</script>

<style scoped>
.noText {
  padding: 0rem;
  border: 0.15rem solid #E32289;
  display: flex;
  background-color: transparent;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  width: 30px;
  min-width: 30px;
  height: 30px;
  margin-left: 0.5rem;
}
.sittingPlayerContainer {
    background: #33347E;
    border-radius: 1rem;
    padding: 1rem;
    display: flex;
    align-items: center;
}
.pathImg {
    margin-right: 0.5rem;
}
.topPath {
    width: 20px;
    height: 20px;
    opacity: 0.4;
    border-left: 1px solid #FFFFFF;
    border-bottom: 1px solid #FFFFFF;
    border-bottom-left-radius: 6px;
    margin-bottom: 0.2rem;
}
.bottomPath {
    width: 20px;
    height: 20px;
    opacity: 0.4;
    border-left: 1px dotted #FFFFFF;
}
.avatarImg {
    width: 40px;
    height: 40px;
    border-radius: 5rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url(../../assets/media/avatar.png);
    margin-right: 1rem;
}
.playerName {
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
    margin-bottom: 0.5rem;
}
.playerID {
    font-size: 10px;
    line-height: 100%;
    opacity: 0.6;
}
.lockerIcon {
    margin-left: auto;
    opacity: 0.2;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url(../../assets/media/lock.svg);
}

</style>