<template>
<div class="game_result container">
  <div v-if="event.result">
    <game-result-table :event="event"
    ></game-result-table>
  </div>
  <pre-loader v-else></pre-loader>
</div>
</template>

<script>
import PreLoader from "../UI/PreLoader";
import GameResultTable from "../main/GameResultTable";

export default {
  name: "GameResult",
  components: {PreLoader, GameResultTable},
  data() {
    return {
      event: {},
    }
  },
  created: function () {
    this.loadData();
  },
  computed: {

  },
  methods: {
    loadData: function () {
      const vm = this;
      this.axios.get(`/api/events_scores/${vm.$route.params.eventId}`)
          .then(function (response) {
            console.log(response.data);
            vm.event = response.data
          })
          .catch(function (error) {
            console.log(error)
            vm.$toast.error(error)
          })
      ;
    },
  },
}
</script>

<style scoped>

</style>