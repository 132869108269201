<template>
    <div class="updatedDetails">
            <div class="updatedSeats">
                <div class="updatedSeatsContainer" v-if="currentMenu == 'Гибкая рассадка'">
                    <p class="updatedSeatsNum">{{eventInfo.availableSeats}}</p>
                    <p class="updatedSeatsSpan">MECT</p>
                </div>
                <div class="d-flex" v-if="currentMenu == 'С посадочными решениями' || currentMenu == 'С картосхемой'" >
                    <div class="tableContainer" 
                        v-for="table in eventInfo.place.seats" 
                        :key="table.id"
                        @click="handleTable(table.id)"
                    >
                    <div :class="{'selected' : table.isSelected == true}"></div>
                    <div class="tableIcon" v-if="table.thumbnail != null" :style="{backgroundImage: 'url(' + table.thumbnail + ')'}"></div>
                    <div v-else class="tableIcon"></div>
                        <div class="tableInfoContainer">
                            <p v-if="currentMenu == 'С посадочными решениями'" class="updatedSeatsNum">{{table.capacity}} - {{table.max_capacity}}</p>
                            <p v-if="currentMenu == 'С картосхемой'" class="thirdTypeSeatsCount">{{table.capacity}} - {{table.max_capacity}}</p>
                            <div class="countSpanContainer">
                                <p class="countSpan">{{table.free}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="freeSeats">
                <div class="freeSeatsContainer">
                    <div class="chairIcon"></div>
                    <p class="updatedSeatsNum chairMargins">20</p>
                </div>
            </div>
            <div class="comparisonContainer">
                <div class="comparisonItem seperator">
                    <p class="comparisonSpan">Рассажено</p>
                    <p class="comparisonNum">{{eventInfo.playersInGame}}</p>
                </div>
                <div class="comparisonItem">
                    <p class="comparisonSpan">Желающих</p>
                    <p class="comparisonNum">{{eventInfo.participantsCount}}</p>
                </div>
            </div>
            <div class="finishDiv">
                <div class="lockIcon"></div>
                <p class="finishSpan">Завершить<br>рассадку</p>
            </div>
        </div>
</template>
<script>
export default {
    name: 'SittingMenu',
    props: ['currentMenu', 'eventInfo', 'teamsInfo'],
    data() {
        return {
            tableAmount: 0,
            seatsAmount: 0,
            reservedSeats: 0,
            teamsAmount: 0,
            lonersAmount: 0,
            totalPlayers: 0,
            tableArray: [],
        }
    },
    methods: {
        handleData() {
            this.tableArray = this.eventInfo.place.furniture
            for (let i in this.tableArray) {
                this.tableAmount += this.tableArray[i].quantity
                this.seatsAmount += this.tableArray[i].quantity * this.tableArray[i].capacity
            }
            for (let i in this.teamsInfo) {
                if (this.teamsInfo[i].players.length > 1) {
                    this.teamsAmount += 1
                    this.totalPlayers += this.teamsInfo[i].players.length
                } else {
                    this.lonersAmount += 1
                    this.totalPlayers += 1
                }
            }
        },
        handleTable(id) {
            this.$emit('tableSelected', id)
        }
    },
    mounted() {
        this.handleData()
    }
}
</script>
<style scoped>
.thirdTypeSeatsCount {
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    white-space: nowrap;
}
.freeSeatsContainer {
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    align-items: center;
    padding-right: 1rem;
}
.updatedSeatsContainer {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    border-right: 1px solid rgba(255, 255, 255, 0.1);
}
.tableInfoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.countSpanContainer {
    border: 1px solid #E32289;
    box-sizing: border-box;
    border-radius: 51px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4px;
    width: 25px;
    height: 25px;
    margin-top: 5px;
}
.countSpan {
    font-weight: 500;
    font-size: 12px;
    line-height: 143%;
    
}
.tableContainer {
    position: relative;
    display: flex;
    align-items: center;
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    padding-right: 1rem;
    padding-left: 1rem;
}
.tableIcon {
    margin-right: 0.5rem;
    width: 53.39px;
    height: 57.16px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url(../../assets/media/table.svg);
    border-radius: 1rem;
}
.selected {
    position: absolute;
    top: -10%;
    left: 0;
    width: 100%;
    height: 120%;
    border: 2px solid #F6A82B;
    border-radius: 1rem;
}
.finishDiv {
    background: #3B3C8D;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    height: 100%;
    width: 125px;
    top: 0;
    right: 0;
}
.lockIcon {
    margin-bottom: 0.5rem;
    opacity: 0.2;
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url(../../assets/media/lock.svg);
}
.finishSpan {
    font-size: 12px;
    line-height: 110%;
    text-align: center;
}
.comparisonContainer {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
}
.seperator {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
}
.comparisonItem {
    display: flex;
    justify-content: space-between;
    width: 9rem;
}
.comparisonSpan,
.comparisonNum {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
}
.updatedDetails {
    background: #33347E;
    box-shadow: 0px 18px 59px #282864;
    display: flex;
    margin-top: 1rem;
    margin-bottom: 1rem;
    overflow-x: scroll;
}
.updatedSeats {
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.updatedSeatsNum {
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    white-space: nowrap;
}
.updatedSeatsSpan {
    font-size: 12px;
    line-height: 100%;
    opacity: 0.6;
    margin-top: 0.5rem;
}
.freeSeats {
    display: flex;
    align-items: center;
    padding: 1rem;
}
.chairIcon {
    width: 34.5px;
    height: 51px;
    background-image: url(../../assets/media/chair.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-right: 0.5rem;
}
.chairMargins {
    margin-bottom: 1.9rem;
}

@media (min-width: 1200px) {
    .updatedDetails {
        border-radius: 16px;
        width: 95%;
        margin-left: auto;
        margin-right: auto;
    }
}
</style>