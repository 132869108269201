<template>
  <h4 class="formTitle mb-3">Оценка игры</h4>
  <h2 class="mb-3">Претензия к вопросу</h2>
  <h5>Укажите номер тура и вопроса, добавьте Ваш комментарий.</h5>
  <div class="row mt-5">
    <div class="">
      <h4 class="formTitle">Тур</h4>
      <div class="tours d-flex flex-row">
        <div class="tourNumber"
             v-for="(tour, tour_index) in tours"
             :key="tour.id"
             @click="selectTour(tour, tour_index)"
             :class="{ 'is_active' : tour == selectedTour}">{{tour_index +1}}</div>
      </div>
    </div>
    <div class="mt-4" v-if="selectedTour">
      <h4 class="formTitle" >Вопрос</h4>
      <div class="questions d-flex flex-wrap align-items-center mb-1"
           v-for="(block, block_index) in selectedTour.task_blocks"
           :key="block.id"
      >
        <h4 class="formTitle"
            style="min-width: 64px;margin-bottom: 0"
            v-if="selectedTour.task_blocks.length>1"
        >Блок&nbsp;&nbsp;{{block_index +1}}</h4>
        <div class="questionNumber flex-wrap mb-2"
             v-for="(task, task_index) in block.tasks"
             :key="task.id"
             @click="selectTask(task, block_index, task_index)"
             :class="{ 'is_active' : task == selectedTask}"
        >{{task_index +1}}</div>
      </div>
    </div>
    <div class="mt-4">
      <h4 class="formTitle">Комментарий</h4>
      <textarea v-model="claim.text"></textarea>
    </div>
    <div class="reviewBtns d-flex justify-content-end mt-4">
          <my-button
             cls='blue'
             style="margin-right: 16px"
             @click="$emit('close')"
             with-margin-right>Закрыть
          </my-button>
          <my-button @click="sendClaim()">Отправить</my-button>
        </div>
  </div>
</template>

<script>
import MyButton from "@/components/UI/MyButton";
export default {
  components: {MyButton},
  name: "Claim",
  props: ['eventInfo', 'tours', 'tasks'],
  data() {
    return {
      selectedTour: null,
      selectedTask: null,
      claim: {
        tour: null,
        task: null,
        block: null,
        text: '',
      },
    }
  },
  mounted() {

  },
  methods: {
  selectTour(tour, tour_index){
      this.selectedTour = tour
      this.claim.tour = tour_index+1
    },
  selectTask(task, block_index, task_index){
    this.selectedTask = task
    this.claim.block = block_index+1
    this.claim.task = task_index+1
  },
  sendClaim() {
    let claimCheck = true
    Object.values(this.claim).forEach(function(el){
      if (!el) {
        claimCheck = false
      }
    })
    if (!claimCheck) {
      this.$toast.info('Заполните все поля, пожалуйста!')
      return null
    }
    this.$emit('send-claim', this.claim)
    this.selectedTour = null
    this.selectedTask = null
    this.claim.block = null
    this.claim.text = ''
    this.claim.tour = null
    this.claim.task = null
  },
  }
}
</script>

<style scoped>
.is_active {
    background: #E32289!important;
    border-radius: 10px!important;
  }
.tourNumber,.questionNumber {
  background: #2E3077;
  border-radius: 10px;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.formTitle {
  margin-bottom: 20px;
}
textarea {
  width: 100%;
  resize: none;
  border: none;
  color: #fff;
  height: 126px;
  background: #2E3077;
  box-shadow: inset 0px 2px 3px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
}
textarea::placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #FFFFFF;
  opacity: 0.4;
}
</style>
