<template>
  <div class="cabinetContainer text-center text-xl-start">
<!--    <div class="d-flex align-items-center w-100" v-if="isAuthenticated">-->
<!--      <div>-->
<!--        <h2 class="">Личный кабинет</h2>-->
<!--        <p class="userID">ID {{ $store.state.user.id }}</p>-->
<!--      </div>-->
<!--    </div>-->
    <div class="row">
      <div class="col-12 col-md-6" style="margin: 0 auto">
        <div class="d-flex justify-content-start mt-3">
          <h1 class="position-relative mb-4 mx-auto mx-xl-1">
            Личный кабинет
          </h1>
        </div>
        <transition name="collapse">
            <div class="d-xl-flex">
              <div class="mb-3 pt-3 mx-auto">
                <ChangePicture
                    v-model="inputAvatar"
                    :picture="user.avatar"
                ></ChangePicture>
              </div>
              <div class="personalDetailsBlock">
                <MyInput
                  type="text"
                  label="Имя"
                  v-model="inputName"
                  name="name"
                ></MyInput>
                <MyInput
                  type="text"
                  label="Телефон"
                  v-model="inputPhone"
                  name="phone"
                ></MyInput>
                <my-button
                  cls="pink"
                  @click="handleEditSubmit">
                  Сохранить изменения
                </my-button>
              </div>
            </div>
        </transition>
        <div class="pe-xl-4">
          <my-button @click="$refs.comfTimeModal.open()" cls="blue" with-margin :icon="require('../assets/media/calendar_white.svg')">Мне удобно играть</my-button>
          <my-button @click="$router.push({name: 'themeVote'})" cls="blue" with-margin>Голосование за тему на след. неделе<i class="ms-2 bi bi-person-hearts"></i></my-button>
          <my-button @click="handleLegion" cls="blue" with-margin>
            Готов играть с другими командами
            <div v-if="isLegionaryLoading" class="ms-3"><div class="spinner-border spinner-border-sm text-light" role="status"><span class="visually-hidden">Loading...</span></div></div>
            <div v-else class="ms-3 fs-5">
              <i v-if="$store.state.user.is_legionary" class="bi-check-circle"></i>
              <i v-else class="bi-circle"></i>
            </div>
          </my-button>
<!--          <my-button cls="blue">Comf</my-button>-->
        </div>
      </div>
<!--      <div class="col-12 col-md-6 flex-column">-->
<!--        <div class="d-flex justify-content-between">-->
<!--          <h3 class="blockWrapperTitle position-relative mx-auto mx-xl-1 mt-5 mb-5">-->
<!--            Новости-->
<!--            <Notification count="13"></Notification>-->
<!--          </h3>-->
<!--        </div>-->
<!--        <transition name="collapse">-->
<!--          <div class="col-12">-->
<!--            <div class="newsList">-->
<!--              <NewsItem @click="$refs.voteForGame.open()" vote-item ></NewsItem>-->
<!--              <NewsItem @click="$refs.invitationInTeam.open()" invitation-in-team-item></NewsItem>-->
<!--              <NewsItem viewed vote-item></NewsItem>-->
<!--              <NewsItem viewed></NewsItem>-->
<!--              <NewsItem viewed></NewsItem>-->
<!--              <NewsItem viewed></NewsItem>-->
<!--              <NewsItem viewed></NewsItem>-->
<!--              <NewsItem viewed></NewsItem>-->
<!--              <NewsItem viewed></NewsItem>-->
<!--              <NewsItem viewed></NewsItem>-->
<!--              <NewsItem viewed></NewsItem>-->
<!--              <NewsItem viewed></NewsItem>-->
<!--              <NewsItem viewed></NewsItem>-->
<!--            </div>-->
<!--          </div>-->
<!--        </transition>-->
<!--      </div>-->
    </div>

      <hr class="d-none d-md-block" style="margin-bottom: 4rem;margin-top: 8rem">
<my-modal ref="invitationInTeam">
        <div class="formWrap blockWrapper">
          <h4 class="formTitle">
            Приглашение в команду
          </h4>
          <p class="formSubtitle">
            Название команды
          </p>
          <p class="formDescription">
            Вас пригласили в команду
            “название команды”, что скажете?
          </p>
          <my-button cls="pink">
            Принять приглашение
          </my-button>
          <my-button cls="hollow">
            Отклонить приглашение
          </my-button>
          <hr>
          <my-button cls="blue">
            Назад
          </my-button>
    </div>
  </my-modal>
    <my-modal ref="voteForGame">
      <game-vote
        @close=$refs.voteForGame.close()
      ></game-vote>
    </my-modal>

    <my-modal ref="comfTimeModal">
      <comf-time v-if="$store.state.user.comfortable_time"></comf-time>
    </my-modal>
    <my-modal ref="confirmNewPhone">
     <div class="window" >
        <div class="d-flex flex-column formWrap blockWrapper">
          <p class="info-note ">Введите 4-х значный код, отправленный вам на WhatsApp, для подтверждения.</p>
        </div>
        <MyInput label="Код подтверждения"
                 phone-input
                 type="text"
                 v-model="confirmNewPhoneCode"
                 v-on:keyup.enter="confirmNewPhone"
                 maxlength="4"
                 autocomplete="off"
        ></MyInput>
          <p></p><div class="error-text" id="auth-error-text"></div>
          <my-button @click="confirmNewPhone" type="button">
						<span>
							ОК
						</span>
            <span v-if="pending" class="spinner-grow spinner-grow-sm ms-3" role="status" aria-hidden="true"></span>
          </my-button>
     </div>
  </my-modal>
  <auth-modal ref="auth"></auth-modal>
  <confirm-modal ref="confirmDiaologue"></confirm-modal>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import ComfTime from "../components/main/ComfTime";
import MyButton from "../components/UI/MyButton";
import GameVote from "../components/cabinet/GameVote";
import AuthModal from "../components/AuthModal";
import ConfirmModal from "../components/UI/ConfirmModal";
import MyInput from "../components/UI/MyInput";
import ChangePicture from "../components/main/ChangePicture";
// import NewsItem from "../components/main/NewsItem";
// import Notification from "../components/UI/Notification";
import MyModal from "../components/UI/MyModal";

export default {
  name: "PersonalCabinet",
  props: ['theme'],
  components: {AuthModal, GameVote, MyButton, ComfTime, ConfirmModal, MyInput, ChangePicture, MyModal},
  data() {
    return {
      inputPhone: '',
      confirmNewPhoneCode: '',
      inputName: '',
      isOpened: '',
      inputAvatar: '',
      isLegionary: null,
      isLegionaryLoading: false,
      news: [],
      events: {
        current: [],
        past: []
      },
    }
  },
  mounted() {
    this.inputName = this.user.first_name
    this.inputPhone = this.user.phone
    this.isLegionary = this.user.is_legionary
  },
  computed: {
    ...mapState(['isAuthenticated', 'user'])
  },
  methods: {
    ...mapActions(['SHOW_AUTH_MODAL']),
    async authenticate() {
      let vm = this;
      const ok = await this.$refs.auth.showAuthModal();
      if (ok) {
        vm.loadData();
      } else {
        vm.$toast.error('Что-то пошло не так!')
      }
    },
    getTeamIdForEvent(eventId) {
      let ep = this.$store.state.user.event_players.filter(ep => ep.event_team.event === eventId && ep.user === this.$store.state.user.id);
      console.log('ep >>> ',ep);
      return ep[0].event_team.team
    },
    setViewed() {
      console.log("hfdkjfhslkfjhsdkfjh")
    },
    getFormData(){
      let formData = new FormData()
      if(this.inputName != this.user.first_name) {
        formData.set('first_name', this.inputName)
      }
      if (this.inputPhone != this.user.phone) {
        formData.set('phone', this.glb.formatPhone(this.inputPhone))
      }
      if(this.inputAvatar) {
        formData.set('avatar', this.inputAvatar)
      }
      formData.set('is_legionary', this.isLegionary)
      return formData
    },

    handleEditSubmit: async function() {
      const vm = this;
      const formData = this.getFormData()
      if(formData) {
        this.$api.updateUser(formData)
          .then(res=>{
            console.log('Update user response >', res)
            if (vm.inputPhone != vm.user.phone) vm.$refs.confirmNewPhone.open()
          })
          .catch(function (error) {
              console.log(error)
              if (error.response.data.phone){
                vm.$toast.error(error.response.data.phone)
              } else if (error.response.data.detail) {
                vm.$toast.error(error.response.data.detail)
              } else {
                vm.$toast.error('Неверный код!')
              }
          });
      }
    },
    confirmNewPhone(){
      let vm = this
      this.$api.confirmNewPhone(this.confirmNewPhoneCode)
        .then(function (response) {
          console.log(response.data);
          vm.$toast.success('Номер успешно изменен :)', {duration: 2500})
          vm.$refs.confirmNewPhone.close()
          vm.confirmNewPhoneCode = ''
        })
        .catch(function (error) {
          console.log(error)
          vm.$toast.error(error)
        });
    },
    handleLegion() {
      const vm =this
      vm.isLegionaryLoading = true
      this.isLegionary = !this.isLegionary
      vm.$api.updateUser(vm.getFormData())
      .then(()=> {
        vm.$store.dispatch('GET_USER_DETAILS')
        .finally(()=>vm.isLegionaryLoading=false)
      })
    },
  },
}
</script>

<style scoped>
.personalDetailsBlock {
  width: 100%;
  margin-right: 2rem;
}
.cabinetContainer {
  padding-left: 20px;
  padding-right: 20px;
}

h2 {
  font-weight: 700;
  font-size: 1.825rem;
  line-height: 2rem;
}
.userID {
  line-height: 24px;
  opacity: 0.6;
}
.blockWrapperTitle {
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.375rem;
  margin: 0;
}
.blockWrapper {
  max-width: 24rem;
  margin: 0 auto;
  padding: 0;
}

.pink, .hollow, .blue {
  width: 100%;
  padding: .75rem;
  margin: 1.25rem auto;
  max-width: 28rem;
}
.newsList {
  height: 26.5rem;
  overflow-y: scroll;
  margin-bottom: 48px;
}
.flip {
  transform: rotate(180deg);
}


/* xs - устройства (до 576px) */
/* CSS для ширины, которая меньше 575px (включительно) */

/* sm-устройства (больше или равно 576px) */
@media (min-width: 576px) {
  /* CSS для: 576px <= ширины <= 767px */

}

/* md-устройства (больше или равно 768px) */
@media (min-width: 768px) {
  /* CSS для: 768px <= ширины <= 991px */
.newsList {
  height: 36.5rem;
}

}

/* lg-устройства (больше или равно 992px) */
@media (min-width: 992px) {
  /* CSS для: 992px <= ширины <= 1119px */
}

/* xl-устройства (больше или равно 1200px) */
@media (min-width: 1200px) {
  /* CSS для: ширины >= 1200px */
  .cabinetContainer {
    max-width: 1440px;
    /*padding-left: 238px;*/
    /*padding-right: 57px;*/
    margin-left: auto;
    margin-right: auto;
  }
  .personalDetailsBlock {
    margin-left: 1rem;
  }
  .newsList {
    height: 26.5rem;
  }
}


</style>
