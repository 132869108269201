<template>
  <div class="container h-100">
    <div class="row align-items-center h-100">
        <div class="col-12">
          <div class="formWrap blockWrapper" style="padding: 0">
            <h4 class="formTitle">
              Создать новую команду
            </h4>
            <p class="formSubtitle">
              Введите название новой команды в поле ниже
            </p>
            <MyInput
                ref="inputTeamTitle"
                v-model="titleOfNewTeam"
                label="Название команды"
                team-title-input
            ></MyInput>
            <my-button @keyup.enter="createNewTeam" @click="createNewTeam" class="mb-2" :style="{width:'100%'}">Создать команду</my-button>
            <my-button @click="$emit('close')" cls="blue" class="w-100"> Назад</my-button>
          </div>
        </div>

<!--        <div class="">-->
<!--          <input ref="inputTeamTitle"  class="form-control" type="text" placeholder="название новой команды" aria-label="default input example">-->
<!--        </div>-->

    </div>
    <confirm-modal ref="confirmDialogue"></confirm-modal>
  </div>
</template>

<script>
import ConfirmModal from "../UI/ConfirmModal";
import MyInput from "../UI/MyInput";
import {handleError} from "@/utils/error";
export default {
  name: "RegNewTeam",
  components: {ConfirmModal, MyInput},
  props:['event'],
  data() {
    return {
      titleOfNewTeam: '',
    }
  },

  mounted() {
    console.log(this.$refs.inputTeamTitle);
    this.$nextTick(()=>{
      this.$refs.inputTeamTitle.focus()
    })
  },
  methods: {
    async createNewTeam() {
      this.$api.createTeam({title: this.titleOfNewTeam})
          .then(data=>{
            this.afterCreate(data);
            this.$store.dispatch('GET_USER_DETAILS')
          })
          .catch(function (error){
            console.log(error);
            if (error.response.status === 409){
              this.confirmCaptain(error.response.data.info, error.response.data.team_id);
            } else {
              handleError(error)
            }
          });
    },
    async confirmCaptain(message, teamId) {
      const ok = await this.$refs.confirmDialogue.show({
        message: message,
      })
      if (ok) {
        this.$api.patchTeam(
            teamId,
            {
              captain: this.$store.state.user.id,
              city: this.$store.state.user.city,
            }
        )
            .then(data=>{
              this.afterCreate(data)
            })
            .catch(err=>handleError(err))
      } else {
        console.log('cancel');
      }
    },

    afterCreate(team) {
      if (this.$route.name.eventId){
        this.$router.push({name: 'RegTeamOnEvent', params: {eventId: this.$route.query.eventId,teamId: team.id }})
      } else {
        this.$emit('load-data')
        this.$emit('close')
      }
    },
  },
}
</script>

<style scoped>
.wrapInput {
  margin-top: 1rem;
}
</style>
