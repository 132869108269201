<template>
  <div class="app">
    <pre-loader v-if="loading"/>
    <router-view v-else/>
  </div>
</template>

<script>
import PreLoader from "./components/UI/PreLoader";
import update from "./mixins/update";
export default {
  components: {PreLoader},
  mixins: [update],
  data: ()=>{
  return{
    loading: true,
  }
},
mounted() {
  if (this.$store.state.user.authToken){
      this.$store.dispatch('GET_USER_DETAILS')
        .finally(()=>this.loading = false)
  } else {
    this.loading = false
  }
}
}
</script>

<style scoped>
.app{
  min-height: 100vh;
}
/*.collapse-enter-active,*/
/*.collapse-leave-active {*/
/*  transition: max-height .9s;*/
/*}*/
/*.collapse-enter-from,*/
/*.collapse-leave-to {*/
/*  max-height: 0;*/
/*  opacity: 0;*/
/*}*/
</style>
