<template>
  <div class="gameWrap col d-md-flex">
    <div class="col col-md-5 themePicture cursor-pointer" @click="goToEventPage" :style="{'background-image': 'url('+gameEvent.game.theme.picture_thumb+')'}">
<!--        <img class = "gameImg" :src="gameEvent.game.theme.picture_thumb" alt="">-->
    </div>
    <div class="col col-md-7 gameInfo">
      <div class="gameTitle cursor-pointer" @click="goToEventPage" v-html="gameEvent.game.theme.title +' '+ gameEvent.postfix "></div>
      <div class="gameInfoWrap d-flex align-items-center mt-0 mb-3">
        <div class="gamePrice">
          <div class="gameInfoWrapTitle">
            УЧАСТИЕ
          </div>
          <div class="price">
            {{ gameEvent.cost }}
            <span v-if="gameEvent.city.currency">{{gameEvent.city.currency.symbol}}</span>
          </div>
        </div>
        <div class="gameСomplexity">
          <div class="gameInfoWrapTitle" style="margin-bottom: 7px">
            СЛОЖНОСТЬ
          </div>
          <div v-if="gameEvent.difficulty === 'norm'" class="stars justify-content-center d-flex">
            <img src="../../assets/media/star_2.svg" alt="">
            <img src="../../assets/media/star_2.svg" alt="">
          </div>
          <div v-else-if="gameEvent.difficulty === 'easy'" class="stars justify-content-center d-flex">
            <img src="../../assets/media/star_2.svg" alt="">
          </div>
          <div v-else class="stars justify-content-center d-flex">
            <img src="../../assets/media/star_2.svg" alt="">
            <img src="../../assets/media/star_2.svg" alt="">
            <img src="../../assets/media/star_2.svg" alt="">
          </div>
        </div>
        <div class="gameBeginTime">
          <div class="gameInfoWrapTitle">
            НАЧАЛО
          </div>
          <div class="gameInfoWrapTime">
            {{ glb.getTime(gameEvent.date)}}
          </div>
        </div>
      </div>
      <div class="gamePlace mt-0 mb-3">
        <place-detail :place="gameEvent.place"/>
      </div>
      <div class="d-flex gameDate mt-0 mb-3">
        <div class="gameDay">
          <img class="icon" src="../../assets/media/calendar_white.svg" style="height: 20px"/>
          {{ glb.getDay(gameEvent.date)}}
        </div>
        <div class="gameMonth">
          {{ glb.getDate(gameEvent.date).split(' ')[1]}}
        </div>
        <div class="gameTime">
          <img class="icon" src="../../assets/media/alarm_white.svg" style="height: 20px"/>
         {{ glb.getTime(gameEvent.date)}}
        </div>

      </div>

      <div class="d-flex flex-column flex-row">
        <my-button
            v-if="isPassed"
            cls="pink"
            @click="showResults"
            with-margin
        ><span>Итоги игры</span></my-button>
        <my-button
            v-if="isPassed && event.album"
            @click="$router.push({name:'PhotoReport', params:{eventId:gameEvent.id}})"
            with-margin
        ><span>Фотоотчёт</span></my-button>
        <my-button
            v-if="isPassed && isParticipant && getQuantityOfDays(new Date(), this.gameEvent.date) <= quantityDaysForShowReviewButton"
            @click="$router.push({name:'Reviews', params:{eventId:gameEvent.id}})"
            data-toggle="tooltip"
            data-placement="top"
            title="Оставить отзыв"
            with-margin
        >Написать отзыв<i class="ms-2 bi bi-chat-right-text-fill"></i></my-button>
        <my-button
          v-if="!isPassed && gameEvent.reg_url"
          @click="openInNewTab(gameEvent.reg_url)"
          type="button"
          with-margin
          :icon="require('../../assets/media/long_right.svg')"
          :id="gameEvent.id"
        >Зарегистрироваться</my-button>
        <my-button
          v-if="!isPassed && gameEvent.isRegistered"
          @click="goToRegEvTeam"
          type="button"
          with-margin
          class="hollow text-white"
          :icon="require('../../assets/media/pink_check_circle.svg')"
        ><span>Вы зарегистрированы</span></my-button>
        <my-button
          ref="regOnEventBtn"
          v-if="!isPassed && !gameEvent.isRegistered"
          @click="clickPlay($event)"
          type="button"
          with-margin
          :icon="require('@/assets/media/long_right.svg')"
        ><span> Зарегистрироваться </span></my-button>
        <my-button
           v-if="gameEvent.game.theme.trial_game_package"
           cls="hollow"
           style="color: #ffffff;width: 100%"
           :icon="require('../../assets/media/Timer.svg')"
           href="#"
           @click="showPromoGameModal()"
           with-margin
       >Опробовать</my-button>
      </div>
    </div>
  <teleport to="#app">
  <my-modal ref="regOnEvent">
    <reg-on-event
        show-event-detail
        @close="$refs.regOnEvent.close()"
        :event-id="gameEvent.id"></reg-on-event>
  </my-modal>
  <my-modal ref="resultsTable">
    <game-result-table uncollapsed :event-id="gameEvent.id"></game-result-table>
  </my-modal>
  <my-modal auto-width v-if="gameEvent.game.theme.trial_game_package" ref="promoGame">
      <promo-game :theme="gameEvent.game.theme"></promo-game>
  </my-modal>
  </teleport>
  </div>
</template>

<script>
import {mapActions} from "vuex";
// import axios from "axios";
import MyModal from "../UI/MyModal";
import RegOnEvent from "./RegOnEvent";
import MyButton from "../UI/MyButton";
import GameResultTable from './GameResultTable.vue';
import PromoGame from "../main/PromoGame";
import PlaceDetail from "@/components/main/PlaceDetail.vue";

export default {
  name: "EventCard",
  components: {PlaceDetail, PromoGame, MyButton, RegOnEvent, MyModal, GameResultTable},
  props: ['gameEvent', 'past'],
  data() {
    return {
      event: {},
      quantityDaysForShowReviewButton : 6,
    }
  },
  computed: {
    isPassed(){
      return new Date() > new Date(this.gameEvent.date)
    },
    pathToRegEventTeam() {
      if (this.gameEvent.isRegistered) {
        let event_player = this.$store.state.user.event_players.filter(ep => ep.event_team.event === this.gameEvent.id)[0]
        return `/event/${this.gameEvent.id}/team/${event_player.event_team.team}/event_registration`
      }
      return ''
    },
    isParticipant(){
      let eps = []
      this.gameEvent.event_teams.forEach(team => {
        let filter = team.event_players.filter(ep=>ep.user.id === this.$store.state.user.id)
        if (filter.length > 0) {
          eps.push(filter)
        }
      })
      return eps.length
    }
  },
  mounted() {
    if (this.gameEvent.openRegistraion){
      this.$emit('show-auth-modal');
      this.$store.commit('SET_TARGET_CLICK_ELEMENT', this.$refs.regOnEventBtn);
    }
  },
  methods: {
    ...mapActions([
      'SHOW_AUTH_MODAL',
    ]),
    showResults() {
      this.$refs.resultsTable.open()
    },
    showPromoGameModal() {
      this.$refs.promoGame.open()

    },
    openInNewTab(url){
      window.open(url,  '_blank')
    },
    clickPlay(event, el=null){
      if (this.$store.getters.isAuthenticated) {
        this.$refs.regOnEvent.open()
      } else {
        if (!el){
          el = event.target
        }
        this.$emit('show-auth-modal');
        this.$store.dispatch('SET_TARGET_CLICK_ELEMENT', el);
      }
    },
    goToRegEvTeam() {
      let eps = this.$store.state.user.event_players.filter(ep => ep.event_team.event === this.gameEvent.id);
      return (eps.length>0) ? this.$router.push({name:'RegTeamOnEvent', params: {eventId: this.gameEvent.id, teamId: eps[0].event_team.team}}) : this.$toast.error('Упс!')
    },
    getQuantityOfDays(startDate, endDate) {
      let start = new Date(startDate)
      let end = new Date(endDate)
      let millisecondsOfOneDay = 24 * 60 * 60 * 1000
      let timeDelta = start.getTime() - end.getTime()
      let dayDelta = Math.round(timeDelta / millisecondsOfOneDay)
      if (dayDelta <= 0) {dayDelta = 999999999} // hardCode. Understand. But time...
      return dayDelta
    },
    goToEventPage(){
      this.$router.push({name: 'eventPage', params: {eventId: this.gameEvent.id}})
    }
  },
}
</script>

<style scoped>
.hollow {
  color: #E32289;
}
.reviewBtn > i{
  color: #E32289;
}
.reviewBtn:hover > i{
  color: #FFFFFF;
}
.gameInfoWrapTitle {
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  text-align: center;
  color: #FFFFFF;
  opacity: 0.6;
}
.gamePrice {
  padding-right: 16px;
}
.gameСomplexity {
  border-left: 1px solid #FFFFFF1f;
  padding-right: 16px;
  padding-left: 16px
}
.gameBeginTime {
  border-left: 1px solid #FFFFFF1f;
  padding-left: 16px
}
.gameInfoWrap {
  margin-bottom: 16px;
}
.price,.gameInfoWrapTime {
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  color: #FFFFFF;
  text-align: center;
  margin-top: 4px;
}
.pink {
    margin-bottom: 16px;
}

@media (min-width: 576px) {
  .gamePrice {
  padding-right: 30px;
}

.gameСomplexity {
  border-left: 1px solid #FFFFFF1f;
  padding-right: 30px;
  padding-left: 30px
}
.gameBeginTime {
  border-left: 1px solid #FFFFFF1f;
  padding-left: 30px
}
.stars img{
  max-height: 1.1rem;
}
}
/* md-устройства (больше или равно 768px) */
@media (min-width: 768px) {
  .pink {
    margin-right: 10px;
}
}
</style>
