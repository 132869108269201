<template>
  <div class="position-relative" :class="{'w-100': !noWidth}" :style="{'min-height': noHeight?undefined:'5rem'}">
    <div class="position-absolute start-50 top-50 translate-middle">
      <div class="spinner-border text-light" :class="{'spinner-border-sm': sm}" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PreLoader",
  props: {
    noHeight:{
      type: Boolean,
      default: false
    },
    noWidth:{
      type: Boolean,
      default: false
    },
    sm:{
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

</style>