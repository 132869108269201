<template>
  <pre-loader v-if="loading"></pre-loader>
<div v-else class="container-fluid container-md">
  <div v-if="event">
  <event-detail :event="event"/>
  <collapse-card v-if="!isPassed" is-uncollapsed>
    <template v-slot:header>Регистрация на игру</template>
    <template v-slot:body>
       <reg-on-event v-if="$store.state.user.authToken" :event-prop="event"/>
       <unauthorized-register-on-event-form v-else :event="event"/>
    </template>
  </collapse-card>
  <collapse-card v-if="isPassed" is-uncollapsed>
    <template v-slot:header>Результаты игры</template>
    <template v-slot:body>
       <game-result-table uncollapsed without-event-info :event-id="event.id"/>
    </template>
  </collapse-card>
  <collapse-card @open="$refs.promoVideo.play()" @close="$refs.promoVideo.pause()">
    <template v-slot:header>Что такое ПРО100 КВИЗ?</template>
    <template v-slot:body>
      <h3 class="mt-2">Откройте мир веселья барных викторин вместе с Про100 Квизом!</h3>
      <video ref="promoVideo" controls class="w-100 my-2" playsinline>
        <source src="https://pro100quiz.ru/static/promo.webm" type="video/webm">
        <source src="https://pro100quiz.ru/static/promo.mp4" type="video/mp4">
      </video>
      <div class="d-flex flex-column flex-xl-row align-items-center justify-content-center">
        <div class="weGotCard d-flex flex-column align-items-center justify-content-center mx-3">
          <img class="weCardIcon" src="@/assets/media/check_yellow.svg" />
          <p>Драйв, музыка и соревновательная борьба </p>
        </div>
        <div class="weGotCard d-flex flex-column align-items-center justify-content-center mx-3">
          <img class="weCardIcon" src="@/assets/media/check_bold.svg" />
          <p>Совмещение вкусного ужина и приятной игры</p>
        </div>
        <div class="weGotCard d-flex flex-column align-items-center justify-content-center mx-3">
          <img class="weCardIcon" src="@/assets/media/check_bold.svg" />
          <p>Отличный повод встретиться с друзьями</p>
        </div>
      </div>
    </template>
  </collapse-card>
  <collapse-card :max-height="500">
    <template v-slot:header>Выбрать любимые темы</template>
    <template v-slot:body>
      <theme-card
        v-for="theme in themes"
        :key="theme.id"
        :theme="theme"
        @show-auth-modal="showAuthModal"
        @set-like="setLike"
      />
    </template>
  </collapse-card>
  <collapse-card>
    <template v-slot:header>Выбрать удобный день и время</template>
    <template v-slot:body><comf-time :with-inputs="false"/></template>
  </collapse-card>
  </div>
  <div v-else>
    <h1 align="center"> Нет игры!</h1>
  </div>
  <auth-modal ref="auth"></auth-modal>
</div>
</template>

<script>
import PreLoader from "@/components/UI/PreLoader.vue";
import ThemeCard from "@/components/main/ThemeCard.vue";
import ComfTime from "@/components/main/ComfTime.vue";
import RegOnEvent from "@/components/main/RegOnEvent.vue";
import UnauthorizedRegisterOnEventForm from "@/components/main/UnauthorizedRegisterOnEventForm.vue";
import CollapseCard from "@/components/UI/CollapseCard.vue";
import {getEventDetails} from "@/utils/event";
import EventDetail from "@/components/Event/EventDetail.vue";
import AuthModal from "@/components/AuthModal.vue";
import GameResultTable from "@/components/main/GameResultTable.vue";

export default {
  name: "EventView",
  components: {
    GameResultTable,
    AuthModal,
    EventDetail,
    CollapseCard,
    UnauthorizedRegisterOnEventForm,
    RegOnEvent,
    ComfTime,
    ThemeCard,
    PreLoader
  },
  data:()=>{
    return{
      event: null,
      themes: null,
      loading: true,
      showRegistration: true,
      showPost: true,
      showAbout: false,
      showThemes: false,
      showComfortableTime: false
    }
  },
  mounted() {
    this.load()
  },
  computed: {
    isPassed(){
      return new Date() > new Date(this.event.date)
    },
  },
  methods:{
    load(){
      this.loadEvent()
      this.loadThemes()
    },
    async loadEvent(){
      const eventData = await getEventDetails(this.$route.params.eventId)
      this.event = eventData
      this.loading=false
    },
    async loadThemes(){
      const themesList = await this.$api.getThemes()
      this.themes = themesList
      this.setUserLikes()
    },
    setUserLikes(){
      if (this.$store.state.user.authToken){
        this.themes.forEach(theme=>{
          if (this.$store.state.user.like.indexOf(theme.id) !== -1) {
            theme.isLiked = true;
          }
        })
      }
    },
    setLike(themeId, status){
      this.themes = this.themes.map(theme => theme.id === themeId ? Object.assign({}, theme, {isLiked:status}) : theme)
    },
    showAuthModal(){
      this.$refs.auth.showAuthModal()
          .then(()=>this.setUserLikes())
    }
  }
}
</script>

<style scoped>
.weGotCard img{
  height: 2rem;
}
.container-md{
  max-width: 700px;
}
</style>