<template>
  <my-modal ref="popup">
    <div class="col-12">
      <div class="formWrap blockWrapper">
        <h4 class="formTitle">
          {{ title }}
        </h4>
        <p class="formSubtitle">
          {{ message }}
        </p>
        <my-button cls="pink" @click="_confirm">
          {{ okButton }}
        </my-button>
        <my-button v-if="cancelButton" cls="blue"  @click="_cancel">
          {{ cancelButton }}
        </my-button>
      </div>


<!--    <h2 style="margin-top: 0">{{ title }}</h2>-->
<!--    <p>{{ message }}</p>-->
<!--    <div class="d-flex justify-content-between">-->
<!--      <div><my-button  @click="_cancel">{{ cancelButton }}</my-button></div>-->
<!--      <div><my-button class="confirm" @click="_confirm">{{ okButton }}</my-button></div>-->
<!--    </div>-->
    </div>
  </my-modal>
</template>

<script>
import MyButton from "./MyButton";
import MyModal from "./MyModal";
export default {
  name: "ConfirmModal",
  components: {MyButton, MyModal},
  data: () => ({
    // Parameters that change depending on the type of dialogue
    title: undefined,
    message: undefined, // Main text content
    okButton: 'Да', // Text for confirm button; leave it empty because we don't know what we're using it for
    cancelButton: 'Нет', // text for cancel button

    // Private variables
    resolvePromise: undefined,
    rejectPromise: undefined,
  }),

  methods: {
    show(opts = {}) {
      this.title = opts.title
      this.message = opts.message
      if (opts.okButton) {
        this.okButton = opts.okButton
      }
      if (opts.cancelButton) {
        if (opts.cancelButton === 'disable'){
          this.cancelButton = false
        } else {
          this.cancelButton = opts.cancelButton
        }
      }
      // Once we set our config, we tell the popup modal to open
      this.$refs.popup.open()
      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },

    _confirm() {
      this.$refs.popup.close()
      this.resolvePromise(true)
    },

    _cancel() {
      this.$refs.popup.close()
      this.resolvePromise(false)
      // Or you can throw an error
      // this.rejectPromise(new Error('User cancelled the dialogue'))
    },
  },
}
</script>

<style scoped>
.confirm {
  background: linear-gradient(90deg, #6ED0A6 0%, #0D8451 100%);
}
.confirm:after {
  background: linear-gradient(90deg, #0D8451 100%, #6ED0A6 0%);

}

.blockWrapper {
  max-width: 24rem;
  padding: 0;
}
.formWrap {
  background: #33347E;
  border-radius: 1rem;
  padding: 0;
}
.formTitle {
  font-size: 10px;
  line-height: 24px;
  letter-spacing: 0.165em;
  text-transform: uppercase;
  opacity: 0.6;
  text-align: left;
}
.formSubtitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
  margin-bottom: 20px;
}
.pink, .hollow, .blue {
  width: 100%;
  padding: .75rem;
  margin: 1.25rem auto;
}
</style>