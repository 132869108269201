<template>
<transition name="fade">
  <div class="modal-dialog" role="document">
    <div class="modal-content cityModalContainer">
      <div class="modal-header d-flex flex-column">
        <div class="close-block" data-dismiss="modal" aria-label="Close">
          <i class="fa fa-times" aria-hidden="true" ></i>
        </div>
        <h2 class="city__close-title mb-3">Выберите город</h2>
        <form class="w-100">
          <my-input
            class="city__form my-3"
            ref="cityinput"
            v-model="searchCity"
            type="text" placeholder="Введите город"
            inputmode="search"
            label="Поиск"
            autocomplete="off"
          />
        </form>
      </div>
      <div class="seperator"></div>
      <div class="modal-body citiesList">
        <ul class="city__close-list" id="city_close_list">
          <li class="city_close_element cursor-pointer mb-2"
            v-for="city in citiesList"
            :key="city.id"
              @click="cityRedirect(city)" v-html="city.title"
          ></li>
        </ul>
      </div>
    </div>
  </div>
</transition>
</template>

<script>
import MyInput from '../UI/MyInput';
export default {
  name: "CityChoice",
  components: { MyInput },
  props: ['cities',],
  data () {
    return {
      show: false,
      searchCity: '',
    }
  },
  mounted() {
    this.$nextTick(()=>this.$refs.cityinput.focus())
  },
  computed: {
    modalClass() {
      return this.show ? 'modal city__close d-block' : 'modal city__close';
    },
    citiesList() {
      if (this.searchCity) {
        return this.cities.filter(c => c.title.toLowerCase().includes(this.searchCity.toLowerCase()))
      } else {
        return this.cities
      }
    },
  },
  methods: {
    open: function (){
      this.show=true;
      this.$nextTick(()=>{
        this.$refs.cityinput.focus();
      })
    },
    cityRedirect: function (city){
      window.location.href = `${window.location.protocol}//${city.subdomain}.${this.glb.domain()}`
    }
  }

}
</script>

<style scoped>
.cityModalContainer {
  border: none;
  background-color: transparent;
  height: 45rem;
}
.citiesList {
  overflow-y: scroll;
  padding: 0.5rem 0;
}
.modal-header {
  border-bottom: none;
}
.seperator {
  width: 85%;
  opacity: .1;
  border-bottom: 1px solid white;
  margin-left: auto;
  margin-right: auto;
}
</style>
