import { createStore } from 'vuex'
import {global_objects} from "../global_objects";
import axios from "axios";

const getMyDomain = ()=>{
  try{
    return '.'+window.location.hostname.split('.').slice(-2)[0]+'.'+window.location.hostname.split('.').slice(-2)[1]
  } catch (err){
    return window.location.hostname
  }
}

const DOMAIN = getMyDomain()

export default createStore({
  state: {
    user:{
      authToken: global_objects.getCookie('quiz_token') || (process.env.NODE_ENV === 'production'?'':process.env.VUE_APP_DEV_AUTHTOKEN),
      first_name: '',
      phone: '',
      city:'',
      captain: [],
      like: [],
      teams: [],
      event_players: [],
      comfortable_time: null,
      id:''
    },
    isAuthenticated: false,
    showAuthModal: false,
    targetClick: null,
  },
  mutations: {
    SET_USER_TOKEN_ON_STATE(state, token) {
      state.user.authToken = token;
      global_objects.setCookie('quiz_token', token, {domain: DOMAIN, path: '/'})
    },
    SET_AUTH_STATUS(state, bool) {
      state.isAuthenticated = bool;
    },
    SET_USER_FIELDS_ON_STATE(state, user) {
      state.user = {...state.user, ...user}
    },
    SET_SHOW_AUTH_MODAL_ON_STATE(state, bool) {
      state.showAuthModal = bool;
    },
    SET_TARGET_CLICK_ELEMENT_ON_STATE(state, el) {
      state.targetClick = el;
    },
    LOGOUT_USER(state) {
      state.user = {
        authToken: null,
        first_name: '',
        avatar: null,
        phone: '',
        city:'',
        captain: [],
        like: [],
        teams: [],
        event_players: [],
        comfortable_time:{},
        id:''
      }
      state.isAuthenticated = false;
    },
  },
  actions: {
    SET_USER_TOKEN({commit}, token) {
      if (token) {
        commit('SET_USER_TOKEN_ON_STATE', token)
      } else {
      commit('SET_USER_TOKEN_ON_STATE', global_objects.getCookie('quiz_token'))
      }
    },
    SET_USER_FIELDS({commit}, user) {
      commit('SET_USER_FIELDS_ON_STATE', user)
    },
    SHOW_AUTH_MODAL({commit}, bool) {
      commit('SET_SHOW_AUTH_MODAL_ON_STATE', bool)
    },
    GET_USER_DETAILS({commit}){
      return new Promise((resolve, reject)=>{
        axios.get(
            `/api/user_detail`,
            {headers:{
                'Authorization': `Token ${this.state.user.authToken}`
              }
            })
            .then(function (response) {
              commit('SET_USER_FIELDS_ON_STATE', response.data);
              commit('SET_AUTH_STATUS', true);
              resolve(response.data)
            })
            .catch(function (error){
              // console.log(error.response.data);
              if (error.response.status === 403) {
                commit('SET_USER_TOKEN_ON_STATE', '')
                global_objects.setCookie('quiz_token', "",{'max-age': -1, domain: DOMAIN})
              }
              reject(error)
            });
      })
    },
    SET_TARGET_CLICK_ELEMENT({commit}, el){
      commit('SET_TARGET_CLICK_ELEMENT_ON_STATE', el)
    },
    LOGOUT({commit}){
      return new Promise((resolve) =>{
        global_objects.setCookie('quiz_token', "",{'max-age': -1, domain: DOMAIN})
        commit('SET_USER_TOKEN_ON_STATE', '');
        commit('SET_AUTH_STATUS', false);
        resolve()
      })
    },
  },
  getters: {
    USER_TOKEN(state) {
      return state.user.authToken
    },
    isAuthenticated(state) {
      // console.log('VUEX GETTERS ISAUTHENTICATED',state.user.authToken, !!state.user.authToken)
      return !!state.user.authToken
    }
  },
})
