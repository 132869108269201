<template>
  <transition name="fade">
    <div class="popup-modal" v-if="isVisible" @click.self="close">
      <div class="window position-relative" :class="{'w-auto': autoWidth}">
        <div class="window-content">
        <div
            class="position-absolute fs-3"
            style=" top: 0;right: 0.5rem; z-index: 150;"
            @click="close"
        ><i class="bi bi-x" style="font-size: 32px"></i></div>
        <div class="pt-4">
          <slot></slot>
        </div>
      </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'MyModal',
  props:{
    autoWidth:{
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    isVisible: false,
    isVoted: false
  }),
  methods: {
    open() {
      this.isVisible = true
      this.isVoted = true
    },
    close() {
      this.isVisible = false
    },
  },
}
</script>

<style scoped>
/* css class for the transition */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.popup-modal {
  background-color: rgba(0, 0, 0, 0.73);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.5rem;
  align-items: center;
  z-index: 1100;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.window {
  position: relative;
  background: #33347E;
  border-radius: 1rem;
  box-shadow: 2px 4px 8px rgb(0 0 0 / 20%);
  max-width: 90vw;
  width: min-content;
  padding: 0.75rem;
  margin: 1.75rem auto;
  min-width: 320px;
  /*min-height: 80vh;*/
}

.window-content{
  height: 100%;
}
/* md-устройства (больше или равно 768px) */
@media (min-width: 768px) {
    .window {
  padding: 1.75rem;
  min-width: 520px;
}

}
</style>
