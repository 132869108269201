<template>
<div @click.self="show=!show" class="collapse-wrap">
    <div @click="show=!show" class="d-flex justify-content-between">
      <div><slot name="header"/></div>
      <div><img :class="{'revert': show}" :src="require('@/assets/media/arrow_down.svg')"></div>
    </div>
    <collapse-transition>
      <div class="collapsed-container" v-show="show" :style="{maxHeight: maxHeight+'px'}" :class="{'overflow-scroll': maxHeight}">
        <slot name="body"/>
      </div>
    </collapse-transition>
</div>
</template>

<script>
import CollapseTransition from '@ivanv/vue-collapse-transition/src/CollapseTransition.vue';

export default {
  name: "CollapseCard",
  components: {CollapseTransition},
  props:{
    isUncollapsed:{
      type: Boolean,
      default: false
    },
    maxHeight:{
      type: Number,
      default: undefined
    }
  },
  data:()=>{
    return{
      show: false
    }
  },
  mounted() {
    this.show = this.isUncollapsed
  },
  watch:{
    show(currentValue){
      this.$emit(currentValue ? 'open' : 'close')
    }
  }
}
</script>

<style scoped>
.collapse-wrap{
  background: rgba(227, 34, 137, 0.19);
  border-radius: 1rem;
  padding: 0.75rem;
  margin-bottom: 0.75rem;
}
.collapsed-container{
  margin-top: 0.5rem;
  border-top: 2px solid rgba(227, 34, 137, 0.53);
}
.revert{
  transform: rotate(180deg);
  transition: 0.2s;
}

</style>