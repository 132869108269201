<template>
    <div class="row justify-content-center text-center">
      <transition name="fade">
        <div v-if="!agree">
          <div class="col-12 mt-5">
            <my-button class="disabled" :icon="team.rank?require(`../../assets/media/${team.rank}.png`):''">
            </my-button>
            <h3>Вы хотите играть в команде "{{ team.title }}"?</h3>
          </div>
          <div class="col d-flex justify-content-center text-center">
            <my-button class="btn btn-danger m-2" @click="$router.push('/')"> <span>Нет </span></my-button>
            <my-button v-on:click="setAgree" class="btn btn-success m-2"><span>Хочу </span></my-button>
          </div>
        </div>
        <div id="ok" v-else>
          <transition name="fade">
            <div class="d-flex justify-content-center flex-column">
              <h3>Теперь вы в команде "{{ team.title }}" !</h3>
              <my-button @click="$router.push('/')" style="margin: 1.5rem auto;"><span> ОК </span></my-button>
            </div>
          </transition>
        </div>
      </transition>
    </div>
</template>

<script>

import axios from "axios";
import {mapActions} from "vuex";

import MyButton from "../UI/MyButton";

export default {
  name: 'RegOnTeam',
  components: {
    MyButton,
  },
  data() {
    return {
      team: {},
      agree: false,
    }
  },
  created: function () {
    const vm = this;
    //получение данных о команде и игре
    axios.get(
        `/api/team_by_slug/${vm.$route.params.slug}/`,
    )
        .then(function (response) {
          console.log(response.data);
          vm.team = response.data;
        });
  },
  methods: {
    ...mapActions(['SHOW_AUTH_MODAL',]),
    setAgree: function () {
      let vm = this;
      if (this.$store.state.user.authToken) {
        axios.post(
            `/api/team/${vm.team.id}/new_player/`,
            {
              'name': this.$store.state.user.first_name,
              'phone': this.$store.state.user.phone,
            },
            {headers:{
                'Authorization': `Token ${this.$store.state.user.authToken}`
              }
            })
            .then(function (response) {
              vm.agree = true;
              console.log(response.data)
            })
            .catch(function (error){
              console.log(error);
              vm.$toast.error(error);
            });
      } else {
        vm.SHOW_AUTH_MODAL(true);
      }
    },
  },
}
</script>

<style scoped>
.disabled {
  background: transparent!important;
  border: none!important;
  padding: 0!important;
  margin-right: 8px!important;
}
.disabled .btnIcon {
  margin-left: 0!important;
}
</style>
