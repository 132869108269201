<template>
<my-modal ref="modal">
  <div v-if="success" class="d-flex flex-column align-items-center">
    <div style="font-size: 85px">🎉</div>
    <div class="fs-3">Вы зарегистрированы на игру !</div>
    <div class="my-3">Добавьте Игроков в команду кнопкой «Добавить игрока», либо участников, кто придёт с вами на игру (без создания профиля на Портале), просто + и - в поле «С ним идут»</div>
    <my-button class="w-100" @click="$refs.modal.close()">Хорошо</my-button>
  </div>
  <div v-else class="d-flex flex-column align-items-center">
    <div style="font-size: 85px">😔</div>
    <div class="fs-3 mb-3">Вы отменили свою регистрацию на игру!</div>
    <my-button class="w-100" @click="$refs.modal.close()">Ладно</my-button>
  </div>
</my-modal>
</template>

<script>
import MyModal from "@/components/UI/MyModal.vue";
import MyButton from "@/components/UI/MyButton.vue";

export default {
  name: "PlayerRegisterInfoModal",
  components: {MyButton, MyModal},
  data:()=>{
    return{
      success: true,
    }
  },
  methods:{
    registered(){
      this.success = true
      this.$refs.modal.open()
    },
    unregitered(){
      this.success = false
      this.$refs.modal.open()
    }
  }
}
</script>

<style scoped>

</style>