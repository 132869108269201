<template>
<a class="text-nowrap" :href="`https://3.redirect.appmetrica.yandex.com/route?end-lat=${endLat}&end-lon=${endLon}&ref=pro100quizru&appmetrica_tracking_id=1178268795219780156&lang=ru`">
  <img :src="require('@/assets/media/yandex_taxi_app.png')" alt="taxi">
  <span class="ms-2">Вызвать такси</span>
</a>
</template>

<script>
export default {
  name: "CallTaxi",
  props: ['endLat', 'endLon']
}
</script>

<style scoped>
a{
  background-color: white;
  border-radius: 10px;
  color: black;
  overflow: hidden;
  padding: 0.5rem 0.5rem;
  text-decoration: none;
}
a:hover{
  color: black;
  background-color: #e2e2e2;
  transition: 0.2s;
}
a > img{
  height: 23px;
  width: 23px;
  /*background: url() no-repeat 0;*/
  bottom: 0;
  left: 15px;
}
</style>