<template>
<div>
  <div class="d-flex align-items-center" style="text-decoration: underline">
    <img class="icon" src="@/assets/media/place.svg" alt="geo" style="height: 20px;margin-right: 4px;"/>
  <div @click="$refs.placeModal.open()" class="cursor-pointer text-decoration-underline" v-html="place.title"></div>

     <span v-if="withAddress" v-html="',' + place.address"></span>
  </div>

  <teleport to="#app">
  <my-modal ref="placeModal">
    <div class="placeContent">
      <div>
        <div class="placeDescription d-flex justify-content-between align-items-end">
          <p class="fs-1 fw-bold mb-3" v-html="place.title"></p>
        </div>
        <div class="d-flex align-items-center mb-4 justify-content-between">
          <div class="d-flex align-items-center">
            <img class="icon" src="@/assets/media/place.svg" alt="geo" style="height: 25px;margin-right: 4px;"/>
            <div v-html="place.address"></div>
          </div>
          <div class="" v-if="place.coordinates"><call-taxi :end-lat="place.coordinates[0]" :end-lon="place.coordinates[1]"/></div>
        </div>
      </div>

      <div class="rounded-3 overflow-hidden" v-if="place.coordinates">
        <pre-loader v-if="mapLoading"/>
        <yandex-map
            v-show="!mapLoading"
            @map-was-initialized="mapLoading=false"
            :coords="place.coordinates"
            :zoom="15"
            :controls="['routeButtonControl']"
        >
          <ymap-marker :coords="place.coordinates" />
        </yandex-map>
      </div>
    </div>
  </my-modal>
  </teleport>
</div>
</template>

<script>
import MyModal from "@/components/UI/MyModal.vue";
import { yandexMap, ymapMarker } from 'vue-yandex-maps'
import CallTaxi from "@/components/UI/CallTaxi.vue";
import PreLoader from "@/components/UI/PreLoader.vue";
export default {
  name: "PlaceDetail",
  components: {PreLoader, CallTaxi, MyModal, yandexMap, ymapMarker},
  props: {
    place:{
      type: Object,
      required: true
    },
    withAddress:{
      type: Boolean,
      default: false
    }
  },
  data: ()=>{
    return{
      mapLoading: true,
    }
  },
  methods:{
    alert(val){
      alert(val)
    }
  }
}
</script>

<style scoped>
.placeContent{
  min-width: 300px;
  width: 80vw;
}
.ymap-container {
  height: 400px;
  width: 100%;
}
.yandex-balloon {
height: 200px;
width: 200px;
}
@media (min-width: 450px)  {
  .placeContent{
    width: 400px;
  }
}
@media (min-width: 650px)  {
  .placeContent{
    min-width: 550px;
  }
}
</style>
