<template>
<div>
  <navbar></navbar>
  <div class="routerContainer">
    <router-view></router-view>
  </div>
  <main-footer></main-footer>
</div>
</template>

<script>
import Navbar from "../components/Navbar";
import MainFooter from "../components/main/MainFooter";

export default {
  name: "Base",
  components: {MainFooter, Navbar},
}
</script>

<style scoped>
.routerContainer{
  min-height: calc(100vh - 184px)
}
@media (min-width: 1200px) {
  .routerContainer{
    min-height: calc(100vh - 226px)
  }
}
</style>