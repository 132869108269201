<template>
<div v-if="!registrationIsOpen" class="mt-3 mb-3 text-center">
      Регистрация не доступна ... 😥
</div>
<div v-else-if="!isNewTeamButtonClicked">
    <br>
    <my-button class="w-100" @click="isNewTeamButtonClicked = true">Создать новую команду</my-button>
    <br>
    <my-button class="w-100" @click="redirectToLogin">Играть в существующей команде</my-button>
    <br>
</div>
<form v-else @submit.prevent="submitForm">
    <vue-select
        v-if="event.united && event.unitedEvents"
        label="Место"
        :options="getUnitedEventsOptions()"
        v-model="selectedEvent"
        required
    />

    <my-input
      :disabled="regFormResponse"
      required
      label="Название команды"
      v-model="regForm.teamTitle"
    />
    <my-input
      required
      :disabled="regFormResponse"
      :label="`Кол-во участников (не больше ${maxPlayerInTeam})`"
      v-model="regForm.participantCount"
      type="number"
      :max="maxPlayerInTeam"
      min="1"
    />
    <my-input
      :disabled="regFormResponse"
      required
      label="Ваше имя"
      v-model="regForm.firstName"
    />
    <my-input
      required
      :disabled="regFormResponse"
      ref="phoneInput"
      phone-input
      label="Телефон для связи"
      v-model="regForm.phone"
      type="tel"
      data-tel-input
      autocomplete="tel"
      maxlength="18"
    />
    <div v-if="error" class="text-danger">{{error}}</div>
    <my-button type="button" cls="blue" class="w-100" v-if="regFormResponse">Вы зарегистрированы</my-button>
    <my-button type="button" class="w-100" v-else-if="loading" cls="purple">Регистрируем <pre-loader class="ms-4" no-width no-height sm/></my-button>
    <my-button class="w-100" v-else type="submit">Зарегистрироваться</my-button>
  </form>
  <teleport to="#app">
    <my-modal ref="finalReg">
      <div class="fs-5 mb-3">Ваша команда <strong>{{regForm.teamTitle}}</strong> успешно зарегистрирована!</div>
      <div v-if="codeSended">
        <p> Если хотите войти в кабинет регистрации команды,</p>
        <p v-if="codeSendBy==='whatsapp'" class="info-note ">введите 4-х значный код, отправленный вам в WhatsApp.</p>
        <p v-else-if="codeSendBy==='flash_call'" class="info-note ">введите последние 4-ре цифры номера входящего вызова.</p>
        <p v-else class="info-note ">введите 4-х значный код, отправленный вам на номер <strong>{{regForm.phone}}</strong>.</p>
        <my-input
          ref="inputCode"
          v-on:keyup.enter="sendValidateCode"
          v-model="validateCode"
          type="number"
          maxlength="4"
          pattern="\d{4}"
          autocomplete="off"
          label="Код"
        />
      </div>
      <my-button cls="hollow" class="w-100" @click="$router.push('/')" > На главную</my-button>
    </my-modal>
    <my-modal ref="inviteToLogin">
      <p class="fs-5 my-3">Команда с таким названием уже существует!</p>
      <p class="my-3">Если эта команда создана вами, тогда нужно авторизоваться, чтобы вы могли её зарегистрировать на игру.</p>
      <my-button class="w-100" @click="$router.push({name:'login', query: {from: $route.path}})">
        Авторизоваться
      </my-button>
    </my-modal>
  </teleport>
</template>

<script>
import {useToast} from "vue-toastification";
import MyButton from "@/components/UI/MyButton.vue";
import MyInput from "@/components/UI/MyInput.vue";
import MyModal from "@/components/UI/MyModal.vue";
import VueSelect from "@/components/UI/VueSelect.vue";
import {getUnitedEventPlacesForSelect} from "@/utils/event";
import {handleError} from "@/utils/error";
import PreLoader from "@/components/UI/PreLoader.vue";

export default {
  name: "UnauthorizedRegisterOnEventForm",
  props: ['event'],
  components: {PreLoader, VueSelect, MyButton, MyInput, MyModal},
  data: ()=>{
    return{
      selectedEvent: null,
      regForm:{
        firstName: '',
        phone: '',
        teamTitle: '',
        participantCount: 1
      },
      error: null,
      codeSended: false,
      codeSendBy: null,
      validateCode: null,
      regFormResponse: null,
      isNewTeamButtonClicked: false,
      loading: false
    }
  },
  setup(){
    const toast = useToast()
    return {toast}
  },
  computed: {
    registrationIsOpen(){
      return ['published', 'sitting'].includes(this.event.workflow_state)
    },
    maxPlayerInTeam(){
      if (this.event && this.event.place && this.event.place.max_players_in_team){
        return this.event.place.max_players_in_team
      } else {
        return 10
      }
    }
  },
  mounted(){
    if (this.$store.state.user.authToken){
      this.checkRegistration()
    }
  },
  watch:{
    validateCode() {
      if (`${this.validateCode}`.length>3) this.sendValidateCode();
    },
  },
  methods:{
   submitForm(){
      const vm = this
      if(vm.loading) return
      vm.loading = true
      vm.error = null
      vm.$logApi.log({
        logs:{
          event: 'submit form',
          phone_input_value: document.querySelector('input[data-tel-input]').value,
          form_data:{
            event: vm.selectedEvent || vm.event.id,
            phone: vm.glb.formatPhone(vm.regForm.phone),
            name: vm.regForm.firstName,
            participant_count: vm.regForm.participantCount,
            team_title: vm.regForm.teamTitle,
          }
        }
      })
      vm.$api.sendEventRegistrationForm(
          vm.selectedEvent || vm.event.id,
          {
            phone: vm.glb.formatPhone(vm.regForm.phone),
            name: vm.regForm.firstName,
            participant_count: vm.regForm.participantCount,
            team_title: vm.regForm.teamTitle,
          })
        .then(data=>{
          vm.$refs.finalReg.open()
          vm.regFormResponse = data
          try{
            vm.codeSended = vm.regFormResponse.send_auth_code.sent
            vm.codeSendBy = vm.regFormResponse.send_auth_code.send_by
          } catch (err){
            console.log(err)
          }
          vm.loading = false
        })
        .catch(err=>{
          handleError(err)
          vm.loading = false
        })
    },
    showFormData(){
      this.toast(JSON.stringify(this.regForm))
    },
    goToRegPage(teamId){
      this.$router.push({
        name: 'RegTeamOnEvent',
        params: {eventId: this.$route.params.eventId, teamId: teamId },
        query: {set_check: 'true'}
      })
    },
    sendValidateCode(){
      const vm = this
      vm.$api.validateAuthCode(vm.regForm.phone, vm.validateCode)
      .then(res=>{
        vm.$store.dispatch('SET_USER_TOKEN', res.token)
        vm.$store.dispatch('GET_USER_DETAILS')
        vm.goToRegPage(vm.regFormResponse.event_team.team.id)
      })
    },
    redirectToLogin(){
        const currentPath = this.$route.fullPath
        this.$router.push({ path: '/login', query: { from: currentPath } })
    },
    getUnitedEventsOptions(){
      return getUnitedEventPlacesForSelect(this.event)
    }
  }
}
</script>

<style scoped>

</style>
