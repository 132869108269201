<template>
    <div class="moduleContainer" v-if="isLoaded">
        <h2 class="pageHeader ms-3">Рассадка команд на игру</h2>
        <div class="eventDetailsContainer">
            
            <div class="eventImg" :style="{backgroundImage: 'url('+eventDetails.game.theme.picture+')'}"></div>
            <div class="detailsTexts">
                <p class="eventHeader">{{eventDetails.game.theme.title}}</p>
                <p class="detailsP">{{eventDetails.place.title}} {{eventDetails.place.address}}</p>
                <div class="d-flex mt-1">
                    <div class="calendarIcon iconImg"></div>
                    <p class="detailsP">{{glb.getDate(eventDetails.date)}} </p>
                    <div class="clockIcon iconImg"></div>
                    <p class="detailsP">{{glb.getTime(eventDetails.date)}}</p>
                </div>
            </div>
        </div>
        <div class="sittingMenuContainer">
            <div class="sittingMenu">
                <div @click="currentMenu = 'Общая информация'" class="sittingMenuItem" :class="{'active': currentMenu === 'Общая информация'}"><p class="menuText">Общая информация</p></div>
                <div @click="currentMenu = 'Гибкая рассадка', loadData()" class="sittingMenuItem" :class="{'active': currentMenu === 'Гибкая рассадка'}"><p class="menuText">Гибкая рассадка</p></div>
                <div @click="currentMenu = 'С посадочными решениями', loadData()" class="sittingMenuItem" :class="{'active': currentMenu === 'С посадочными решениями'}"><p class="menuText">С посадочными решениями</p></div>
                <div @click="currentMenu = 'С картосхемой', loadData()" class="sittingMenuItem" :class="{'active': currentMenu === 'С картосхемой'}"><p class="menuText">С картосхемой</p></div>
            </div>
        </div>
        <div v-if="currentMenu === 'Общая информация'">
            <place-details :placeInfo="eventDetails" :teamsInfo="eventTeams"></place-details>
        </div>
        <div v-if="currentMenu === 'Гибкая рассадка'">
                <sitting-team 
                v-for="team in listOne"
                :key="team.id"
                :teamDetails="team"
                :currentMenu="currentMenu"
                @sittingEvent="updateParent"
                >
                </sitting-team>
                <div v-if="listOne.length < 1" class="editField">
                    <div class="plusContainer">
                        <p>+</p>
                    </div>
                    <p>Нажмите что бы добавить команду в игру.</p>
                </div>
            <sitting-menu
                :currentMenu="currentMenu"
                :eventInfo="eventDetails"
                :teamsInfo="eventTeams"
            ></sitting-menu>
            <div>
            <sitting-team
                v-for="team in listTwo"
                :key="team.id"
                :teamDetails="team"
                :currentMenu="currentMenu"
                @sittingEvent="updateParent"
            ></sitting-team>
            </div>
        </div>
        <div v-if="currentMenu === 'С посадочными решениями'">
            <div v-if="listOne.length < 1" class="editField">
                <div class="plusContainer">
                    <p>+</p>
                </div>
                <p>Нажмите что бы добавить команду в игру.</p>
            </div>
            <sitting-team
                v-for="team in listOne"
                :key="team.id"
                :teamDetails="team"
                :currentMenu="currentMenu"
                @sittingEvent="updateParent"
            ></sitting-team>
            <sitting-menu
                :currentMenu="currentMenu"
                :eventInfo="eventDetails"
                :teamsInfo="eventTeams"
                @tableSelected="chooseTable"
            ></sitting-menu>
            <sitting-team
                v-for="team in listTwo"
                :key="team.id"
                :teamDetails="team"
                :currentMenu="currentMenu"
                @sittingEvent="updateParent"
            ></sitting-team>
        </div>
        <div v-if="currentMenu === 'С картосхемой'">
            <div v-if="listOne.length < 1" class="editField">
                <div class="plusContainer">
                    <p>+</p>
                </div>
                <p>Нажмите что бы добавить команду в игру.</p>
            </div>
            <sitting-team
                v-for="team in listOne"
                :key="team.id"
                :teamDetails="team"
                :currentMenu="currentMenu"
                @sittingEvent="updateParent"
            ></sitting-team>
            <sitting-menu
                :currentMenu="currentMenu"
                :eventInfo="eventDetails"
                :teamsInfo="eventTeams"
                @tableSelected="chooseTable"
            ></sitting-menu>
            <sitting-team
                v-for="team in listTwo"
                :key="team.id"
                :teamDetails="team"
                :currentMenu="currentMenu"
                @sittingEvent="updateParent"
            ></sitting-team>
        </div>
    </div>
</template>

<script>
import PlaceDetails from './PlaceDetails';
import SittingTeam from './SittingTeam';
import SittingMenu from './SittingMenu';
export default {
    name: 'SittingModule',
    components: { PlaceDetails, SittingTeam, SittingMenu },
    data() {
        return {
            eventDetails: {},
            eventTeams: [],
            currentMenu: 'Общая информация',
            eventLoaded: false,
            teamsLoaded: false,
            selectedTeam: {},
            selectedTable: {}
        }
    },
    computed: {
        isLoaded () {
            if (this.eventLoaded && this.teamsLoaded) {
                return true
            } else {
                return false
            }
        },
        listOne () {
            return this.eventTeams.filter(team => team.is_in_game === true)
        },
        listTwo () {
            return this.eventTeams.filter(team => team.is_in_game === false)
        },
        eventDateTime: function () {
            let date = new Date(this.eventDetails.date)
            let dateWithTime = new Intl.DateTimeFormat('ru-RU', {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
            })
            return dateWithTime.format(date)
        },
    },
    methods:{
        updateParent(isInGame, teamId) {
            const team = this.eventTeams.find(team => team.id == teamId)
            if (this.currentMenu == 'Гибкая рассадка') {

                team.is_in_game = isInGame

                if (isInGame == true) {
                    this.eventDetails.playersInGame += team.players.length
                    this.eventDetails.availableSeats -= team.players.length
                } else {
                    this.eventDetails.playersInGame -= team.players.length
                    this.eventDetails.availableSeats += team.players.length
                }

            } else if (this.currentMenu == 'С посадочными решениями' || this.currentMenu == 'С картосхемой') {

                for (let i in this.eventTeams) {
                    this.eventTeams[i].isSelected = false
                }

                team.isSelected = true
                this.selectedTeam = team

                if (this.selectedTeam.id != undefined && this.selectedTable.id != undefined && isInGame == true) {
                    this.sitSelectedTeam()
                } else if (isInGame == false) {
                    this.unsitSelectedTeam()
                }
            }
        },
        chooseTable(tableId) {
            const table = this.eventDetails.place.seats.find(table => table.id == tableId)
            const tablesArray = this.eventDetails.place.seats

            for (let i in tablesArray) {
                tablesArray[i].isSelected = false
            }

            table.isSelected = true
            this.selectedTable = table

            if (this.selectedTeam.id != undefined && this.selectedTable.id != undefined) {
                    this.sitSelectedTeam()
                }
        },
        sitSelectedTeam() {
            const tablesArray = this.eventDetails.place.seats
            const table = tablesArray.find(table => table.id == this.selectedTable.id)
            const team = this.eventTeams.find(team => team.id == this.selectedTeam.id)

                    if (team.occupied_furniture.id != undefined) {
                        const previousTable = tablesArray.find(table => table.id == team.occupied_furniture.id)
                        previousTable.free += 1
                    }

                    table.free -= 1

                    team['occupied_furniture'] = table
                    team.is_in_game = true
                    team.isSelected = false
                    table.isSelected = false

                    this.selectedTable = {}
                    this.selectedTeam = {}

                    this.updateSeats(team)
        },
        unsitSelectedTeam() {
            const tablesArray = this.eventDetails.place.seats
            const team = this.eventTeams.find(team => team.id == this.selectedTeam.id)

                    if (team.occupied_furniture.id != undefined) {
                        const previousTable = tablesArray.find(table => table.id == team.occupied_furniture.id)
                        previousTable.free += 1
                    }

                    if (this.selectedTable.id != undefined) {
                        const table = tablesArray.find(table => table.id == this.selectedTable.id)
                        table.isSelected = false
                    }

                    team['occupied_furniture'] = {}
                    team.is_in_game = false
                    team.isSelected = false

                    this.selectedTable = {}
                    this.selectedTeam = {}

                    this.updateSeats(team)
        },
        updateSeats(team) {
            if (team.is_in_game == true) {
                    this.eventDetails.playersInGame += team.players.length
                    this.eventDetails.availableSeats -= team.players.length
                } else {
                    this.eventDetails.playersInGame -= team.players.length
                    this.eventDetails.availableSeats += team.players.length
                }
        },
        loadData(){
            let vm = this
            this.axios.get(`/api/event/${vm.$route.params.eventId}`,
            {headers:{
                'Authorization': `Token ${vm.$store.state.user.authToken}`
              }
            })
            .then(function(response) {
                vm.eventDetails = response.data
                
                const tableArray = vm.eventDetails.place.seats
                let tableCount = 0
                for (let i in tableArray) {
                    vm.formatSelective(tableArray[i])
                    tableCount += tableArray[i].free
                }
                vm.eventDetails.place['tableCount'] = tableCount
                vm.eventDetails['availableSeats'] = vm.eventDetails.place.capacity
                vm.eventDetails['legionersCount'] = 0
                vm.eventDetails['playersInGame'] = 0
                vm.eventDetails['participantsCount'] = 0
                vm.eventLoaded = true
            })
            this.axios.get(`/api/event/${vm.$route.params.eventId}/team`,
            {headers:{
                'Authorization': `Token ${vm.$store.state.user.authToken}`
              }
            })
            .then(function(response) {
                vm.eventTeams = response.data
                
                for (let i in vm.eventTeams) {
                    vm.formatSelective(vm.eventTeams[i])
                    vm.eventDetails['participantsCount'] += vm.eventTeams[i].players.length
                }
                vm.teamsLoaded = true
            })
        },
        formatSelective(obj) {
            obj['isSelected'] = false
        }
    },
    mounted() {
        this.loadData()
        document.getElementById('footer').style.display = 'none'
    },
    beforeUnmount() {
        document.getElementById('footer').style.display = 'block'
    },
}

</script>

<style scoped>
.editField {
    opacity: 0.6;
    border: 1px dashed #FFFFFF;
    box-sizing: border-box;
    border-radius: 20px;
    display: flex;
    align-items: center;
    padding: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
}
.plusContainer {
    width: 32px;
    height: 32px;
    margin-right: 1rem;
    border: 2px solid white;
    border-radius: 0.7rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    padding: 0.7rem;
}
.menuText {
    font-weight: 500;
    font-size: 14px;
    line-height: 143%;
    opacity: 0.6;
}
.sittingMenuContainer {
    position: relative;
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.sittingMenu {
    display: flex;
    overflow-x: scroll;
}
.sittingMenuItem {
    white-space: nowrap;
    padding: 1rem;
    background-color: #2C2D73;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    cursor: pointer;
}
.sittingMenuItem:hover,
.active {
    border-bottom: 1px solid #E32289;
}
.active .menuText {
    opacity: 1;
}
.iconImg {
  width: 18px;
  height: 18px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-right: 0.5rem;
}
.calendarIcon {
  background-image: url(../../assets/media/calendar.svg);
}
.clockIcon {
  background-image: url(../../assets/media/alarm.svg);
  margin-left: 1rem;
}
.pageHeader {
    font-weight: 700;
    font-size: 26px;
    line-height: 32px;
    text-align: center;
}
.eventDetailsContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 1rem;
    padding-right: 1rem;
}
.eventImg {
    width: 318px;
    height: 221px;
    box-shadow: 0px 5px 24px -12px rgba(103, 15, 118, 0.87);
    border-radius: 12px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 2rem;
    margin-bottom: 2rem;
}
.detailsTexts {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.eventHeader {
    font-weight: 600;
    font-size: 18px;
    line-height: 130%;
    margin-bottom: 1rem;
}
.detailsP {
    font-size: 14px;
    line-height: 20px;
}

/* sm-устройства (больше или равно 576px) */
@media (min-width: 576px) {
  /* CSS для: 576px <= ширины <= 767px */
    .pageHeader,
    .eventImg {
        font-size: 40px;
        line-height: 52px;
    }
}

/* md-устройства (больше или равно 768px) */
@media (min-width: 768px) {
  /* CSS для: 768px <= ширины <= 991px */
    .eventDetailsContainer {
        flex-direction: row;
    }
    .detailsTexts {
        margin-left: 1rem;
    }
    .eventImg {
        width: 151px;
        height: 105px;
    }

}

/* lg-устройства (больше или равно 992px) */
@media (min-width: 992px) {
  /* CSS для: 992px <= ширины <= 1119px */
}

/* xl-устройства (больше или равно 1200px) */
@media (min-width: 1200px) {
    .moduleContainer {
        max-width: 1440px;
        margin-left: auto;
        margin-right: auto;
    }
}
</style>