import MyButton from "./MyButton";
import ErrorModal from "./ErrorModal";
import MyModal from "./MyModal";
import ConfirmModal from "./ConfirmModal";
import PreLoader from "./PreLoader";

export default [
    MyButton,
    ErrorModal,
    MyModal,
    ConfirmModal,
    PreLoader
]