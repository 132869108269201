<template>
  <div class="row justify-content-center">
    <transition name="fade">
    <div v-if="isLoaded && !registrationIsOpen" class="mt-3 mb-3 text-center">
      Регистрация не доступна ... 😥
    </div>
    <div v-else-if="isLoaded && registrationIsOpen" class="col-12 mt-3 regOnEventWrap">
      <div v-if="showEventDetail">
        <h5 class="">Регистрация на игру</h5>
        <h1 class="mt-3" v-html="event.game.theme.title +' '+ event.postfix "></h1>
        <h2 v-if="event.place">
          <img class="icon" src="../../assets/media/place.svg" style="height: 20px"/>
          {{ event.place.title }}</h2>
        <div class="eventDate mb-3">
          <img class="icon" src="../../assets/media/calendar_white.svg" />
          {{glb.getDate(event.date)}}
          <img class="icon" src="../../assets/media/alarm_white.svg" />
          {{glb.getTime(event.date)}}
        </div>
      </div>
      <vue-select
        class="mb-4"
        v-if="event.united && event.unitedEvents"
        label="Место"
        :options="getUnitedEventsOptions()"
        v-model="selectedEvent"
        required
        :error="selectedEventError"
      />
      <my-button v-if="!showCreateForm" class="w-100" @click="openCreateTeamModal">
        Создать команду
      </my-button>
      <div v-if="showCreateForm">
        <p class="formSubtitle">
          Введите название новой команды в поле ниже
        </p>
        <MyInput
            ref="inputTeamTitle"
            v-model="titleOfNewTeam"
            label="Название команды"
        ></MyInput>
        <my-button @keyup.enter="createNewTeam" @click="createNewTeam" class="mb-2" :style="{width:'100%'}">Создать команду</my-button>
      </div>

<!--      <h6 class="text-center text-dark">{{ glb.getDate(event.date) }} в {{ glb.getTime(event.date) }}</h6>-->
      <div v-if="$store.state.user.teams.length" class="subTitleWrap">
        <div class="line"></div><h4 class="text-center">Или выберите из списка</h4><div class="line"></div>
      </div>

        <my-button
            v-for="team in $store.state.user.teams.filter(t=>!t.is_united)"
            :key="team.id"
            class="mb-2 hollow"
            :style="{width: '100%'}"
            @click="goToRegPage(team.id)" >
          {{ team.title }}
        </my-button>
      <div class="subTitleWrap">
        <div class="line"></div><h4 class="text-center">Если нет команды</h4><div class="line"></div>
      </div>
        <my-button @click="regOnUnitedTeam" class="mb-2" :style="{width: '100%'}">
          Играть в сборной
        </my-button>
      <my-button
          v-if="$route.name==='home'"
          @click="$emit('close')"
          class="mb-2 blue"
          :style="{width: '100%'}">
        Назад
      </my-button>
      <confirm-modal ref="confirmDialogue"></confirm-modal>
    </div>
      <pre-loader v-else/>
    </transition>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import MyInput from "../UI/MyInput";
import {getUnitedEventPlacesForSelect} from "@/utils/event";
import VueSelect from "@/components/UI/VueSelect.vue";
import {useToast} from "vue-toastification";
import PreLoader from "@/components/UI/PreLoader.vue";
import ConfirmModal from "@/components/UI/ConfirmModal.vue";
import {handleError} from "@/utils/error";

export default {
  name: "RegOnEvent",
  components: {ConfirmModal, PreLoader, VueSelect, MyInput},
  props: {
    eventProp:{
      type: Object
    },
    eventId: {
      type: Number,
    },
    showEventDetail: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedEvent: null,
      selectedEventError: null,
      titleOfNewTeam:'',
      showCreateForm: false,
      event: {},
      isLoaded: false,
    }
  },
  setup(){
    const toast = useToast()
    return {toast}
  },
  created: function () {
    const vm = this;
    //получение данных о команде и игре
    if (this.$store.state.user.authToken) {
      vm.loadData();
    } else {
      vm.$router.push({name: 'login', query: {next: vm.$route.path}})
      // vm.SHOW_AUTH_MODAL(true)
    }
  },
  computed: {
    eventDateTime(){
      let date = new Date(this.event.date)
      let dateWithTime = new Intl.DateTimeFormat('ru-RU', {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      })
      return dateWithTime.format(date)
    },
    registrationIsOpen(){
      return ['published', 'sitting'].includes(this.event.workflow_state)
    }
  },
  methods: {
    ...mapActions(['SHOW_AUTH_MODAL']),
    loadData: function () {
      const vm = this;
      if (this.eventProp){
        this.event = this.eventProp
        this.isLoaded = true
      } else {
        this.$api.getEventDetails(this.eventId || vm.$route.params.eventId)
            .then(data=>{
              vm.event = data;
              vm.isLoaded = true;
            })
            .catch(function (error) {
              console.log(error);
              vm.$toast.error(error);
            });
      }
    },
    regOnUnitedTeam(){
      const vm = this
      if (!this.validatePlace()) return
      vm.$api.register_on_event_on_united_team(vm.selectedEvent || vm.event.id)
      .then(data=>{
        vm.goToRegPage(data.event_team.team)
      })
    },
    createNewTeam: async function () {
      let vm = this;
      this.$api.createTeam({title: vm.titleOfNewTeam})
          .then(data=> {
            vm.goToRegPage(data.id)
          })
          .catch(function (error){
            console.log(error);
            if (error.response.status === 409){
              vm.confirmCaptain(error.response.data.info, error.response.data.team_id);
            } else if (error.response.data.error !== undefined){
              error.response.data.error.title ? vm.$toast.error(error.response.data.error.title) : {};
            } else {
              vm.$toast.error('что-то пошло не так :(');
            }
          });
    },
    async confirmCaptain(message, teamId) {
      let vm = this
      const ok = await this.$refs.confirmDialogue.show({
        message: message,
      })
      if (ok) {
        this.$api.patchTeam(
            teamId,
            {
              captain: this.$store.state.user.id,
              city: this.$store.state.user.city,
            }
        )
            .then(data=>{
              vm.goToRegPage(data.id)
            })
            .catch(err=>{
              handleError(err)
            })
      } else {
        console.log('cancel');
      }
    },
    goToRegPage(teamId){
      if (!this.validatePlace()) return
      this.$router.push({
        name: 'RegTeamOnEvent',
        params: {
          eventId: this.selectedEvent || this.eventId || this.$route.params.eventId,
          teamId: teamId
        },
        query: {set_check: 'true'}
      })
    },
    getUnitedEventsOptions(){
      return getUnitedEventPlacesForSelect(this.event)
    },
    validatePlace(){
      if (this.event.united && this.event.unitedEvents && !this.selectedEvent){
        this.selectedEventError = 'выберите место !!!!'
        this.toast.error('выберите место')
        return false
      }
      return true
    },
    openCreateTeamModal(){
      if (!this.validatePlace()) return
      this.showCreateForm=!this.showCreateForm
    },
  },
}
</script>

<style scoped>
.new__team {
  font-size: 20px;
  display: block;
  margin: 0 auto;
  width: 244px;
  background: linear-gradient(90deg, #6ED0A6 0%, #0D8451 100%);
}
.window {
  margin: auto;

}
.icon {
  margin-bottom: 4px;
}
.eventDate {
  font-size: 14px;
  line-height: 20px;
  color: #fff;
}
h1 {
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
}
h2 {
  margin-top: 12px;
  margin-bottom: 12px!important;
}
.new__team:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  text-decoration: none;
  opacity: 0;
  transition: opacity 0.5s;
  border-color: transparent;
  background: linear-gradient(90deg, #0D8451 100%, #6ED0A6 0%);
}
h2 {
  font-size: 14px;
  line-height: 20px;
}
h4 {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;
  letter-spacing: 0.2em;
  margin: 30px 10px;
  display: block;
  min-width: max-content;
}
.subTitleWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.line {
  height: 1px;
  width: -webkit-fill-available;
  background-color: #ffffff;
  opacity: .2;
}
.regOnEventWrap{
  max-width: 600px;
}
h5 {
  font-size: 10px;
  line-height: 24px;
  letter-spacing: 0.165em;
  text-transform: uppercase;
  opacity: 0.6;
}

/* lg-устройства (больше или равно 992px) */
@media (min-width: 992px) {
  /* CSS для: 992px <= ширины <= 1119px */
  .window {
    max-width: 40%;
  }
}

</style>
