<template>
<div class="homeContainer">
  <div class="homeHeader">
    <div class="homeImg bg-transparent">
      <div class="homeTextDiv d-flex justify-content-center align-items-center">
        <div class="d-none d-xl-flex flex-column justify-content-center align-items-center socialIconDiv">
<!--          <img class="m-4" src="../assets/media/soc_icon_01_facebook.svg" />-->
<!--          <img class="m-4" src="../assets/media/soc_icon_02_instagram.svg" />-->
<!--          <img class="m-4" src="../assets/media/soc_icon_03_vk.svg" />-->
<!--          <img class="m-4" src="../assets/media/soc_icon_04_instagram.svg" />-->
        </div>
        <div class="text-center text-xl-start d-flex flex-column align-items-center align-items-xl-start">
          <h1 class="weH1">МЫ!</h1>
          <h2 class="weH2">проводим</h2>
          <p class="weP py-4">Тематические интеллектуально — развлекательные, командные игры.</p>
          <my-button
            v-if="!$store.getters.isAuthenticated"
            @click="scrollMeTo('events')"
            class="mt-2" :icon="require('../assets/media/long_right.svg')"
            with-margin>
          Регистрируйся на игру</my-button>
        </div>
      </div>
    </div>
  </div>
  <div class="howDiv w-100 text-center">
    <div class="seperator"></div>
      <h2 class="weH2 mb-3 pt-5 pb-xl-5">Что у нас?</h2>
      <div class="d-flex flex-column flex-xl-row align-items-center justify-content-center">
        <div class="weGotCard">
          <img class="weCardIcon" src="../assets/media/check_yellow.svg" />
          <p>Драйв, музыка и соревновательная борьба </p>
        </div>
        <div class="weGotCard">
          <img class="weCardIcon" src="../assets/media/check_bold.svg" />
          <p>Совмещение вкусного ужина и приятной игры</p>
          <img class="lights d-none d-md-block" src="../assets/media/6light.svg" alt="" />
          <img class="lights1 d-none d-md-block" src="../assets/media/6light.svg" alt="" />
        </div>
        <div class="weGotCard">
          <img class="weCardIcon" src="../assets/media/check_bold.svg" />
          <p>Отличный повод встретиться с друзьями</p>
          <img class="lights2 d-none d-md-block" src="../assets/media/7light.svg" alt="" />
        </div>
      </div>
      <div class="seperator seperatorMargin"></div>
  </div>
    <div class="text-center" id="events">
      <h2 class="weH2 mb-3 py-xl-5" ref="events">Список наших игр</h2>
    </div>
  <transition name="fade">
    <pre-loader v-if="!themes.length && !events.length"/>
    <div v-else class="home">
      <div class="gameListDiv">
        <div class="row m-0">
          <div class="col px-2">
            <transition name="fade">
              <div v-if="themes.length>0 || events.length>0" class="gamesList">
                <TransitionGroup name="cardsAnimation">
                  <event-card
                      :id="`event${event.id}`"
                      v-for="event in events"
                      :key="event.id"
                      :game-event="event"
                      @show-auth-modal="showAuthModal"
                  ></event-card>
                  <theme-card
                      v-for="theme in themes.slice(themesStart, themesEnd)"
                      :key="theme.id"
                      :theme="theme"
                      @set-like="setLike"
                      @show-auth-modal="showAuthModal">
                  </theme-card>
                </TransitionGroup>
                <my-button v-if="showButtonMore" @click="showMore" class="mx-auto my-5">Показать ещё</my-button>
              </div>
            </transition>
          </div>
        </div>
      </div>
      <div class="comfDiv position-relative">
          <div class="comfContainer position-relative">
            <img class="bottomLights d-none d-md-block" src="../assets/media/6light.svg" alt="" :style="{transform: 'rotate(' + rotateAngle+ 'deg)'}" />
            <div class="d-flex position-relative comfTimeContainer">
            <comf-time class="container-fluid"></comf-time>
            <img class="redHeadBig d-none d-xl-block" src="../assets/media/girl1.png" />
            </div>
<!--            <div class="position-relative">-->
<!--              <div class="position-relative">-->
<!--                <img class="redHead d-xl-none" src="../assets/media/girl1.png" />-->
<!--              </div>-->
<!--              <div class="promotionDiv d-xl-none">-->
<!--                <p class="promotionHeader mb-3">Узнали кого-то на фото?</p>-->
<!--                <p>Возможно Ваши друзья или<br> знакомые уже с нами, ищи<br> их в нашем инстаграме <br><a class="instaLink">@insta_adress</a></p>-->
<!--                <div class="d-flex btnsDiv">-->
<!--                  <my-button class="rounded-circle arrowsBtn left" cls='hollow' :icon="require('../assets/media/long_right.svg')"></my-button>-->
<!--                  <my-button class="rounded-circle arrowsBtn ms-3" cls='hollow' :icon="require('../assets/media/long_right.svg')"></my-button>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
        </div>
      </div>
<!--      <div class="promotionDiv d-none d-xl-block promotionBig">-->
<!--        <div class="promotionContainer d-flex justify-content-between mx-auto">-->
<!--          <div class="mb-5 pb-5">-->
<!--            <p class="promotionHeader mb-3">Узнали кого-то на фото?</p>-->
<!--            <p>Возможно Ваши друзья или знакомые уже с нами, ищи их в нашем инстаграме <a class="instaLink">@insta_adress</a></p>-->
<!--          </div>-->
<!--          <div class="d-flex btnsDiv lgBtnsDiv">-->
<!--            <my-button class="rounded-circle arrowsBtn left" cls='hollow' :icon="require('../assets/media/long_right.svg')"></my-button>-->
<!--            <my-button class="rounded-circle arrowsBtn ms-3" cls='hollow' :icon="require('../assets/media/long_right.svg')"></my-button>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="position-relative">-->
<!--        <div class="d-flex galleryDiv position-absolute">-->
<!--          <img src="../assets/media/people1.png" alt="" />-->
<!--          <img src="../assets/media/people2.png" alt="" />-->
<!--          <img src="../assets/media/people3.png" alt="" />-->
<!--          <img src="../assets/media/people4.png" alt="" />-->
<!--          <img src="../assets/media/people1.png" alt="" />-->
<!--          <img src="../assets/media/people2.png" alt="" />-->
<!--          <img src="../assets/media/people3.png" alt="" />-->
<!--          <img src="../assets/media/people4.png" alt="" />-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </transition>
  <scroll-up></scroll-up>
  <auth-modal ref="auth"></auth-modal>
</div>
</template>

<script>

import EventCard from "../components/main/EventCard";
import ThemeCard from "../components/main/ThemeCard";
import ComfTime from "../components/main/ComfTime";
import {mapActions, mapState} from "vuex";
import PreLoader from "../components/UI/PreLoader";
import AuthModal from "../components/AuthModal";
import MyButton from '../components/UI/MyButton.vue';
import ScrollUp from '../components/UI/ScrollUp.vue';

export default {
  name: 'Home',
  components: { AuthModal, PreLoader, EventCard, ThemeCard, ComfTime, MyButton, ScrollUp},
  data:() =>({
    rotateAngle: '15',
      common: {},
      events: [],
      themes: [],
      cities: [],
      themesStart: 0,
      themesEnd: 3,
      eventImgs: [
        require('@/assets/media/people1.png'),
        require('@/assets/media/people2.png'),
        require('@/assets/media/people3.png'),
        require('@/assets/media/people4.png'),
      ],
    showButtonMore: true
  }),
  mounted() {
    if (this.$store.state.user.authToken) {
      this.GET_USER_DETAILS();
    }
    this.loadData();
    window.addEventListener('scroll', this.handleScroll);

  },
  computed: {
    ...mapState(['isAuthenticated']),
    rotationStyle() {
      return { transform: 'rotate(' + this.rotateAngle + 'deg)'}
    }
  },
  watch: {
    isAuthenticated() {
      this.processData({events: this.events, themes: this.themes});
    },
  },
  methods: {
    ...mapActions(['GET_USER_DETAILS']),
    loadData: function (){
      const vm = this;
      vm.axios.get(
          `/api/common`,
          )
          .then(function (response) {
            vm.common = response.data
          })
          .catch(function (error) {
            vm.$toast.error(error);
          });
      vm.axios.get(
          `/api/landing_feed`,
          )
          .then(function (response) {
            vm.processData(response.data);
            if (vm.$route.query.regonevent){
              vm.scrollToEventCard(vm.$route.query.regonevent)
            }
          })
          .catch(function (error) {
            vm.$toast.error(error);
          });
      vm.axios.get(
          `/api/cities_list`,
      )
          .then(function (response) {
            vm.cities = response.data;
          })
          .catch(function (error) {
            console.error(error)
            vm.$toast.error(error);
          });
    },
    processData: function (data){
      let events = [];
      let themes = [];
      for (let event of data.events) {
        if (this.$route.query.regonevent == event.id){
          event.openRegistraion = true
        }
        event.game.isLiked = false;
        event.isRegistered = false;
        if (this.isAuthenticated) {
          if (this.$store.state.user.like.indexOf(event.game.id) !== -1) {
            event.game.isLiked = true;
          }
        }
        for (let ep of this.$store.state.user.event_players) {
          if (ep.event_team.event === event.id) {
            event.isRegistered = true;
          }
        }
        events.push(event)
      }
      for (let theme of data.themes) {
        theme.isLiked = false;
        if (this.$store.state.user.authToken) {
          if (this.$store.state.user.like.indexOf(theme.id) !== -1) {
            theme.isLiked = true;
          }
        }
        themes.push(theme)
      }
      this.events = events
      this.themes = data.themes
    },
    setLike(themeId, status) {
      // this.themes.forEach(theme, i, this.themes) {}
      this.themes = this.themes.map(theme => theme.id === themeId ? Object.assign({}, theme, {isLiked:status}) : theme)
    },
    showAuthModal() {
      this.$refs.auth.showAuthModal();
    },
    showMore() {
      this.themesEnd = this.themesEnd + 3;
      if (this.themes.length === this.themesEnd) {
        this.showButtonMore = false
      }
    },
    handleScroll: function(event) {
      if (event.path && event.path[1]){
        this.rotateAngle = event.path[1].pageYOffset
      }
    },
    scrollToEventCard(eventId){
      setTimeout(()=>{
        document.getElementById(`event${eventId}`)
      })
    },
    scrollMeTo(refName) {
      let element = this.$refs[refName];
      let top = element.offsetTop;
      window.scrollTo(0, top);
    },
  },
  unmounted () {
    window.removeEventListener('scroll', this.handleScroll);
  },
}
</script>

<style scoped>
.pushForward {
  position: relative;
  z-index: 2;
}
.promotionContainer {
  max-width: 70rem;
}
.comfContainer {
  max-width: 28rem;
  margin-left: auto;
  margin-right: auto;
}
.bottomLights {
  position: absolute;
  top: -23rem;
  right: -20rem;
  z-index: 1;
}
.lights {
  position: absolute;
  top: -2.3rem;
  right: -2.7rem;
  z-index: 1;
}
.lights1 {
  position: absolute;
  bottom: -2.3rem;
  left: -2.7rem;
  z-index: 1;
}
.lights2 {
  position: absolute;
  top: -1rem;
  left: -6rem;
  z-index: 1;
}
.left {
  transform: rotate(180deg);
}
.seperator {
  border-top: 1px solid white;
  opacity: 0.1;
  width: 75%;
  height: 1px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10%;
}
.seperator{
  border-top: 1px solid white;
  opacity: 0.1;
  width: 75%;
  height: 1px;
  margin: 5rem auto;
}
.gameListDiv {
  background-color: transparent;
  z-index: 10;
  position: relative;
}
.gamesList {
  padding: 0;
  background-color: transparent;
}
.homeContainer {
  margin-top: -7rem;
  z-index: 0;
}
.homeHeader {
  background-image: url("../assets/media/homeBg.svg");
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
}
.homeImg {
  background-image: url("../assets/media/homeImg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-position-y: 1rem;
  height: 150%;
}
.homeTextDiv {
  padding-top: 95%;
  margin-top: 5%;
}
.weH1 {
  font-size: 3.75rem;
  line-height: 3.75rem;
  font-weight: bold;
}
.weH2 {
  font-size: 2.125rem;
  line-height: 100%;
  font-weight: bold;
  text-transform: uppercase;
}
.weP {
  font-size: 1rem;
  line-height: 1.375rem;
  padding-left: 2rem;
  padding-right: 2rem;
}
.weGotCard {
  background: linear-gradient(180deg, rgba(227, 34, 137, 0.05) 0%, rgba(255, 255, 255, 0.05) 100%), #512B77;
  box-shadow: 0px 18px 59px #2C2D73;
  border-radius: 22px;
  padding: 1rem;
  max-width: 20rem;
  height: 20rem;
  margin: 0.5rem;
  font-size: 1.5rem;
  font-weight: 600;
  position: relative;
}
.howDiv {
  background-image: url('../assets/media/aboutBg.svg');
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  padding-top: 15%;
}
.cardsAnimation-enter-active,
.cardsAnimation-leave-active {
  transition: all 0.5s ease;
}
.cardsAnimation-enter-from,
.cardsAnimation-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
.socialIconDiv {
  margin-right: 16%;
  opacity: 0.6;
}
.weCardIcon {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.promotionDiv {
  padding-right: 1.25rem;
  padding-bottom: 9.5rem;
  padding-top: 4rem;
  padding-left: 1.25rem;
}
.promotionHeader {
  font-size: 1.25rem;
  font-weight: 500;
}
.redHead {
  position: absolute;
  z-index: 1;
  top: 5rem;
  right: -4rem;
  max-width: 20rem;
}
.arrowsBtn,
.arrowsBtnLeft {
  height: 3rem;
  width: 3rem;
  padding: 0;
}
.arrowsBtnLeft {
  transform: rotate(180deg);
}
.btnsDiv {
  padding-top: 6rem;
}
.galleryDiv {
  z-index: 2;
  left: -1rem;
  bottom: 0rem;
}
.galleryDiv img {
  width: 10rem;
  height: auto;
}
.instaLink {
  color: #E32289;
  text-decoration: none;
}
.promotionBig {
  background: #3E2C73;
}
.comfDiv {
  background-image: url('../assets/media/aboutBg.svg');
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  padding-top: 10%;
}
.lgBtnsDiv {
  padding-top: 0.5rem;
}
.comfTimeContainer{
  z-index: 10;
}
@media (min-width: 576px) {
  .homeImg {
    max-width: 576px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 768px) {

}
@media (min-width: 1200px) {
  .homeContainer {
    margin-top: -11rem;
    z-index: 0;
  }
  .bottomLights {
    left: -22rem;
    top: -20rem;
  }
  .comfContainer {
    max-width: 70rem;
  }
  .promotionDiv {
    padding-bottom: 11.5rem;
  }
  .lights {
    position: absolute;
    top: -2.8rem;
    right: -2.3rem;
    z-index: 1;
  }
  .lights1 {
    position: absolute;
    bottom: -2.8rem;
    left: -2.3rem;
    z-index: 1;
  }
  .seperatorL {
    width: 100%;
  }
  .homeHeader {
    height: 40rem;
    background-size: cover;
    background-position: bottom right;
    width: 100%;
  }
  .homeImg {
    position: absolute;
    background-position: right 0 top 10px;
    background-size: 50rem 50rem;
    height: 50rem;
    max-width: 95rem;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  .homeTextDiv {
    padding-top: 15rem;
    padding-right: 46rem;
    padding-left: 5rem;
  }
  .weH1 {
    font-size: 5rem;
    line-height: 5rem;
  }
  .weH2 {
    font-size: 3rem;
    line-height: 2rem;
  }
  .weP {
    font-size: 1.5rem;
    line-height: 133.5%;
    padding-left: 0;
    padding-right: 0;
  }
  .galleryDiv img {
    width: 100%;
    height: 100%;
  }
}
</style>
