import vueConfig from '../vue.config'

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}
function replaceAll(str, match, replacement){
   return str.replace(new RegExp(escapeRegExp(match), 'g'), ()=>replacement);
}

export const global_objects = {
    domain: () =>  window.location.host.split('.')[window.location.host.split('.').length-2]+'.'+window.location.host.split('.')[window.location.host.split('.').length-1],
    getMediaUrl: (path) => {
        return (process.env.NODE_ENV === 'development') ? vueConfig.devServer.proxy+path : path;
    },
    formatPhone: (phone) => {
        phone = replaceAll(phone,'-', '');
        phone = replaceAll(phone,'(', '');
        phone = replaceAll(phone,')', '');
        phone = replaceAll(phone,'+', '');
        phone = replaceAll(phone,' ', '');

        if (phone.slice(0, 2) === '89') {
            phone = '79' + phone.slice(2)
        }
        return phone
    },
    getCookie: (cookie_name) => {
        let results = document.cookie.match('(^|;) ?' + cookie_name + '=([^;]*)(;|$)');
        if (results)
            return (unescape(results[2]));
        else
            return null;
    },
    setCookie: (name, value, options = {}) => {
        if (options.expires instanceof Date) {
            options.expires = options.expires.toUTCString();
        }
        let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

        for (let optionKey in options) {
            updatedCookie += "; " + optionKey;
            let optionValue = options[optionKey];
            if (optionValue !== true) {
                updatedCookie += "=" + optionValue;
            }
        }
        document.cookie = updatedCookie;
    },
    getFullDate: (datetimestring) => {
        let date = new Date(datetimestring)
        let dateFormatter = new Intl.DateTimeFormat('ru-RU', {
            year: "numeric",
            month: "long",
            day: "numeric"
        })
        return dateFormatter.format(date)
    },
    getDay: (datetimestring) => {
        let date = new Date(datetimestring)
        let dateFormatter = new Intl.DateTimeFormat('ru-RU', {
            day: "numeric"
        })
        return dateFormatter.format(date)
    },
    getMonth: (datetimestring) => {
        let date = new Date(datetimestring)
        let dateFormatter = new Intl.DateTimeFormat('ru-RU', {
            month: "long",
        })
        return dateFormatter.format(date)
    },
    getDate: (datetimestring) => {
        let date = new Date(datetimestring)
        let dateFormatter = new Intl.DateTimeFormat('ru-RU', {
            month: "long",
            day: "numeric"
        })
        return dateFormatter.format(date)
    },
    getTime: (datetimestring) => {
        let date = new Date(datetimestring)
        let timeFormatter = new Intl.DateTimeFormat('ru-RU', {
            hour: "numeric",
            minute: "numeric",
        })
        return timeFormatter.format(date)
    },
    phoneInputFormatter: ()=> {
        let phoneInputs = document.querySelectorAll('input[data-tel-input]');
        let getInputNumbersValue = function (input) {
            // Return stripped input value — just numbers
            return input.value.replace(/\D/g, '');
        }

        let onPhonePaste = function (e) {
            let input = e.target,
                inputNumbersValue = getInputNumbersValue(input);
            let pasted = e.clipboardData || window.clipboardData;
            if (pasted) {
                let pastedText = pasted.getData('Text');
                if (/\D/g.test(pastedText)) {
                    // Attempt to paste non-numeric symbol — remove all non-numeric symbols,
                    // formatting will be in onPhoneInput handler
                    input.value = inputNumbersValue;
                    return;
                }
            }
        }

        let onPhoneInput = function (e) {
            let input = e.target,
                inputNumbersValue = getInputNumbersValue(input),
                selectionStart = input.selectionStart,
                formattedInputValue = "";

            if (!inputNumbersValue) {
                return input.value = "";
            }

            if (input.value.length != selectionStart) {
                // Editing in the middle of input, not last symbol
                if (e.data && /\D/g.test(e.data)) {
                    // Attempt to input non-numeric symbol
                    input.value = inputNumbersValue;
                }
                return;
            }

            if (["7", "8"].indexOf(inputNumbersValue[0]) > -1) {
                // Russia formatter
                // if (inputNumbersValue[0] == "9") inputNumbersValue = "7" + inputNumbersValue;
                let firstSymbols = (inputNumbersValue[0] == "8") ? "8" : "+7";
                formattedInputValue = input.value = firstSymbols + " ";
                if (inputNumbersValue.length > 1) {
                    formattedInputValue += '(' + inputNumbersValue.substring(1, 4);
                }
                if (inputNumbersValue.length >= 5) {
                    formattedInputValue += ') ' + inputNumbersValue.substring(4, 7);
                }
                if (inputNumbersValue.length >= 8) {
                    formattedInputValue += '-' + inputNumbersValue.substring(7, 9);
                }
                if (inputNumbersValue.length >= 10) {
                    formattedInputValue += '-' + inputNumbersValue.substring(9, 11);
                }
            } else if(inputNumbersValue.slice(0,2)==="90"){
                // Turkey formatter
                formattedInputValue = input.value = "+90"
                if (inputNumbersValue.length > 2) {
                    formattedInputValue += ' ' + inputNumbersValue.substring(2, 5);
                }
                if (inputNumbersValue.length >= 6) {
                    formattedInputValue += ' ' + inputNumbersValue.substring(5, 8);
                }
                if (inputNumbersValue.length >= 9) {
                    formattedInputValue += ' ' + inputNumbersValue.substring(8, 10);
                }
                if (inputNumbersValue.length >= 11) {
                    formattedInputValue += ' ' + inputNumbersValue.substring(10, 12);
                }
            } else {
                formattedInputValue = '+' + inputNumbersValue.substring(0, 18);
            }
            input.value = formattedInputValue;
        }
        let onPhoneKeyDown = function (e) {
            // Clear input after remove last symbol
            let inputValue = e.target.value.replace(/\D/g, '');
            if (e.keyCode == 8 && inputValue.length == 1) {
                e.target.value = "";
            }
        }
        for (let phoneInput of phoneInputs) {
            phoneInput.addEventListener('keydown', onPhoneKeyDown);
            phoneInput.addEventListener('input', onPhoneInput, false);
            phoneInput.addEventListener('paste', onPhonePaste, false);
        }
    },
    isMobileBrowser: ()=> {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
    textFit(parent) {
        const matches = parent.querySelectorAll(".text_fit");
        for (let i = 0; i < matches.length; i++) {
            let text = matches[i].innerHTML;
            matches[i].textContent = ''
            matches[i].innerText = ''
            let innerHtml = `<div class="text_fit_element${i}">${text}</div>`;
            matches[i].innerHTML = innerHtml;
            text = parent.querySelector(`div.text_fit_element${i}`) //const text = this.$refs.text
            text.style.minWidth = 'fit-content';
            let count = 0
            text.style.fontSize = count + 'px'
            if (text.offsetHeight < matches[i].offsetHeight) {
                while ( text.offsetHeight < matches[i].offsetHeight && text.offsetWidth <= matches[i].offsetWidth && count < 180) {
                    count = count + 1;
                    text.style.fontSize = count + 'px';
                }
                count = count - 1
                text.style.fontSize = count + 'px'
            }
        }
    }
}

