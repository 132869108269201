<template>
<div>
  <div class="row" style="padding-right: 0;padding-left: 0;">
    <div class="col d-flex justify-content-between align-items-center">
      <div class="navAvatar" :style="{ backgroundImage: 'url('+ player.avatar +')'}"></div>
      <div class="foo foo_name"
           v-if="!activeEdit"
           v-bind:for="player.id"
           style="margin-right: auto;margin-left: 8px">
        <div>
        {{player.first_name}}
        </div>
        <div class="text-secondary">*{{ player.phone.slice(-4) }}</div>
      </div>
      <div v-else class="d-flex w-100">
        <input
             ref="edit"
             type="text"
             v-model="playerFirstName"
             :aria-describedby="player.id + '-addon'"
             class="form-control edit-form"
             onfocus="this.style.width = ((this.value.length + 1) * 10) + 'px';"
             @focusout="saveChanges(player.id)">
      </div>

    </div>
    <div class="col-2 d-flex justify-content-end">
      <MyButton v-show="isCaptain"
                @click="showEdit"
                class="editBtn"
                :icon="require('../../assets/media/edit.svg')"
                edit-small
                cls="noText">
      </MyButton>
      <MyButton v-show="isCaptain && player.id !== $store.state.user.id"
                @click="deletePlayer(player)"
                user-close cls="noText"
                :icon="require('../../assets/media/userClose.svg')">
      </MyButton>
    </div>
    </div>
  </div>
</template>

<script>
import MyButton from "../UI/MyButton";
export default {
  name: "player-in-team",
  components: { MyButton},
  props: {
    team: Object,
    player: Object,
    index: Number,
    isCaptain: Boolean,
    picture: {
      type: String
    },
  },
  data() {
    return {
      playerFirstName: this.player.first_name,
      activeEdit: false,
    }
  },
  methods: {
    deletePlayer() {
      this.$emit('delete-player', this.team.id, this.player.id)
    },
    getPicture(){
      // console.log(this.picture ? this.picture : require('../../assets/media/avatar.svg'));
      return this.picture? this.glb.getMediaUrl(this.picture): require('../../assets/media/coolicon-mail.svg');
    },
    showEdit() {
      this.activeEdit = true;
      console.log(this.$refs.edit);
      this.$nextTick(() => this.$refs.edit.focus());
    },
    saveChanges (playerId) {
      let vm = this;
      this.axios.put(
          `/api/user/${playerId}/name/`,
          {
            first_name: vm.playerFirstName,
          },
          {headers:{
              'Authorization': `Token ${vm.$store.state.user.authToken}`
            }
          })
          .then(function (response) {
            console.log(response)
            vm.activeEdit = false
            vm.$emit('name-changed')
          })
          .catch(function (error){
            vm.vueError(error);
          });
    },
  }
}
</script>

<style scoped>
.bi {
  cursor: pointer;
}
.noText {
  padding: 0rem;
  border: 0.15rem solid #E32289;
  display: flex;
  background-color: transparent;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  width: 30px;
  min-width: 30px;
  height: 30px;
  margin-right: 4px;
}
.edit-span {
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid #ffffff;
  background-position: center;
  margin-right: 8px;
  padding: 0px 4px;
  height: 24px;
}
.edit-form, .edit-form:active, .edit-form:focus, .edit-form:hover {
  display: block;
  width: 100%;
  padding: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  background-color: transparent;
  border: none;
  outline: 0;
  outline-offset: 0;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-left: 8px;
}
/*.navAvatar{*/
/*  background-position: center;*/
/*  background-size: cover;*/
/*  height: 100%;*/
/*  max-height: 40px;*/
/*  width: 100%;*/
/*  max-width: 40px;*/
/*  border-radius: 50%;*/
/*}*/
.navAvatar{
  background-position: center;
  background-size: cover;
  width: 2.5rem;
  height: 2.5rem;
  max-width: 40px;
  border-radius: 50%;
}
.foo_name {
  max-width: calc(56vw - 10px);
}
</style>
