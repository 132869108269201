<template>
  <div class="container" v-if="eventInfo && album">
    <div class="row photoReportHeader flex-column-reverse flex-lg-row">
      <div class="col-12 col-lg-4 p-0">
          <div class="photoReportGameDate">
              <p class="photoReportGameDay">
                {{ glb.getDay(eventInfo.date)}}
              </p>
              <p class="photoReportGameMonth">
                {{ glb.getDate(eventInfo.date).split(' ')[1]}}
              </p>
          </div>
          <div class="photoReportGameInfo">
            <p class="photoReportGameTitle">
              {{eventInfo.game.theme.title}}
            </p>
            <p class="photoReportGamePlace">
              {{ eventInfo.place? eventInfo.place.title:'' }}
            </p>
          </div>
      </div>
      <div class="col-12 col-lg-8 p-0">
        <div class="bigPicture">
          <img v-if="album.cover_photo" :src="album.cover_photo.file_url" alt="" class="w-100 h-100" style="object-fit: cover;height: initial">
        </div>
      </div>
    </div>
    <div class="row photoReportGameItems" style="margin-right: -24px">
        <div
           v-for="p in album.photos"
           :key="p.id"
           :photo="p"
           :event-info="eventInfo"
            class="col-12 col-md-6 col-lg-4 photoReportWrap ps-0"
           style="padding-right: 10px;padding-bottom: 10px"
           @click="selectPhoto(p)"
        >
          <img :src="p.thumbnail_url" alt="" class="w-100" style="object-fit: contain;height: initial;" loading="lazy">
        </div>
<!--      <photo-report-item-->
<!--          v-for="p in album.photos"-->
<!--         :key="p.id"-->
<!--         :photo="p"-->
<!--          :event-info="eventInfo"-->
<!--      ></photo-report-item>-->
    </div>
    <my-modal ref="photoReportModal">
      <photo-report-item
        :event-info="eventInfo"
        :photo="selectedPhoto"
        @next-photo="nextPhoto"
        @prev-photo="prevPhoto"
      />
    </my-modal>
  </div>

</template>

<script>


import MyModal from "@/components/UI/MyModal";
import PhotoReportItem from "../components/photoReport/PhotoReportItem";
export default {
  name: "PhotoReport",
  components: {MyModal, PhotoReportItem},
  data: ()=> {
    return {
      album: null,
      eventInfo: null,
      selectedPhoto: null
    }
},
  mounted() {
    this.loadAlbum()
    this.loadEventInfo()
  },
  methods:{
    loadAlbum(){
      let vm = this
      this.axios.get(`/api/event/${vm.$route.params.eventId}/album`)
       .then(function (response) {
            console.log(response.data);
            vm.album = response.data
          })
          .catch(function (error) {
            console.log(error)
            vm.$toast.error(error)
          })
    },
    loadEventInfo(){
      let vm = this
      this.axios.get(`/api/event/${vm.$route.params.eventId}/`,
      {
            headers: {
              'Authorization': `Token ${vm.$store.state.user.authToken}`
            }
          }
      )
       .then(function (response) {
            console.log(response.data);
            vm.eventInfo = response.data
          })
          .catch(function (error) {
            console.log(error)
            vm.$toast.error(error)
          })
    },
    selectPhoto(photo){
      this.selectedPhoto = photo
      this.$refs.photoReportModal.open()
    },
    nextPhoto(){
      this.selectedPhoto = this.album.photos[this.album.photos.findIndex((el)=>el===this.selectedPhoto)+1]
    },
    prevPhoto(){
      this.selectedPhoto = this.album.photos[this.album.photos.findIndex((el)=>el===this.selectedPhoto)-1]
    }
  }
}
</script>

<style scoped>
.photoReportHeader {
  margin-bottom: 10px
}
.photoReportGameItems {
  /*gap: 10px;*/
  margin-bottom: 10px;
  margin-top: 10px;
}
.photoReportGameDate {
  padding: 16px 0 32px;
  background: #E32289;
}
.photoReportGameDay {
  font-weight: 800;
  font-size: 132px;
  line-height: 100%;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
}
.photoReportGameMonth {
  font-weight: 800;
  font-size: 26px;
  line-height: 100%;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
}
.photoReportGameInfo {
  padding: 30px 0 40px;
  background: #772A74;
}
.photoReportGameTitle {
  font-weight: 700;
  font-size: 68px;
  line-height: 100%;
  text-align: center;
  color: #FFFFFF;
}
.photoReportGamePlace {
  font-weight: 600;
  font-size: 26px;
  line-height: 130%;
  text-align: center;
  color: #FFFFFF;
  margin-top: 40px;
}
.bigPicture {
  height: 320px;
}


@media (min-width: 576px) {

.container {
  max-width: none;
}
}
@media (min-width: 768px) {

}
/* lg-устройства (больше или равно 992px) */
@media (min-width: 992px) {
.photoReportHeader {
  margin-bottom: 20px
}
.photoReportGameInfo {
  padding: 88px 0 134px;
}
.photoReportGameItems {
  /*gap: 20px;*/
  margin-bottom: 20px;
  margin-top: 0;
}
.bigPicture {
  height: 100%;
  max-height: 672px;
}
}

@media (min-width: 1400px) {
.container {
  max-width: 1400px;
}
}

</style>
