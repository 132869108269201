<template>
    <div class="tableContainer">
        <div class="placeContainer">
            <h2 class="placeHeader">Вместимость заведения</h2>
            <div class="tableItem">
                <p>Количество столов</p>
                <p>{{placeInfo.place.tableCount}}</p>
            </div>
            <div class="tableItem">
                <p>Общее количество мест</p>
                <p>{{placeInfo.place.capacity}}</p>
            </div>
            <div class="tableItem">
                <p>Распределено мест</p>
                <p>{{placeInfo.playersInGame}}</p>
            </div>
        </div>
        <div class="placeContainer">
            <h2 class="placeHeader">Зарегистрировалось</h2>
            <div class="tableItem">
                <p>Команд</p>
                <p>{{teamsInfo.length}}</p>
            </div>
            <div class="tableItem">
                <p>Одиночек</p>
                <p>{{placeInfo.legionersCount}}</p>
            </div>
            <div class="tableItem">
                <p>Всего</p>
                <p>{{placeInfo.participantsCount}}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PlaceDetails',
    props: ['placeInfo', 'teamsInfo'],
    methods: {
        handleData() {
            console.log(this.placeInfo)
            console.log(this.teamsInfo)
        }
    },
    mounted() {
        this.handleData()
    }
}
</script>

<style scoped>
.placeContainer {
    margin-bottom: 3rem;
    max-width: 333px;
}
.placeHeader {
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 1rem;
}
.tableContainer {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.tableItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;
    padding-top: 1rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
@media (min-width: 768px) {
    .tableContainer {
        flex-direction: row;
        justify-content: space-between;
        width: 666px;
        margin-left: auto;
        margin-right: auto;
    }
}
</style>