import axios from "axios";
import store from "@/store";
import { useToast } from "vue-toastification";

const toast = useToast()

class LogApi {
    constructor(BASE_URL) {
        this.BASE_URL = BASE_URL
    }

    _request(params) {
        if (!params.method) params.method = 'get'
        if (store.state.user.authToken) {
            params.headers = {'Authorization': `Token ${store.state.user.authToken}`}
        }
        params.baseURL = this.BASE_URL
        return new Promise((resolve, reject) => {
            axios(params)
                .then(res => resolve(res.data))
                .catch(err => {
                    console.error(err)
                    if (err.response) {
                        if (err.response.status === 401) {
                            store.dispatch('LOGOUT')
                            toast.error(err.toString())
                        }
                        // if (err.response.data.detail) toast.error(err.response.data.detail)
                    }
                    reject(err)
                })
        })
    }

    log(log){
        this._request({
            method: 'post',
            url: 'log',
            data: {
                from: window.location.href,
                ...log
            }
        })
    }
}
export default new LogApi('/api/')