<template>
    <button :class="['button', cls]">
      <div v-if="icon_left"
           class="btnIconLeft"
           :style="{'background-image': 'url(' + icon_left + ')'}">
      </div>
      <div v-if="icon_left_no_transform"
           class="btnIconLeftNoTransform"
           :style="{'background-image': 'url(' + icon_left_no_transform + ')'}">
      </div>
      <span v-if="withMarginRight" style="margin-left: 0.5rem"></span>
      <slot/>
      <span v-if="withMargin" style="margin-right: 0.5rem"></span>
      <div v-if="icon"
           class="btnIcon ms-1"
           :style="{'background-image': 'url(' + icon + ')'}">
      </div>
<!--      <div v-if="mailNotification" class="notificationsInnerCircle rounded-circle d-flex justify-content-center align-items-center">-->
<!--        <img class="mailNotification" src="../../assets/media/coolicon-mail.svg" />-->
<!--      </div>-->
    </button>
</template>

<script>
export default {
  name: "my-button",
  props: {
    withMargin: {
      type: Boolean,
      default: false
    },
    withMarginRight: {
      type: Boolean,
      default: false
    },
    cls: {
      type: String,
      default: 'pink',
      validator: value => {
        return [
          'pink',
          'hollow',
          'blue',
          'noText',
          'blank',
          'purple'
        ].includes(value)
      }
    },
    icon:{
      type: String,
    },
    icon_left:{
      type: String,
    },
    icon_left_no_transform:{
      type: String,
    },
  },
  computed: {
    btnClass(){
      if (this.cls === 'hollow'){
        return 'hollow'
      } else if (this.cls === 'blue'){
        return 'blue'
      } else if (this.cls === 'noText') {
        return 'noText'
      } else if (this.cls === 'blank'){
        return 'blank'
      } else {
        return 'pink'
      }
    },
  }
}
</script>

<style scoped>
.button{
  border-radius: 1rem;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #FFFFFF;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #E32289;
  border: 0.15rem solid #E32289;
  /*max-width: 28rem;*/
}
.purple{
  background: #653E8B;
  border: 0.15rem solid #653E8B;
}
.pink {
    background: #E32289;
    border: 0.15rem solid #E32289;
}
.pink:hover{
    background: transparent!important;
    border: 0.15rem solid #fff;
    transition: 0.2s;
}
.pink:focus,.pink:focus-within {
    border: 0.15rem solid #fff;
    transition: 0.2s;
}
.heartDisable, .heartActive {
  margin-left: 4px;
}
.hollow {
    border: 0.15rem solid #E32289;
    background-color: transparent;
}
.hollow:hover {
    border: 0.15rem solid #fff;
    filter: brightness(10);
    transition: 0.2s;
}
.withMargin {
  margin-right: 0.5rem;
}
.blue {
  border: 0.15rem solid #3B3C8D;
  background-color: #3B3C8D;
}
.blue:hover {
  border: 0.15rem solid #fff;
  background-color: transparent;
  transition: 0.2s;
}
.noText {
  padding: 4px;
  width: 30px;
  height: 30px;
  border-radius: 12px;
}

.blank {
  background-color: transparent;
  border: none;
  cursor: default;
}

.backArrowIcon {
  transform: rotate(180deg);
  margin-right: 0.5rem;
}

.scrollLeft {
  transform: rotate(180deg);
}
.scrollLeft,
.scrollRight {
  height: 1.7rem;
}

.nextArrowIcon,
.logInIcon,
.logOutIcon {
  margin-left: 0.5rem;
}
.notificationsInnerCircle {
  width: 1.5rem;
  height: 1.5rem;
  background-color: #E32289;
}
.mailIcon {
  margin-left: 0.5rem;
  width: 1.25rem;
  height: 1.25rem;
}

.btnIcon{
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 1rem;
  width: 1rem;
}
.btnIconLeft{
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 1.25rem;
  width: 1.25rem;
  transform: rotate(180deg);
}
.btnIconLeftNoTransform{
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 1.25rem;
  width: 1.25rem;
}
</style>
