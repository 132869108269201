<template>
<div class="cartContainer">
  <div v-if="cart && cart.cart_items.length">
    <div class="cartContentContainer">
      <div class="cartPlayerCard">
        <p class="">{{this.$store.state.user.first_name}}</p>
        <div v-for="item in cart.cart_items" :key="item.id">
          <cart-item :item="item"/>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-center text-center totalPaddings">
      <div>
        <p class="totalPrice">{{total}}&nbsp;₽</p>
        <p class="totalPriceLabel">ИТОГО - МОЁ</p>
      </div>
    </div>
  </div>
  <div v-show="!cart || !cart.cart_items.length" class="fs-4 text-center">
    <p>Тут пусто.</p>
    <p>Добавь что-нибудь 🍕!</p>
    <my-button @click="$emit('close')" class="mt-3 w-100 mb-5">Хорошо</my-button>
  </div>
  <div v-if="carts && carts.length">
      <div v-for="(otherCart, i) in carts" class="cartContentContainer" :key="otherCart.id">
          <div class="cartPlayerCard">
            <div class="d-flex justify-content-between">
                <p class="">{{otherCart.name}}</p>
                <my-button 
                    v-if="this.$store.state.user.phone"
                    @click="cartModals[i].open()"
                    class="deleteButton"
                ><span class="buttontext">X</span></my-button>
            </div>
            <div v-for="item in otherCart.cart_items" :key="item.id">
              <cart-item :item="item" :foreign="true"/>
            </div>
          </div>
          <my-modal :ref="(el) => { this.cartModals[i] = el }">
              <div class="fs-4 text-center">
                  <p>Удалить заказ который сделал(а)</p>
                  <p>{{otherCart.name}}</p>
                  <my-button 
                      @click="$emit('deleteCart', otherCart.id)"
                      class="mt-3 w-100 mb-5"
                   >Удалить</my-button>
              </div>
          </my-modal>
      </div>
        <div class="d-flex justify-content-between align-items-center text-center totalPaddings">
          <div>
            <p class="totalPrice">{{totalTeam}}&nbsp;₽</p>
            <p class="totalPriceLabel">ИТОГО - СТОЛ</p>
          </div>
        </div>
  </div>
</div>
</template>

<script>
import CartItem from "./CartItem";
import MyButton from "@/components/UI/MyButton.vue";
import MyModal from '../UI/MyModal';
export default {
  name: "PreorderCart",
  components: {MyButton, CartItem, MyModal},
  props: ['cart', 'carts'],
  data() {
      return {cartModals: []}
  },
  computed: {
    total: function() {
      let total_coast = 0;
      for (let item of this.cart.cart_items) {
        total_coast += item.product.price * item.quantity;
      }
      return total_coast.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    },
    totalTeam: function() {
      const allCarts = [...this.carts, this.cart]
      let totalCost = 0;
      for (let cart of allCarts) {
          for (let item of cart.cart_items) {
            totalCost += item.product.price * item.quantity;
          }
      }
      return totalCost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    },
    totalItems: function() {
      let total_items = 0;
      for (let item of this.cart.cart_items) {
        total_items += item.quantity
      }
      return total_items
    },
  },
}
</script>

<style scoped>
/*.cartContentContainer {*/
/*  padding: 10px;*/
/*}*/
.totalPriceLabel {
  margin-top: 0.2rem;
  font-size: 10px;
  line-height: 100%;
  opacity: .6;
}
.buyButton {
  padding: 13px;
  margin-left: 1rem;
}
.totalPrice {
  padding-bottom: 0.5rem;
  font-weight: 700;
  font-size: 26px;
  line-height: 22px;
  width: max-content;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.totalPaddings {
  margin-top: 1rem;
  padding: 30px;
  margin-bottom: 15px;
  background: #3B3C8D;
  border-radius: 0px 0px 20px 20px;
}
.cartPlayerCard {
  background: #3B3C8D;
  border-radius: 16px;
  padding: 10px;
  margin-bottom: 5px;
}
.cartContainer {
  background: #33347E;
  border-radius: 20px;
}
.cartHeader {
  display: flex;
  align-items: center;
  align-content: center;
  padding: 20px;
  background: #3B3C8D;
  margin-bottom: 10px;
  border-radius: 20px 20px 0px 0px;
}
.cartIcon {
  width: 26px;
  height: 26px;
  background-image: url(../../assets/media/bag.svg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.cartHeadText {
  font-weight: 600;
  font-size: 26px;
  line-height: 22px;
  margin-left: 1rem;
}
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.deleteButton {
  font-size: 1rem;
  font-weight: 400;
  padding: 5px 10px;
  background: #33347E;
  border-radius: 1rem;
  border: none;
}
</style>
