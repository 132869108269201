<template>
  <nav class="navbar navbar-light">
    <div class="container-fluid position-relative d-flex flex-nowrap" :class="{'navBorder': this.$router.currentRoute.value.name !== 'home'}">
      <div v-if="this.$router.currentRoute.value.name !== 'home'" class="smallBorder"></div>
      <div class="d-flex">
        <div class="d-xl-flex align-items-center mr-1 cursor-pointer" @click="$router.push('/')">
            <div class="position-relative d-flex align-items-center justify-content-center">
            <div class="logoContainer position-absolute" style="max-width: 86px;" :style="{backgroundImage: 'url('+require('@/assets/media/logo.png')+')' }"></div>
            <div class="logoBackground position-relative">
            </div>
          </div>
          <div class="cityDiv me-auto d-none d-xl-block menuLink" v-show="currentCity!==''">
            <a @click="$refs.city.open()" class="cursor-pointer"><img class="icon" src="@/assets/media/place.svg" alt="geo" style="height: 18px; vertical-align: middle"/><span class="ms-1" v-html="currentCity"></span><img class="ms-1" src="../assets/media/arrow_down.svg" /></a>
          </div>
        </div>
        <router-link class="menuLink" :to="{name:'cabinet'}" v-if="$store.getters.isAuthenticated" style="margin-right: auto;">
          <div class="d-flex d-xl-none ms-2">
           <div class="notificationsCircleDiv d-flex rounded-circle justify-content-center align-items-center">
             <div class="navAvatar" :style="{ backgroundImage: 'url('+ glb.getMediaUrl($store.state.user.avatar) +')'}"></div>
            </div>
            <div class="d-flex flex-column ms-2">
            <div class=""><span>{{ $store.state.user.first_name }}</span></div>
            <div @click="$refs.city.open()" class="d-flex align-items-center"><span class="idSpan"> <img class="icon" src="@/assets/media/place.svg" alt="geo" style="height: 14px"/><span class="ms-1" v-html="currentCity"></span></span></div>
          </div>
        </div>
        </router-link>
        <div v-if="!$store.getters.isAuthenticated" class="d-flex align-items-center d-xl-none ms-2">
          <div @click="$refs.city.open()" class="d-flex align-items-center">
            <img class="icon" src="@/assets/media/place.svg" alt="geo" style="height: 14px"/>
            <span class="ms-2 fs-14 opacity-75" v-html="currentCity"></span>
          </div>
        </div>
      </div>

      <div class="dropdown d-xl-none">
        <div class="navbar-toggler menuA" role="button" @click="showCollapse=!showCollapse">
          <img class="d-block" id="menuIcon" src="../assets/media/menu_alt_01.svg" />
        </div>
        <collapse-transition>
          <ul v-show="showCollapse" class="dropdown-menu w-100 rounded-0 collapse show overflow-hidden" aria-labelledby="dropdownMenuLink0">
            <div @click="$refs.city.open()" class="cityDiv me-auto d-block d-xl-none menuLink " style="margin-left: 0;margin-top: 16px">
              <a class="cursor-pointer" v-html="currentCity"></a><img class="ms-1" src="../assets/media/arrow_down.svg" />
            </div>
            <router-link class="menuLink" to="/"><li class="listTop">Главная</li></router-link>
            <router-link class="menuLink" :to="{name: 'GameResults'}"><li>Результаты игр</li></router-link>
            <router-link class="menuLink" :to="{name: 'Rating'}"><li>Рейтинг команд</li></router-link>
            <router-link class="menuLink position-relative" :to="{name: 'Games'}"><li>Игры </li></router-link>
            <router-link class="menuLink" v-show="$store.getters.isAuthenticated" :to="{name: 'MyTeams'}"><li>Мои команды</li></router-link>
            <div class="d-flex flex-column mt-5">
              <my-button v-if="!$store.getters.isAuthenticated && currentCity != ''"
                         @click="$refs.auth.showAuthModal()"
                         class="mb-4 menuLink mx-auto"
                         :icon="require('../assets/media/long_right.svg')"
                         href="#" with-margin>Регистрируйся</my-button>
              <my-button v-if="currentCity === ''"
                         @click="$refs.city.open()"
                         class="mb-4 menuLink mx-auto"
                         :icon="require('../assets/media/long_right.svg')"
                         href="#" with-margin>Регистрируйся</my-button>
              <my-button
                  v-if="!$store.getters.isAuthenticated"
                  @click="showAuthModal"
                  cls="hollow"
                  class="menuLink mx-auto"
                  :icon="require('../assets/media/login_icon.svg')"
                  href="#"
              >Войти</my-button>
              <my-button v-if="$store.getters.isAuthenticated"
                         @click="logout()"
                         class="mb-4 menuLink mx-auto"
                         :icon="require('../assets/media/logout_icon.svg')"
                         href="#"
                         with-margin>Выйти</my-button>
              <div class="d-flex justify-content-center align-items-center p-3 my-3">
              </div>
            </div>
          </ul>
        </collapse-transition>
      </div>
      <div class="d-none d-xl-flex align-items-center position-absolute end-0">
              <router-link class="lgLinkHome menuLink" to="/">Главная</router-link>
              <router-link class="sleep menuLink" :class="{'active': this.$router.currentRoute.value.name == 'GameResults'}" :to="{name: 'GameResults'}">Результаты игр</router-link>
              <router-link :class="{'active': this.$router.currentRoute.value.name == 'Rating'}" class="sleep menuLink" :to="{name: 'Rating'}">Рейтинг команд</router-link>
              <router-link :class="{'active': this.$router.currentRoute.value.name == 'Games'}" class="sleep menuLink position-relative" :to="{name: 'Games'}">Игры </router-link>
              <router-link v-show="$store.getters.isAuthenticated" :class="{'active': this.$router.currentRoute.value.name == 'MyTeams'}" class="sleep menuLink" :to="{name: 'MyTeams'}">Мои команды</router-link>
              <router-link class="menuLink" :to="{name:'cabinet'}" v-show="$store.getters.isAuthenticated">
                <div class="ms-3 me-auto border-start ps-2 pe-2 d-flex">
                <div class="notificationsCircleDiv d-flex rounded-circle justify-content-center align-items-center">
                    <div class="navAvatar" :style="{ backgroundImage: 'url('+ glb.getMediaUrl($store.state.user.avatar) +')'}"></div>
                  </div>
                <div class="d-flex flex-column" style="max-width: calc(10vw - 10px)">
                  <div class="ms-3 me-1">

                  <span class="">{{ $store.state.user.first_name }}</span>
                </div>
                <span class="ms-3 idSpan">ID {{ $store.state.user.id }}</span>
                </div>
              </div>
              </router-link>
              <my-button v-if="!$store.getters.isAuthenticated" :icon="require('../assets/media/login_icon.svg')" @click="$refs.auth.showAuthModal()" href="#" with-margin>Войти</my-button>
              <my-button v-if="$store.getters.isAuthenticated"  :icon="require('../assets/media/logout_icon.svg')" @click="logout()" href="#" with-margin>Выйти</my-button>
      </div>
    </div>
    <teleport to="#app">
    <my-modal ref="city">
      <city-choice :cities='cities'></city-choice>
    </my-modal>
    <auth-modal ref="auth"/>
    </teleport>

  </nav>
</template>

<script>
import AuthModal from "./AuthModal";
import {mapActions, mapState} from "vuex";
import MyButton from './UI/MyButton.vue';
import CollapseTransition from '@ivanv/vue-collapse-transition/src/CollapseTransition.vue';
import CityChoice from './main/CityChoice';
import MyModal from './UI/MyModal.vue';
export default {
  name: "Navbar",
  components: { AuthModal,MyButton, CityChoice, MyModal, CollapseTransition},
  data: function() {
    return{
        showCollapse: false,
        navBg:  'navbar navbar-light',
        picture: '',
        cities:[],
        currentCity: '',
    }
  },
  watch:{
    $route: function (){
      this.showCollapse = false;
    }
  },

  mounted(){
    const vm = this
    this.load()
        .then(()=>{
          vm.selectCurrentCity()
        })
        .catch(function (error){
          vm.$toast.error(error);
        })
  },

  computed: {
    ...mapState(['isAuthenticated', 'user']),
    isMainPage(){
      return this.$router.currentRoute.value.name === 'home'
    }
  },
  methods: {
    ...mapActions([
      'SHOW_AUTH_MODAL',
      'GET_USER_DETAILS',
      'LOGOUT',
    ]),
    load() {
      const vm = this
      return new Promise((resolve, reject) => {
        this.axios.get(`/api/cities_list`)
            .then(function (response) {
              vm.cities = response.data
              resolve()
            })
            .catch(function (error) {
              reject(error);
            });
      })
    },
    selectCurrentCity() {
      for (let i in this.cities) {
        if (this.cities[i].subdomain === window.location.hostname.split('.')[0]) {
          this.currentCity = this.cities[i].title
        }
      }
      if (this.currentCity == '') {
      this.$refs.city.open()
    }
    },
    getPicture(){
      // console.log(this.picture ? this.picture : require('../../assets/media/avatar.svg'));
      return this.picture? this.glb.getMediaUrl(this.picture): require('../assets/media/coolicon-mail.svg');
    },
    logout() {
      this.LOGOUT().then(()=>{
        if (this.$route.name === 'home'){
          window.location.reload()
        } else {
          this.$router.push('/');
        }
      })
    },
    showAuthModal(){
      const vm = this
      vm.$refs.auth.showAuthModal()
      .then(()=>vm.showCollapse = false)
    }
  }
}
</script>

<style scoped>
.smallBorder {
  position: absolute;
  width: 92%;
  top: 5px;
  left: 4%;
  padding: 1.5rem;
  border-bottom: 1px solid rgba(255, 255, 255, .2);
  z-index: -1;
}
.listTop {
  margin-top: 1rem;
  border-top: 1px solid rgba(255, 255, 255, .2);
}
.navbar-toggler {
  border: none unset;
  padding: 0;
}
.navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}
.navbar-toggler span {
  color: white;
  font-size: 1rem;
}
.dropdown {
  position: static !important;
}
.notification {
  top: 24px;
}
.navbar {
  background-color: transparent;
  padding-top: 0.5rem;
  /*padding-bottom: 1.5rem;*/
  z-index: 1040;
  margin-bottom: 0.5rem;
}
.dropdown-menu {
  position: absolute !important;
  top: -1rem;
  left: 0;
  z-index: -1;
  padding: 65px;
  background-color: #2C2D73;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  box-shadow: 0px 70px 76px 10px rgb(0 0 0 / 20%);
}
.navbar-dark-bg{
  background-color: #2C2D73;
}
.navbar-dark-bg-disable{
  background-color: transparent;
  transition-delay: 0.2s;
}
a {
  color: white;
  text-decoration: none;
}
a:hover {
  color: white;
  text-decoration: none;
}
ul {
  border: none;
  margin: 0;
}
li {
  border-bottom: 1px solid rgba(255, 255, 255, .2);
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
}
li:hover {
  color: white;
  border-bottom: 1px solid #E32289;
}
li:focus {
  border-bottom: 1px solid #E32289;
}
.notificationsCircleDiv {
  width: 2.5rem;
  height: 2.5rem;
  border: 0.15rem solid #E32289;
}
.lgLinkHome {
  margin-right: 2.5rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid transparent;
}
.lgLinkHome:hover {
  color: white;
  border-bottom: 1px solid #E32289;
}
.active {
  border-bottom: 1px solid #E32289;
}
.sleep {
  margin-right: 1.5rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.sleep:hover {
  border-bottom: 1px solid #E32289;
}
.logoContainer, .logoBackground {
    height: 3rem;
    width: 4rem;
}
.logoContainer{
  background-position: center;
  background-repeat:  no-repeat;
  background-size: contain;
}
.logoBackground {
  background: rgba(213,35,136,.3);
  filter: blur(4rem);
}
.arrowDown {
  transform: rotate(45deg);
}
.clicked {
  background-color: #2C2D73;
}
.idSpan {
  font-size: 0.75rem;
  opacity: 0.6;
}
.menuA::after {
    display: none !important;
}
.navAvatar{
  background-position: center;
  background-size: cover;
  height: 100%;
  width: 100%;
  border-radius: 50%;
}
.cityDiv {
  cursor: pointer;
  margin-left: 16px;
}
@media screen and (min-width: 1200px) {
  /*.cityDiv {*/
  /*  margin-left: 7rem;*/
  /*}*/
  .smallBorder{
    display: none;
  }
  .logoBackground, .logoContainer{
      width: 5.5rem;
      height: 5.5rem;
  }
  .navbar {
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
  }
  .navBorder {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
}
</style>
