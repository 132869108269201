<template>
<div class="container">
  <div class="row mb-4">
    <div class="col d-sm-flex justify-content-between">
      <h2 class="">Рейтинг команд</h2>
      <Search
          v-model="searchString"
          placeholder="Поиск"
      ></Search>
    </div>
  </div>
  <div class="col-12 flex-column" :key="index" v-for="(season, index) in resultSeasons" >
    <div v-on:click="seasons[index].isVisible=!seasons[index].isVisible" class="blockWrapper d-flex justify-content-between">
      <h3 class="blockWrapperTitle position-relative"> {{season.name}} </h3>
      <div class="arrowBtn">
        <div><img :class="{'flip': season.isVisible}" class="ms-1" src="../assets/media/arrow_down.svg" /></div>
      </div>
    </div>
    <transition name="collapse">
      <div v-if="season.rating.length>0" class="table-responsive" v-show="season.isVisible">
        <table class="table table-shq">
          <thead>
          <tr>
            <th class="hash text-center" scope="col">#</th>
            <th class="team" scope="col">КОМАНДА</th>
            <th class="games text-center d-none d-md-table-cell" scope="col">ИГРЫ</th>
            <th class="fst text-center d-none d-md-table-cell" scope="col">1е</th>
            <th class="scd text-center d-none d-md-table-cell" scope="col">2е</th>
            <th class="trd text-center d-none d-md-table-cell" scope="col">3е</th>
            <th class="bal text-center" scope="col">БАЛЛОВ</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(team, index) in season.rating"
              :key="index"
              :class="setColor(index)"
          >
            <th class="text-center" scope="row">{{ index+1 }}</th>
            <td class="flex-column pl-0">
              <div class="foo">
                {{ team[0] }}
              </div>
              <div class="d-flex info">
                <div class="mobileView d-md-none d-table-cell">ИГРЫ - {{ team[2] }}</div>
                <div class="mobileView d-md-none d-table-cell">1е - {{ team[3] }}</div>
                <div class="mobileView d-md-none d-table-cell">2е - {{ team[4] }}</div>
                <div class="mobileView d-md-none d-table-cell">3е - {{ team[5] }}</div>
              </div>
            </td>
            <td class="games text-center d-none d-md-table-cell">{{ team[2] }}</td>
            <td class="fst text-center d-none d-md-table-cell">{{ team[3] }}</td>
            <td class="scd text-center d-none d-md-table-cell">{{ team[4] }}</td>
            <td class="trd text-center d-none d-md-table-cell">{{ team[5] }}</td>
            <td class="text-center">{{ team[1] }}</td>
          </tr>
          </tbody>
        </table>
      </div>
<!--      <pre-loader v-else></pre-loader>-->
    </transition>
  </div>



</div>
</template>

<script>
import {mapActions} from "vuex";
// import PreLoader from "../components/UI/PreLoader";
import Search from "../components/UI/Search";

export default {
  name: "Rating",
  components: { Search},
  mounted() {
    this.loadData();
  },
  watch:{
    searchString(){
      this.seasons.forEach(s=>s.isVisible=Boolean(this.searchString))
    }
  },

  computed: {
    resultSeasons(){
      let result = this.seasons.slice()
      if (this.searchString){
        return result.map(season=>{
          let res = {
            name: season.name,
            isVisible: Boolean(season.isVisible),
          }
          res.rating = season.rating.slice()
          res.rating = res.rating.filter(el => el[0].toLowerCase().includes(this.searchString.toLowerCase()))
          return res

        })
      } else {
        return result
      }
    }
  },
  data: function (){
    return {
      seasons:[],
      rating:[],
      searchString: ''
    }
  },
  methods: {
    ...mapActions(['SHOW_AUTH_MODAL']),
    loadData: function () {
      const vm = this;
      this.axios.get(`/api/rating/`)
          .then(function (response) {
            console.log(response.data);
            vm.seasons = response.data
            vm.seasons.map(s=>s.isVisible = false)
          })
          .catch(function (error) {
            console.log(error)
            vm.$toast.error(error)
          })
      ;
    },
    setColor(index){
      if (index===0) {
        return 'primary'
      }else if(index===1) {
        return 'secondary'
      }else if(index===2) {
        return 'third'
      }else {
        return 'base-color'
      }
    }
  },

}
</script>

<style scoped>
h2 {
  margin-bottom: 20px;
}
.table {
  color: #ffffff;
}
.blockWrapper {
  background-color: #33347E;
  border-radius: 1rem;
  padding: 1rem;
  margin-bottom: 10px;
  max-width: none;
}
.primary {
  background-color: #AC2683;
  }
.secondary {
  background-color: #7C3E8B;
}
.third {
  background-color: #3B3C8D;
}
.base-color{
  background-color: #33347E;
}
table {
  border-collapse: separate;
  border-spacing: 0 4px;
}
td {
  border: solid 1px #000;
  border-style: hidden;
  padding: 10px;
  font-size: 14px;

}
td .foo {
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  max-width: calc(50vw - 10px);
  padding-left: 0;
}
/*tr {*/
/*  border-top: solid 1px #fff;*/
/*  border-style: solid;*/
/*}*/
/*td {*/
/*  table-layout: fixed;*/
/*}*/
tbody {
  position: relative;
}
tbody:before {
  content: '';
  background-color: rgb(255 255 255 / 10%);
  height: 1px;
  width: 100%;
  display: block;
  position: absolute;
  top: -4px;
}
th {
  border-style: hidden;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  font-size: 14px;
  line-height: 143%;
  font-weight: 400;
}
.arrowBtn {
  transform: rotate(180deg);
}
.flip {
  transform: rotate(180deg);
}
/*.table>:not(caption)>*>* {*/
/*  padding-right: 0;*/
/*}*/
/*.team {*/
/*  padding-left: 0;*/
/*}*/
th:last-child {
  padding-right: 0;
  padding-left: 0;
}
td:last-child {
  border-right-style: hidden;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: none;
}
.mobileView {
  font-size: 10px;
  line-height: 100%;
  opacity: 0.8;
  border-right: 1px solid rgb(255 255 255 / 10%);
  padding-right: 6px;
  padding-left: 6px;
  margin-top: 11px;
}
.mobileView:last-child {
  border-right: none;
}
.mobileView:first-child {
  padding-left: 0;
}
thead *{
  opacity: 0.6;
  font-weight: 400!important;
  font-size: 12px;
  line-height: 100%;
}
@media screen and (max-width: 576px){

}
</style>