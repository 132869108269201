<template>
  <div class="alert alert-danger" :style="{ display: error.display }" role="alert">
    <div @click="closeFunction" class="text-right"><i class="bi bi-x"></i></div>
    <h5>{{ error.title }}</h5>
    <p>{{ error.description }}</p>
  </div>
</template>

<script>
export default {
  name: "modal-error",
  props: ['error'],
  methods : {
    closeFunction(){
      this.display = "none";
    }
  },
}
</script>

<style scoped>

</style>