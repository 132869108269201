<template>
    <div class="teamContainer">
        <div class="sittingTeamHeader">
            <div class="topHeader">
                <div @click="$refs.detailsModal.open()" class="infoIcon iconImg"></div>
                <h2 class="teamName">{{teamDetails.title}}</h2>
                <my-button v-if="teamDetails.is_in_game == true" @click="handleSitting" class="noText hollow" :icon="require('../../assets/media/check_bold.svg')"></my-button>
                <my-button v-if="teamDetails.is_in_game == false && teamDetails.isSelected == true" @click="handleSitting" class="noText hollow" :icon="require('../../assets/media/check_yellow.svg')"></my-button>
                <my-button v-if="teamDetails.is_in_game == false && teamDetails.isSelected == false" @click="handleSitting" class="noText hollow plusContainer">+</my-button>
                <div @click="showContent = !showContent" class="arrowIcon iconImg"></div>
            </div>
            <div class="bottomHeader">
                <div v-if="teamDetails.is_in_game == false" class="infoGraph">
                    <div v-for="col in teamDetails.registration_history" :key="col.timestamp" class="column" :style="{height: col.amount*3+'px'}"></div>
                </div>
                <div v-if="currentMenu == 'Гибкая рассадка' && teamDetails.is_in_game == true" class="status">
                    <div>В игре</div>
                </div>
                <div 
                    class="inGameContainer" 
                    v-if="currentMenu == 'С посадочными решениями' 
                    && teamDetails.is_in_game == true 
                    || currentMenu == 'С картосхемой' 
                    && teamDetails.is_in_game == true"
                >
                    <p class="tableSpan">В ИГРЕ</p>
                    <p class="tableData">{{teamDetails.occupied_furniture.capacity}} - {{teamDetails.occupied_furniture.max_capacity}}</p>
                </div>
                <div class="legioners">
                    <p class="tableSpan">ЛЕГИОНЕРОВ</p>
                    <p class="tableData">{{lonersInTeam}}</p>
                </div>
                <div class="seats">
                    <p class="tableSpan">ЗАНЯТО МЕСТ</p>
                    <p class="tableData">{{teamDetails.estimated_number_of_players}} из 10</p>
                </div>
            </div>
        </div>
        <div v-if="showContent" class="playersContainer">
            <player-in-sitting-team 
                v-for="player in teamDetails.players"
                :key="player.phone"
                :playerDetails="player"
                :teamDetails="teamDetails"
            ></player-in-sitting-team>
        </div>
        <my-modal ref="detailsModal">
            <sitting-team-details
                :teamDetails="teamDetails"
            ></sitting-team-details>
        </my-modal>
    </div>
</template>

<script>
import PlayerInSittingTeam from './PlayerInSittingTeam';
import SittingTeamDetails from './SittingTeamDetails';
import MyModal from '../UI/MyModal';
export default {
    name: 'SittingTeam',
    components: { PlayerInSittingTeam, SittingTeamDetails, MyModal },
    props: ['currentMenu', 'teamDetails'],
    data() {
        return {
            showContent: false,
            columnsData: [
                {value: 2, id:1},
                {value: 5, id:2},
                {value: 7, id:3},
                {value: 3, id:4}
            ],
            lonersInTeam: 0,
        }
    },
    methods: {
        handleSitting() {
                this.$emit('sittingEvent', !this.teamDetails.is_in_game, this.teamDetails.id)
        }
    },

}
</script>

<style scoped>
.noText {
    margin-left: auto;
    margin-right: 1rem;
}
.plusContainer {
    width: 30px;
    height: 30px;
    border: 2px solid white;
    border-radius: 0.7rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    padding: 0.7rem;
}
.unSelected {
    border-color: white;
    transform: rotate(-90deg);
}
.active {
    border: 1px solid white;
}
.infoGraph {
    width: 80px;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
}
.column {
    width: 1px;
    background: white;
}
.inGameContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}
.sittingTeamHeader {
    padding: 1rem;
    background-color: #33347E;
    margin-bottom: 0.5rem;
}
.topHeader {
    display: flex;
    margin-bottom: 1.5rem;
}
.bottomHeader {
    display: flex;
    justify-content: end;
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
.iconImg {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.infoIcon {
    background-image: url(../../assets/media/infoIcon.svg);
    width: 18px;
    height: 18px;
}
.arrowIcon {
    margin-top: 0.1rem;
    width: 14px;
    height: 8px;
    background-image: url(../../assets/media/arrow_down.svg);
}
.teamName {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-left: 1rem;
    margin-right: auto;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
    max-width: calc(40vw);
}
.status {
    display: flex;
    align-items: center;
    background: #2C2D73;
    border-radius: 6px;
    padding: 0.6rem 1rem;
    font-weight: 500;
    font-size: 14px;
    line-height: 143%;
}
.legioners {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    border-right: 1px solid rgba(255, 255, 255, 0.1);
}
.seats {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.tableSpan {
    font-size: 10px;
    line-height: 100%;
    opacity: 0.6;
    white-space: nowrap;
}
.tableData {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
}
.playersContainer {
    padding: 0.5rem;
}

@media (min-width: 576px) {
    .sittingTeamHeader {
        border-radius: 20px;
    }
    .teamContainer {
        width: 95%;
        margin-left: auto;
        margin-right: auto;
    }
}
@media (min-width: 768px) {
    .sittingTeamHeader {
        display: flex;
        padding-right: 6rem;
        position: relative;
    }
    .arrowIcon {
        position: absolute;
        right: 1rem;
        top: 2rem;
    }
    .noText {
        position: absolute;
        right: 2rem;
        top: 1.5rem;
    }
    .bottomHeader {
        margin-left: auto;
    }
}
</style>