<template>
<div class="container pb-5">
  <div class="row">
    <div class="col d-sm-flex justify-content-between align-items-center">
      <h2 class="">Мои команды</h2>
      <my-button
        class="hollow createNewTeam_btn"
        @click="$refs.addNewTeamModal.open()"
      >
        Создать новую команду
      </my-button>
    </div>
  </div>

  <div
      class="col-12"
      v-for="team in teams"
      :key="team.id"
  >
    <TeamCabinet
        :team-secret-slug="team.secret_slug"
        :team="team"
        @load-data="loadData"
    ></TeamCabinet>
  </div>

  <my-modal ref="addNewTeamModal">
    <reg-new-team
        @close="$refs.addNewTeamModal.close()"
        @load-data="loadData"
    ></reg-new-team>
  </my-modal>
</div>

</template>

<script>
import {mapActions, mapState} from "vuex";
import MyButton from "../components/UI/MyButton";
import TeamCabinet from "../components/team/TeamCabinet";
import RegNewTeam from "../components/team/RegNewTeam";

export default {
  name: "MyTeams",
  components: {RegNewTeam, MyButton, TeamCabinet},
  data() {
    return {
      teams:[],
    }
  },

  mounted() {
    //получение данных о команде и игре
    this.loadData()
  },
  computed: {
    ...mapState(['isAuthenticated'])
  },

  methods: {
    ...mapActions(['SHOW_AUTH_MODAL']),
    async authenticate() {
      let vm = this;
      const ok = await this.$refs.auth.showAuthModal();
      if (ok) {
        vm.loadData();
      } else {
        vm.$toast.error('Что-то пошло не так!')
      }
    },

    loadData: function () {
      const vm = this;

      this.axios.get(
          `/api/user/teams`,
          {headers: {'Authorization': `Token ${this.$store.state.user.authToken}`}})
          .then(function (response) {
            console.log(response.data);
            vm.teams = response.data
          });
      this.axios.get(
          `/api/user/events`,
          {
            headers: {
              'Authorization': `Token ${this.$store.state.user.authToken}`
            }
          })
          .then(function (response) {
            console.log(response.data);
            vm.events = response.data
          });
    },
  },
}
</script>

<style scoped>
.createNewTeam_btn {
  margin: 24px 0;
}


</style>
