<template>
  <div class="container">
    <div class="">
<!--      <div class="col d-sm-flex justify-content-between align-items-center">-->
<!--        <h2 class="">Игры</h2>-->
<!--      </div>-->
      <div class="menuContainer">
        <button @click="currentGameType='UPCOMING'; shakeThemes()" :class="{'active': currentGameType === 'UPCOMING'}" class="menuA col position-relative">Ближайшие
<!--          <Notification style="right: 0px;" count="2"></Notification> -->
        </button>
        <button @click="currentGameType='RECENT'" :class="{'active': currentGameType === 'RECENT'}" class="menuA col">Прошедшие</button>
        <button @click="currentGameType='DEVELOPMENT'" :class="{'active': currentGameType === 'DEVELOPMENT'}" class="menuA col position-relative">В разработке
<!--          <Notification style="right: 0px;" count="1"></Notification> -->
        </button>
        <button v-if="$store.state.user.authToken" @click="currentGameType='MYGAMES'" :class="{'active': currentGameType === 'MYGAMES'}" class="menuA col">Мои игры</button>
        <button v-if="$store.state.user.authToken" @click="currentGameType='FAVOURITES'" :class="{'active': currentGameType === 'FAVOURITES'}" class="menuA col">Избранное</button>
      </div>
      <div>
        <TransitionGroup name="cardsAnimation">
          <event-card
            v-for="event in currentEvents"
            :key="event.id"
            :game-event="event"
            @show-auth-modal="showAuthModal"
            :past="currentGameType==='RECENT'"
          ></event-card>
          <theme-card
            v-for="theme in currentThemes"
            :key="theme.id"
            :theme="theme"
            @set-like="setLike"
            ref="themesEl"
          >
          </theme-card>
        </TransitionGroup>
        <div v-show="currentEvents.length < 1 && currentThemes.length < 1 && !loading">
          <h3 class="text-center">Пока ничего... </h3>
        </div>
<!--        <div v-show="!$store.getters.isAuthenticated">-->
<!--          <h2 @click="$refs.auth.showAuthModal()" class="text-center h2Log">Попробуй Войти</h2>-->
<!--        </div>-->
      </div>
      <pre-loader v-if="loading"/>
    </div>
    <div class="moreButton-container">
      <MyButton
          class="more-button"
          v-if="showMoreGameCards && !requestLock"
          @click="loadElseEventCards(nextGameCardsUrlPage)"
          :cls="moreButtonCls"
      >Еще</MyButton>
    </div>
    <div class="gamesContainer"></div>
    <scroll-up></scroll-up>
    <auth-modal ref="auth"></auth-modal>
  </div>
</template>

<script>
import ThemeCard from '../components/main/ThemeCard.vue';
import EventCard from '../components/main/EventCard.vue';
import ScrollUp from '../components/UI/ScrollUp.vue';
import MyButton from "../components/UI/MyButton";
import {mapActions, mapState} from "vuex";
// import Notification from "../components/UI/Notification";
import AuthModal from '../components/AuthModal.vue'
export default {
  components: {
    // Notification,
    ThemeCard,
    ScrollUp,
    EventCard,
    AuthModal,
    MyButton
  },
  name: "Games",
  data() {
    return {
      showMoreGameCards: false,
      nextGameCardsUrlPage: 1,
      showMenu: false,
      themes: [],
      currentThemes: [],
      currentEvents: [],
      nextEvents: [],
      pastEvents:[],
      developmentThemes: [],
      myEvents: [],
      currentGameType: 'UPCOMING',
      moreButtonCls: 'pink',
      requestLock: false,
      loading: true,
    }
  },
  mounted() {
    this.currentGameType = this.$route.query.type || 'UPCOMING'
    this.shakeThemes()
  },
  computed: {
    ...mapState(['isAuthenticated'])
  },
  watch: {
    currentGameType(val) {
      this.$router.push({name: 'Games', query: {type: val}})
      console.log('currentGameType >> ', val)
      this.shakeThemes()
    },

  },
  methods: {
    ...mapActions(['GET_USER_DETAILS']),
    setLike(themeId, status) {
      let themes = this.currentThemes.map(theme => theme.id === themeId ? Object.assign({}, theme, {isLiked:status}) : theme)
      this.GET_USER_DETAILS().then(()=>this.processThemes(themes))
    },
    showAuthModal() {
      this.$refs.auth.showAuthModal();
    },
    processEvents: function(data , push=false) {
      let events = []
      for (let event of data) {
        event.game.isLiked = false;
        event.isRegistered = false;
        if (this.isAuthenticated) {
          if (this.$store.state.user.like.indexOf(event.game.id) !== -1) {
            event.game.isLiked = true;
          }
        }
        for (let ep of this.$store.state.user.event_players) {
          if (ep.event_team.event === event.id) {
            event.isRegistered = true;
          }
        }
        events.push(event)
      }
      if (push){
        this.currentEvents.push(...events)
      } else {
        this.currentEvents = events
      }
      console.log(this.currentEvents)
    },
    processThemes: function (data) {
      let themes = []
      for (let theme of data) {
        theme.isLiked = false;
        if (this.$store.state.user.authToken) {
          if (this.$store.state.user.like.indexOf(theme.id) !== -1) {
            theme.isLiked = true;
          }
        }
        themes.push(theme)
      }
      this.currentThemes = themes
    },
    shakeThemes(page='1'){
      console.log('SHAKE')
        console.log('test')
      this.loading = true
      if (!this.requestLock) {
        this.requestLock = true
        this.showMenu = false
        this.currentThemes = []
        this.currentEvents = []
        this.showMoreGameCards = false
        let loadGames = null
        if (this.currentGameType == 'UPCOMING') {
          loadGames = this.$api.getFutureGames()
        } else if (this.currentGameType == 'RECENT') {
          loadGames = this.loadRecent(page)
        } else if (this.currentGameType == 'MYGAMES') {
          loadGames = this.$api.getMyGames()
        } else if (this.currentGameType == 'FAVOURITES') {
          loadGames = this.$api.getFavoriteGames()
        } else if (this.currentGameType == 'DEVELOPMENT') {
          loadGames = this.$api.getInDevelopingGames()
        }
        if (loadGames){
          loadGames.then(data=>{
            let events = []
            let themes = []
            if (this.currentGameType == 'RECENT') return
            if (this.currentGameType == 'UPCOMING') {
              events = data
            } else if (this.currentGameType == 'MYGAMES') {
              events = data.current.concat(data.past)
            } else if (this.currentGameType == 'FAVOURITES') {
              themes = data.favorite.concat(data.not_favorite)
            } else if (this.currentGameType == 'DEVELOPMENT') {
              themes = data
            }
            this.processEvents(events)
            this.processThemes(themes)
          })
          .finally(()=>{
              this.loading = false
              this.requestLock = false
            })
        }
      }
    },
    loadElseEventCards(page) {
      this.loading = true
      if (this.currentGameType == 'UPCOMING') {
        null
      } else if (this.currentGameType == 'RECENT') {
        this.requestLock = true
        this.loadRecent(page)
      } else if (this.currentGameType == 'MYGAMES') {
        null
      } else if (this.currentGameType == 'FAVOURITES') {
        null
      } else if (this.currentGameType == 'DEVELOPMENT') {
        null
      }
    },
    loadRecent(page){
      const vm = this
      return vm.$api.getPastGames(page)
       .then(data=>{
        if (data.next != null) {
          vm.showMoreGameCards = true
          vm.nextGameCardsUrlPage = data.next.split('page=')[1]
        } else {
          vm.showMoreGameCards = false
        }
        vm.moreButtonCls = 'pink'
        vm.processEvents(data.results, true)

      })
      .finally(()=>vm.loading=false)
    }
  },
}
</script>

<style scoped>
.menuContainer {
  display: flex;
  align-items: center;
  /*padding-top: 1.5rem;*/
  padding-bottom: 1.5rem;
  overflow-x: auto;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}
.gamesContainer {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  margin-bottom: 5%;
  margin-top: 5%;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}
.h2Log {
  cursor: pointer;
}
button {
  text-align: left;
}
.menuA {
  border-top: none;
  border-left: none;
  border-right: none;
  background-color: transparent;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.6);
  padding-bottom: 0.5rem;
  padding-right: 1rem;
  padding-left: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  cursor: pointer;
  white-space: nowrap;
  text-align: center;
}
.menuDD {
  border-top: none;
  border-left: none;
  border-right: none;
  background-color: transparent;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.6);
  padding: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  cursor: pointer;
  width: 100%;
}
.menuA:hover,
.menuDD:hover,
.active {
  border-bottom: 1px solid #E32289;
  color: rgba(255, 255, 255, 1);
}
.cardsAnimation-enter-active,
.cardsAnimation-leave-active {
  transition: all 0.5s ease;
}
.cardsAnimation-enter-from,
.cardsAnimation-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
.seperator {
  width: 80%;
  border-bottom: 1px solid white;
  opacity: 0.1;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
}
.moreButton-container {
  display: flex;
  justify-content: center;
  padding-top: 10px;
}
@media (min-width: 1200px) {
  /* CSS для: ширины >= 1200px */
  button {
    text-align: center;
  }
}
</style>
